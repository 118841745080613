module.exports = {
  CONTROL__THEME: 'control-theme',
  CONTROL__ANIMATIONS: 'control-animations',
  CONTROL__COLOURS: 'control-colours',
  CONTROL__SANTA_SLEIGH: 'control-santa-sleigh',
  CONTROL_ADVANCED_ANIMATION: 'control-advanced-animation',
  CONTROL_ADVANCED_ANIMATION_PLAY: 'control-advanced-animation-play',
  CONTROL_ADVANCED_ANIMATION_CREATE: 'control-advanced-animation-create',
  CONTROL__GO_CRAZY: 'control-go-crazy',
  CONTROL__BYO_THEME: 'control-byo-theme',
  CONTROL__VOICE: 'control-voice',
  CONTROL__SIMON_SAYS: 'simon-says'
};

/**
 * This file contains the group names that an element can belong to.
 * An element can belong to none or many groups
 */
module.exports = {
  FACADE: 'facade',
  GARDEN: 'garden',
  INSIDE: 'inside',
  MOTIFS: 'motifs',
  ROOF: 'roof',
  ROOF_TREE: 'roof_tree',
  TREES: 'trees',
}

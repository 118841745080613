<template>
  <Light class="light--white" :style="{ 'background-color': backgroundColor }"/>
</template>

<script>
  import Light from './Light';
  import {convertBrightness} from '../../utils';

  export default {
    name: 'WhiteLight',
    components: {
      Light
    },
    props: [
      'brightness'
    ],
    computed: {
      backgroundColor() {
        return `rgba(255, 255, 255, ${convertBrightness(this.brightness)})`;
      }
    }
  };
</script>

<style lang="scss">
  .light--white {
    border-color: antiquewhite;
  }
</style>

import constants from "@constants";

/**
 * Initial state
 * @type {Object}
 */
const state = {
  selectedHousePart: null
};

/**
 * Mutations for updating this piece of state
 * Must be synchronous
 *
 * @type {Object}
 */
const mutations = {
  updateSelectedHousePart(state, housePart) {
    state.selectedHousePart = housePart;
  }
};

/**
 * Actions for updating this piece of state
 * Can contain asynchronous actions
 *
 * @type {Object}
 */
const actions = {
  UPDATE_SELECTED_HOUSE_PART({ commit }, payload) {
    const { housePart } = payload;
    commit('updateSelectedHousePart', housePart);
  }
};

/**
 * Getter for this piece of state
 *
 * @type {Object}
 */
const getters = {
  selectedHousePart: state => state.selectedHousePart,
  houseParts: () => {
    return [
      ...Object.values(constants.houseParts).map(housePart => ({ id: housePart.id, display: housePart.name }))
    ]
  }
};

// Module exports
export default {
  state,
  mutations,
  actions,
  getters
};

export default {
  name: "ElementSelector",
  computed: {
    selectedElements: {
      get() {
        return this.$store.getters.selectedElements
      },
      set(elements) {
        this.$store.dispatch('UPDATE_SELECTED_ELEMENTS', { elements });
      }
    }
  }
}

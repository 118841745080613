"use strict";

const InWaves = require('../in-waves');

/**
 * The first half of the In Waves animation
 *
 * CH1 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 */
class InWavesOne extends InWaves {
  constructor() {
    super();

    const channelOneOn = [];
    const channelTwoOn = [];
    for (let ch1FrameFadeUp = this.ch1FrameFadeUpStart ; ch1FrameFadeUp < this.ch1FrameFadeUpStart + this.fadeLength ; ch1FrameFadeUp++) {
      channelOneOn.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeUp)
      });
      channelTwoOn.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeUp)
      });
    }

    for (let ch1FrameFadeHold = this.ch1FrameFadeHoldStart ; ch1FrameFadeHold < this.ch1FrameFadeHoldStart + this.fadeLength ; ch1FrameFadeHold++) {
      channelOneOn.push({
        frame: ch1FrameFadeHold,
        brightness: 250
      });
      channelTwoOn.push({
        frame: ch1FrameFadeHold,
        brightness: 250
      });
    }

    for (let ch1FrameFadeDown = this.ch1FrameFadeDownStart, ch1FrameFadeDownMultiplier = 29 ; ch1FrameFadeDown < this.ch1FrameFadeDownStart + this.fadeLength ; ch1FrameFadeDown++, ch1FrameFadeDownMultiplier--) {
      channelOneOn.push({
        frame: ch1FrameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeDownMultiplier)
      });
      channelTwoOn.push({
        frame: ch1FrameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeDownMultiplier)
      });
    }

    this.channels = {
      1: channelOneOn,
      2: channelTwoOn
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '1st';
  }
}

module.exports = InWavesOne;

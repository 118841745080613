const houseParts = require('../house-parts');
const byoThemeElements = require('../byo-theme-elements');
const channels = require('../channels');
const types = require('../types');
const themeGroups = require('../theme-groups');
const themes = require('../themes');
const elementGroups = require('../element-groups');

module.exports = {
  LETTERBOX_TREES_MULTI: {
    id: "LETTERBOX_TREES_MULTI",
    name: 'Letterbox Trees Party',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.LETTERBOX_TREES.id,
    byoThemeElementId: byoThemeElements.LETTERBOX_TREES.id,
    availableInThemes: [
      ...themeGroups.MULTI
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  LETTERBOX_TREES_COOL_WHITE: {
    id: "LETTERBOX_TREES_COOL_WHITE",
    name: 'Letterbox Trees Ice',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.LETTERBOX_TREES.id,
    byoThemeElementId: byoThemeElements.LETTERBOX_TREES.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  LETTERBOX_TREES_WARM_WHITE: {
    id: "LETTERBOX_TREES_WARM_WHITE",
    name: 'Letterbox Trees Classic',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.LETTERBOX_TREES.id,
    byoThemeElementId: byoThemeElements.LETTERBOX_TREES.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },
}

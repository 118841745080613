<template>
  <String class="string--multi" :strLength="this.strLength">
    <Red :brightness="channelOneBrightness"/>
    <Blue :brightness="channelTwoBrightness"/>
    <Yellow :brightness="channelOneBrightness"/>
    <Green :brightness="channelTwoBrightness"/>
  </String>
</template>

<script>
  import String from './String';
  import Red from '../Lights/Red';
  import Blue from '../Lights/Blue';
  import Green from '../Lights/Green';
  import Yellow from '../Lights/Yellow';

  export default {
    name: 'MultiString',
    components: {
      String,
      Red,
      Blue,
      Green,
      Yellow
    },
    props: {
      channelOneBrightness: Number,
      channelTwoBrightness: Number,
      strLength: {
        type: Number,
        default: 10
      }
    }
  };
</script>

<style>

</style>

"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * Create a "pulse" of light.
 * This will handle both the fade up and fade down
 *
 * @param {Number} pulseLength   Total length of pulse, off-on-off
 * @param {Number} fadeIncrement The step changes between each animation frame
 * @param {Number} startingFrame The first frame that the pulse will start at
 *
 * @returns {[{
 *   frame: Number,
 *   brightness: Number
 * }]}
 */
function createPulse(pulseLength, fadeIncrement, startingFrame) {
  const pulse = [];
  let trackingFrame = 0;

  // Create the fade up
  for (let i = 0, j = startingFrame ; i < (pulseLength / 2) ; i++, j++) {
    trackingFrame = j;
    pulse.push({
      frame: trackingFrame,
      brightness: i * fadeIncrement
    })
  }

  for (let i = 0, j = trackingFrame ; i <= (pulseLength / 2) ; i++, j++) {
    pulse.push({
      frame: j,
      brightness: 250 - (fadeIncrement * i)
    })
  }

  return pulse;
}

/**
 * Jingle Bells Animation
 *
 * Channel 1 starts off, channel 2 starts on
 * Fade in channel 1 at the same rate that channel 2 is fading out
 * Then fade out channel 1 at the same rate that channel 2 is fading in
 * Repeat
 */
class JingleBells extends FadeBaseAnimation {
  constructor() {
    super();
    this.id = 'jingle_bells';

    const fullBrightness = 250;
    this.frameLength = 5; // Length in "ticks"
    const pulseLength = 8;
    this.totalFrames = 250; // This is completely dependent on how many pulses and pauses there are below
    this.setMaxTicks();
    this.fadeLength = pulseLength / 2 ; // Fade is from off-on, or on-off, not off-on-off
    this.fadeIncrement = fullBrightness / this.fadeLength;

    const channelOne = [];
    const channelTwo = [];

    // jing-le Bells
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 0)); // 0 - 7
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 8)); // 8 - 15
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 16)); // 16 - 23

    // jing-le Bells
    channelTwo.push(...createPulse(pulseLength, this.fadeIncrement, 27)); // 27 - 34
    channelTwo.push(...createPulse(pulseLength, this.fadeIncrement, 35)); // 35 - 42
    channelTwo.push(...createPulse(pulseLength, this.fadeIncrement, 43)); // 43 - 50

    // jing-le All
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 54)); // 54 - 61
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 62)); // 62 - 69
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 70)); // 70 - 77

    // the way
    channelTwo.push(...createPulse(pulseLength, this.fadeIncrement, 79)); // 79 - 86
    channelTwo.push(...createPulse(pulseLength, this.fadeIncrement, 86)); // 86 - 92

    // oh-what-fun
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 99)); // 99 - 106
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 107)); // 107 - 114
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 115)); // 115 - 122

    // it-is-to-ride
    channelTwo.push(...createPulse(pulseLength, this.fadeIncrement, 123)); // 123 - 130
    channelTwo.push(...createPulse(pulseLength, this.fadeIncrement, 131)); // 131 - 138
    channelTwo.push(...createPulse(pulseLength, this.fadeIncrement, 139)); // 139 - 146
    channelTwo.push(...createPulse(pulseLength, this.fadeIncrement, 147)); // 147 - 154

    // in-a-one-horse-op-en-sleigh
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 155)); // 155 - 162
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 162)); // 162 - 169
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 169)); // 169 - 176
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 178)); // 178 - 185
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 187)); // 187 - 194
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 196)); // 196 - 203
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 205)); // 205 - 212

    // hey!
    // ? Could these trail off? So the trailing edge of the pulse is a little longer..
    channelOne.push(...createPulse(pulseLength, this.fadeIncrement, 217)); // 217 - 224
    channelTwo.push(...createPulse(pulseLength, this.fadeIncrement, 217)); // 217 - 224

    this.channels = {
      1: channelOne,
      2: channelTwo
    };

  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Jingle Bells';
  }
}

module.exports = JingleBells;

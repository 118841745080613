"use strict";

const ChasingFlash = require('../chasing-flash');

/**
 * The second half of the chasing flash animation
 *
 * CH1 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|X|_|X|_|X|_|_|_|_|_|X|X|X|_|_|
 *
 */
class ChasingFlashTwo extends ChasingFlash {
  constructor() {
    super();

    this.channels = {
      1: [
        6, 8, 10, 16, 17, 18
      ],
      2: [
        6, 8, 10, 16, 17, 18
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '2nd';
  }
}

module.exports = ChasingFlashTwo;

"use strict";

const SloGlo = require('../slo-glo');

/**
 * First half of the Slo Glow animation
 *
 * CH1 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 *
 */
class SloGloOne extends SloGlo {
  constructor() {
    super();

    const channelOneOn = [];
    const channelTwoOn = [];
    for (let ch1FrameFadeUp = 0 ; ch1FrameFadeUp <= 29 ; ch1FrameFadeUp++) {
      channelOneOn.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeUp)
      });
      channelTwoOn.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeUp)
      });
    }

    for (let ch1FrameFadeDown = 30, ch1FrameFadeDownMultiplier = 29 ; ch1FrameFadeDown <= 59 ; ch1FrameFadeDown++, ch1FrameFadeDownMultiplier--) {
      channelOneOn.push({
        frame: ch1FrameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeDownMultiplier)
      });
      channelTwoOn.push({
        frame: ch1FrameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeDownMultiplier)
      });
    }

    this.channels = {
      1: channelOneOn,
      2: channelTwoOn
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '1st';
  }
}

module.exports = SloGloOne;

import { HousePartSelector } from '@components';
import { getAvailableElementGroupThemes } from '@utils/get-available-element-themes';

export default {
  name: "ColourControl",
  components: {
    HousePartSelector
  },
  computed: {
    availableThemes() {
      return getAvailableElementGroupThemes(this.$store.getters.selectedHousePart);
    }
  },
  methods: {

  }
}

/**
 * This file will simply contain groups of themes that might be applied to a single element/house part
 */
const themes = require('./themes');

const { CHIC, CLASSIC, CRAZY, FIRE, ICE, LAGOON, MODERN, ODDBALL, OFF, PARTY } = themes;

module.exports = {
  MULTI: [PARTY, MODERN, ODDBALL, LAGOON, FIRE, CRAZY, OFF],
  COOL_WHITE: [MODERN, ICE, CHIC, CRAZY, OFF],
  WARM_WHITE: [CLASSIC, ODDBALL, CHIC, CRAZY, OFF]
}

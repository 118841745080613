"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * Slo Glow animation
 *
 * Channel 1 *on*, channel 2 *off*. Fade channel 1 **out** slowly
 * Channel 1 *off*, channel 2 *off*, Fade channel 2 **in** slowly
 * Channel 1 *off*, channel 2 *on*, Fade channel 2 **out** slowly
 * Channel 1 *off*, channel 2 *off*, Fade channel 1 **in** slowly
 *
 * Fade takes around 3 seconds
 *
 * CH1 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 *
 */
class SloGlo extends FadeBaseAnimation {
  constructor() {
    super();

    this.id = 'slo_glo';
    this.frameLength = 10; // Length in ticks
    this.totalFrames = 240;
    this.setMaxTicks();
    this.fadeLength = this.totalFrames / 4; // Each channel will have 1/4 the time to fade in, and 1/4 time to fade out
    this.fadeIncrement = 250 / this.fadeLength; // +1 ensures brightness gets to 100%. This will happen for multiple frames, but that's alright

    const channelOne = [];
    for (
      let ch1FrameFadeUp = 0
      ; ch1FrameFadeUp < (this.totalFrames * 0.25)
      ; ch1FrameFadeUp++
    ) {
      channelOne.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeUp)
      });
    }

    for (
      let ch1FrameFadeDown = (this.totalFrames * 0.25), ch1FrameFadeDownMultiplier = this.fadeLength
      ; ch1FrameFadeDown < (this.totalFrames * 0.5)
      ; ch1FrameFadeDown++, ch1FrameFadeDownMultiplier--
    ) {
      channelOne.push({
        frame: ch1FrameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeDownMultiplier)
      });
    }

    const channelTwo = [];
    for (
      let ch2FrameFadeUp = (this.totalFrames * 0.5), ch2FrameFadeUpMultiplier = 0
      ; ch2FrameFadeUp < (this.totalFrames * 0.75)
      ; ch2FrameFadeUp++, ch2FrameFadeUpMultiplier++
    ) {
      channelTwo.push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FrameFadeUpMultiplier)
      });
    }

    for (
      let ch2FrameFadeUp = (this.totalFrames * 0.75), ch2FrameFadeDownMultiplier = this.fadeLength
      ; ch2FrameFadeUp < this.totalFrames
      ; ch2FrameFadeUp++, ch2FrameFadeDownMultiplier--
    ) {
      channelTwo.push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FrameFadeDownMultiplier)
      });
    }

    this.channels = {
      1: channelOne,
      2: channelTwo
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Slo Glo';
  }
}

module.exports = SloGlo;

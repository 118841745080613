/**
 * Simple array comparison function. Will return true if both arrays contain exactly the same content
 *
 * @param {array} array1 First array to compare
 * @param {array} array2 Second array to compare
 *
 * @returns {Boolean}
 */
export const isEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every(item => array2.includes(item)) && array2.every(item => array1.includes(item));
}


export default {
  isEqual
};

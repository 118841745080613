import { Title } from '@components';
import { Control, TakeControl, Queue } from '@views';
import { listenClientKey, listenTimer } from "@store/FirebaseListeners";
import AJAX from "@utils/AJAX";
import HollyBells from '../../assets/holly_bells.svg';
import command from "@utils/command";

export default {
    components: {
        Control,
        TakeControl,
        Title,
        Queue,
        HollyBells
    },
    data() {
        return {
            hollyBellsIcon: HollyBells
        }
    },
    computed: {
        isTakeControlVisible() {
            return !this.$store.getters.isInControl && !this.$store.getters.isInQueue;
        },
        isQueueVisible() {
            return this.$store.getters.isInQueue;
        },
        isDev() {
            return window.location.hostname === 'localhost'
        }
    },
    mounted() {
        if (this.$store.getters.clientKey !== null) {
            listenClientKey(this.$store.getters.clientKey);
        }

        listenTimer();
    },
    methods: {
        rate5Stars() {
            AJAX.post('rate5Stars');
            this.$store.dispatch('alert', { message: 'Rated 5 stars!', type: 'info' })
        },
        purgeCommand() {
            command('');
        }
    }
}

import { Http } from 'vue-resource';
import appConfig from '../../application-configuration';

export default class AJAX {

  static get(endpoint) {
    return Http.get(`${appConfig.cloudFunctionsUrl}/${endpoint}`);
  }

  static post(endpoint, data = {}) {
    if (!appConfig.isDev) {
      return Http.post(`${appConfig.cloudFunctionsUrl}/${endpoint}`, data);
    }

    console.log(`Network Request to ${endpoint}. Data: ${JSON.stringify(data)}`)

    // Handle AJAX requests locally
    if (endpoint === 'requestTakeControl') {
      return Promise.resolve({
        body: {
          control: true
        }
      })
    }

    if (endpoint === 'saveBYOTheme') {
      return Promise.resolve({
        data: {
          themeId: data.id,
        }
      })
    }

    return Promise.resolve(); // Just resolve when no return value is required
  }
}

const { elements } = require('../constants');
const getSelectedHousePart = require('./get-selected-house-part');

/**
 * Get all the elements that belong to a house part
 *
 * @param {string} housePart The house part to find the elements of
 *
 * @returns {{
 *  id: <string>,
 *  name: <string>,
 *  animation: <string>,
 *  type: <string>,
 *  housePart: <string>,
 *  availableInThemes: <Array[string]>,
 *  brightness: <Object>
 * }}
 */
module.exports = (housePart) => {
    const selectedHousePartObject = getSelectedHousePart(housePart);

    if (!selectedHousePartObject) {
        return [];
    }

    return Object.values(elements)
        .filter((element) => {
            return element.housePart === selectedHousePartObject.id
        })
        .map((element) => elements[element.id]);
};

"use strict";

const PingPongFlash = require('../ping-pong-flash');

/**
 * Ping Pon Flash animation
 *
 * Channel 1 flash on/off 3 times
 *
 * Channel 2 flash on/off 3 times
 *
 * Channel 1 on, channel 2 off
 * Channel 1 off, channel 2 on
 * repeat 4 more times
 *
 * CH1 |X|_|X|_|X|_|_|X|_|X|_|X|_|X|_|_|_|_|_|_|_|_|_|
 * CH2 |_|X|_|X|_|X|_|_|_|_|_|_|_|_|_|X|_|X|_|X|_|X|_|
 *
 */
class PingPongFlashTwo extends PingPongFlash {
  constructor() {
    super();

    this.channels = {
      1: [
        1, 3, 5, 7, 9
      ],
      2: [
        1, 3, 5, 7, 9
      ]
    };

    for (let ch2FrameFadeUp = this.ch2FrameFadeStart, ch2FadeUpMultiplier = 0 ; ch2FrameFadeUp < this.ch2FrameFadeStart + this.fadeLength ; ch2FrameFadeUp++, ch2FadeUpMultiplier++) {
      this.channels[1].push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FadeUpMultiplier)
      });
      this.channels[2].push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FadeUpMultiplier)
      });
    }

    for (let ch2FrameHold = this.ch2FrameFadeStart + this.fadeLength ; ch2FrameHold < this.ch2FrameHoldStart + this.fadeLength + this.frameHoldBuffer ; ch2FrameHold++) {
      this.channels[1].push({
        frame: ch2FrameHold,
        brightness: 250
      });
      this.channels[2].push({
        frame: ch2FrameHold,
        brightness: 250
      });
    }
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '2nd';
  }
}

module.exports = PingPongFlashTwo;

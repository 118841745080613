const findElementIndex = require('./find-element-index');

function findElementIndexes(elements, element) {
  return elements.reduce((indexes, elementsEntry, index) => {
    if (Array.isArray(elementsEntry)) {
      const localIndex = findElementIndex(elementsEntry, element);
      if (localIndex < 0) return indexes;
      indexes.push(index);
    }

    if (elementsEntry === element) {
      indexes.push(index)
    }

    return indexes;
  }, []);
}

module.exports = findElementIndexes;

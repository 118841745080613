"use strict";

const TwinkleFlash = require('../twinkle-flash');

/**
 * Second half of the Twinkle Flash animation
 *
 * CH1 |_|_|_|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|X|_|X|_|X|_|
 */
class TwinkleFlashTwo extends TwinkleFlash {
  constructor() {
    super();

    this.channels = {
      1: [
        6, 8, 10
      ],
      2: [
        6, 8, 10
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '2nd';
  }
}

module.exports = TwinkleFlashTwo;

/**
 * These types line up with Components that are listed in the components/Strings folder
 * It seems there are only used when using the `/house` feature
 */

module.exports = {
  WarmWhite: 'WarmWhite',
  CoolWhite: 'CoolWhite',
  Multi: 'Multi',
  Blue: 'Blue',
  BlossomTrees: 'BlossomTrees',
  RedWhite: 'RedWhite',
  WhiteMulti: 'WhiteMulti',
  RedGreen: 'RedGreen',
  PurpleWhite: 'PurpleWhite',
  Green: 'Green'
}

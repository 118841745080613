<template>
    <div id="bars">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
    </div>
</template>

<script>
  // Found here https://codepen.io/raynesax/pen/MEbYBv
  export default {
    name: "SoundwaveAnimation"
  }
</script>

<style lang="scss">
    #bars {
        --animation-modifier: 1.8; // The higher the number, the slower the animation

        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        /*     background: black; */
    }

    .bar {
        background: #52467b;
        bottom: 1px;
        height: 3px;
        width: 10px;
        margin: 0px 4px;
        border-radius: 5px;
        animation: sound 0ms -600ms linear infinite alternate;
    }

    @keyframes sound {
        0% {
            opacity: .35;
            height: 3px;
        }
        100% {
            opacity: 1;
            height: 70px;
        }
    }

    .bar:nth-child(1) {
        left: 1px;
        animation-duration: calc(var(--animation-modifier) * 474ms);
    }

    .bar:nth-child(2) {
        left: 15px;
        animation-duration: calc(var(--animation-modifier) * 433ms);
    }

    .bar:nth-child(3) {
        left: 29px;
        animation-duration: calc(var(--animation-modifier) * 407ms);
    }

    .bar:nth-child(4) {
        left: 43px;
        animation-duration: calc(var(--animation-modifier) * 458ms);
    }

    .bar:nth-child(5) {
        left: 57px;
        animation-duration: calc(var(--animation-modifier) * 400ms);
    }

    .bar:nth-child(6) {
        left: 71px;
        animation-duration: calc(var(--animation-modifier) * 427ms);
    }

    .bar:nth-child(7) {
        left: 85px;
        animation-duration: calc(var(--animation-modifier) * 441ms);
    }

    .bar:nth-child(8) {
        left: 99px;
        animation-duration: calc(var(--animation-modifier) * 419ms);
    }

    .bar:nth-child(9) {
        left: 113px;
        animation-duration: calc(var(--animation-modifier) * 487ms);
    }

    .bar:nth-child(10) {
        left: 127px;
        animation-duration: calc(var(--animation-modifier) * 442ms);
    }
</style>

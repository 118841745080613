/**
 * Initial state
 * @type {Object}
 */
const state = {
  remainingTime: null,
  isTrackingTimer: false
};

/**
 * Mutations for updating this piece of state
 * Must be synchronous
 *
 * @type {Object}
 */
const mutations = {
  remainingTime(state, value) {
    state.remainingTime = value;
  },
  isTrackingTimer(state, value) {
    state.isTrackingTimer = value;
  }
};

/**
 * Actions for updating this piece of state
 * Can contain asynchronous actions
 *
 * @type {Object}
 */
const actions = {
  SET_REMAINING_TIME({ commit }, payload) {
    const { remaining } = payload;
    commit('remainingTime', remaining);
  },
  SET_IS_TRACKING_TIMER({ commit }, payload) {
    const { tracking } = payload;
    commit('isTrackingTimer', tracking);
  }
};

/**
 * Getter for this piece of state
 *
 * @type {Object}
 */
const getters = {
  remainingTime: state => state.remainingTime,
  isTrackingTimer: state => state.isTrackingTimer
};

// Module exports
export default {
  state,
  mutations,
  actions,
  getters
};

"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * Twinkle Fade Animation
 *
 * Channel 1 starts off, channel 2 starts on
 * Fade in channel 1 at the same rate that channel 2 is fading out
 * Then fade out channel 1 at the same rate that channel 2 is fading in
 * Repeat
 */
class TwinklePulse extends FadeBaseAnimation {
  constructor() {
    super();

    const fullBrightness = 250;
    this.id = 'twinkle_pulse';
    this.frameLength = 5; // Length in "ticks"
    this.totalFrames = 11;
    this.setMaxTicks();
    this.fadeLength = 6 ; // Length in ticks
    this.fadeIncrement = fullBrightness / this.fadeLength; // +1 ensures brightness gets to 100%. This will happen for multiple frames, but that's alright

    const channelOne = [];
    const channelTwo = [{ frame: 0, brightness: 250 }];

    for (let i = 0 ; i <= this.fadeLength ; i++) {
      channelOne.push({
        frame: i,
        brightness: Math.ceil(this.fadeIncrement * i)
      })
    }

    // j starts at 1 because we don't want to double up on 250
    // It will finish less than the fade length, because we don't want to end on 0, that would mean a double up of 0 at the end and round to the start
    for (let i = 7, j = 1 ; j < this.fadeLength ; i++, j++) {
      channelOne.push({
        frame: i,
        brightness: Math.ceil(fullBrightness - this.fadeIncrement * j)
      })
    }

    for (let i = 1 ; i <= this.fadeLength ; i++) {
      channelTwo.push({
        frame: i,
        brightness: Math.ceil(fullBrightness - this.fadeIncrement * i)
      })
    }

    // j starts at 1 because we don't want to double up on 0
    // It will finish less than the fade length, because we don't want to end on 0, that would mean a double up of 250 at the end and round to the start
    for (let i = 7, j = 1 ; j < this.fadeLength ; i++, j++) {
      channelTwo.push({
        frame: i,
        brightness: Math.ceil(this.fadeIncrement * j)
      })
    }

    this.channels = {
      1: channelOne,
      2: channelTwo
    };

  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Twinkle Pulse';
  }
}

module.exports = TwinklePulse;

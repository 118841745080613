const themes = require('./themes');
const themeGroups = require('./theme-groups');

/**
 * A list of "elements" that will be displayed in the Build Your Own Theme Control selector
 */

module.exports = {
  BELLS: {
    id: 'bells',
    label: 'Bells',
    availableThemes: [
      themes.OFF
    ]
  },
  ELF_TREE: {
    id: 'elf_tree',
    label: 'Elf Trees',
    availableThemes: [
      themes.OFF
    ]
  },
  FENCE: {
    id: 'fence',
    label: 'Fence',
    availableThemes: [
      ...themeGroups.MULTI,
      ...themeGroups.WARM_WHITE,
      ...themeGroups.COOL_WHITE,
    ]
  },
  GUTTER_LINE: {
    id: 'gutter_line',
    label: 'Gutter Line',
    availableThemes: [
      ...themeGroups.MULTI,
      ...themeGroups.WARM_WHITE,
      ...themeGroups.COOL_WHITE,
    ]
  },
  HOLLY: {
    id: 'holly',
    label: 'Holly',
    availableThemes: [
      themes.OFF
    ]
  },
  INSIDE_TREE: {
    id: 'inside_tree',
    label: 'Inside Tree',
    availableThemes: [
      ...themeGroups.MULTI,
      ...themeGroups.WARM_WHITE,
      ...themeGroups.COOL_WHITE,
    ]
  },
  KANGAROOS: {
    id: 'kangaroos',
    label: 'Kangaroos',
    availableThemes: [
      themes.OFF
    ]
  },
  KOALA: {
    id: 'koala',
    label: 'Koala',
    availableThemes: [
      themes.OFF
    ]
  },
  LETTERBOX: {
    id: 'letterbox',
    label: 'Letterbox',
    availableThemes: [
      themes.OFF, ...themeGroups.MULTI,
    ]
  },
  LETTERBOX_TREES: {
    id: 'letterbox_trees',
    label: 'Letterbox Trees',
    availableThemes: [
      ...themeGroups.MULTI,
      ...themeGroups.WARM_WHITE,
      ...themeGroups.COOL_WHITE,
    ]
  },
  MERRY_CHRISTMAS: {
    id: 'merry_christmas',
    label: 'Merry Christmas Sign',
    availableThemes: [
      themes.OFF
    ]
  },
  MINI_TREES: {
    id: 'mini_trees',
    label: 'Mini Trees',
    availableThemes: [
      themes.OFF, themes.CLASSIC, themes.ICE, themes.CHIC
    ]
  },
  PEPPERMINT_TREES: {
    id: 'peppermint_trees',
    label: 'Peppermint Trees',
    availableThemes: [
      themes.OFF
    ]
  },
  REINDEER: {
    id: 'reindeer',
    label: 'Reindeer',
    availableThemes: [
      themes.OFF
    ]
  },
  RIBBON_BELLS: {
    id: 'ribbon_bells',
    label: 'Ribbon Bells',
    availableThemes: [
      themes.OFF
    ]
  },
  ROADSIDE_TREES: {
    id: 'roadside_trees',
    label: 'Roadside Trees',
    availableThemes: [
      themes.OFF
    ]
  },
  ROOF_MOUNTAINS: {
    id: 'roof_mountains',
    label: 'Roof Mountains',
    availableThemes: [
      themes.OFF, ...themeGroups.COOL_WHITE, ...themeGroups.WARM_WHITE,
    ]
  },
  ROOF_PEAK: {
    id: 'roof_peak',
    label: 'Roof Peak',
    availableThemes: [
      ...themeGroups.MULTI,
      ...themeGroups.WARM_WHITE,
      ...themeGroups.COOL_WHITE,
    ]
  },
  ROOF_TREE_STAR: {
    id: 'roof_tree_star',
    label: 'Roof Tree Star',
    availableThemes: [
      ...themeGroups.MULTI,
      ...themeGroups.WARM_WHITE,
      ...themeGroups.COOL_WHITE,
    ]
  },
  ROOF_TREE: {
    id: 'roof_tree',
    label: 'Roof Tree',
    availableThemes: [
      ...themeGroups.MULTI,
      ...themeGroups.WARM_WHITE,
      ...themeGroups.COOL_WHITE,
    ]
  },
  SANTA: {
    id: 'santa',
    label: 'Santa',
    availableThemes: [
      themes.OFF
    ]
  },
  SANTA_REINDEER: {
    id: 'santa_reindeer',
    label: 'Santa & Reindeer',
    availableThemes: [
      themes.OFF
    ]
  },
  SNOWFLAKES: {
    id: 'snowflakes',
    label: 'Snowflakes',
    availableThemes: [
      themes.OFF
    ]
  },
  TRAIN: {
    id: 'train',
    label: 'Train',
    availableThemes: [
      themes.OFF
    ]
  },
}

<template>
  <div class="container">
    <p>
      <small
        >Use this control to create custom sweeping, bouncing, imploding or
        exploding effects with only the lights that you want!</small
      >
    </p>
    <div class="label">
      1. Choose Animation Style
    </div>
    <v-select
      v-model="selectedAnimation"
      :items="advancedAnimations"
      :menu-props="{ maxHeight: '600' }"
      item-text="label"
      item-value="id"
      label=""
      @change="sendUpdate"
    ></v-select>

    <div class="label">
      2. Add lights to your group
    </div>
    <div>
      <v-btn @click="isElementSelectorVisible = true">+ Add Light</v-btn>
    </div>

    <v-dialog v-model="isElementSelectorVisible" max-width="600px">
      <v-card>
        <v-card-title class="modal-title">
          <div class="modal-title-header">
            <span class="headline">Light Selector</span>
            <v-btn color="green" text @click="isElementSelectorVisible = false"
              >Done</v-btn
            >
          </div>
          <small>Click on lights below to add them to your group</small>
        </v-card-title>
        <v-card-text>
          <div class="modal-body">
            <v-btn
              v-for="element in elements.filter(
                el => !this.elementsInGroup.includes(el.id)
              )"
              :key="element.id"
              @click="() => addToGroup(element.id)"
            >
              + {{ element.name }}
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="isElementSelectorVisible = false"
            >Done</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="label">
      3. Order your group
      <p>
        <small>
          Touch and drag lights up and down to change where they are in your
          animation sequence
        </small>
      </p>
    </div>
    <draggable :list="elementsInGroup" group="people" @end="handleDragEnd">
      <div
        v-for="elementInGroup in elementsInGroup"
        :key="elementInGroup.id"
        class="draggable-element"
      >
        <v-icon class="handle">mdi-drag</v-icon>
        <span>
          {{ elements.find(el => el.id === elementInGroup).name }}
        </span>
        <v-btn @click="() => removeFromGroup(elementInGroup)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import groupAnimations from "@/group-animations";
import elements from "@/constants/elements";
import Analytics from "@utils/Analytics";
import constants from '@constants';

export default {
  name: "AdvancedAnimationCreate",
  components: {
    draggable
  },
  data: function() {
    return {
      selectedAnimation: "",
      isElementSelectorVisible: false,
      elementsInGroup: []
    };
  },
  mounted() {
    // Setting the off animation will ensure all of the lights do turn off
    // It will likely cause an issue if a user then goes back to the change theme option, so will have to figur that out
    this.$store.dispatch("CHANGE_ANIMATION", {
      animation: "off",
      elements: "ALL",
      key: this.$store.getters.clientKey
    });
  },
  computed: {
    selectedByoThemeElement: {
      get() {
        return this.$store.getters.selectedByoThemeElement;
      },
      set(element) {
        this.$store.dispatch("UPDATE_SELECTED_BYO_THEME_ELEMENTS", { element });
      }
    },
    elements() {
      return Object.values(elements);
    },
    advancedAnimations() {
      return Object.entries(groupAnimations).filter(([, groupAnimation]) => groupAnimation.id !== 'empty').map(([, groupAnimation]) => ({
        id: groupAnimation.id,
        label: groupAnimation.getDisplayName()
      }));
    }
  },
  methods: {
    addToGroup(elementId) {
      this.elementsInGroup.push(elementId);

      Analytics.FireFeatureUsed("add_light_create_advanced_animation", {
        key: this.$store.getters.clientKey
      });
      this.sendUpdate();
    },
    removeFromGroup(elementId) {
      this.elementsInGroup = this.elementsInGroup.filter(
        elementInGroup => elementInGroup !== elementId
      );
      Analytics.FireFeatureUsed("remove_light_create_advanced_animation", {
        key: this.$store.getters.clientKey
      });
      this.sendUpdate();
    },
    handleDragEnd() {
      this.sendUpdate();
    },
    sendUpdate() {
      this.$store.dispatch("CHANGE_ANIMATION", {
        animation: this.selectedAnimation,
        elements: this.elementsInGroup.join(","),
        key: this.$store.getters.clientKey,
        control: constants.controlNames.CONTROL_ADVANCED_ANIMATION_CREATE
      });
    }
  }
};
</script>

<style scoped>
.container {
  padding: 10px;
}
.label {
  text-align: left;
  padding: 0 5px 10px 5px;
}
.modal-title {
  display: flex;
  flex-direction: column;
  background-color: #222222;
  position: sticky;
  top: 0;
  z-index: 10;
  margin-bottom: 5px;
}
.modal-title-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.draggable-element {
  position: relative;
  padding: 10px;
  background-color: #222222;
  margin-bottom: 5px;
  user-select: none;
  cursor: grab;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

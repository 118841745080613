<template>
  <div>
    <ul class="control-advanced-play__options reset-ul">
      <li
        class="control-advanced-play__option"
        v-for="advancedAnimation in advancedAnimations"
        :key="advancedAnimation.name"
      >
        <v-btn
          large
          block
          class="animation font-weight-light"
          :id="advancedAnimation.name"
          @click="
            $store.dispatch('CHANGE_ANIMATION', {
              animation: advancedAnimation.animationId,
              elements: advancedAnimation.elements,
              control: controlName
            })
          "
        >
          {{ advancedAnimation.name }}
        </v-btn>
      </li>
    </ul>
  </div>
</template>

<script>
import constants from "@constants";

export default {
  name: "AdvancedAnimationPlay",
  computed: {
    controlName() {
      return constants.controlNames.CONTROL_ADVANCED_ANIMATION_PLAY
    },
    advancedAnimations() {
      return constants.advancedAnimations;
    }
  }
};
</script>

<style scoped></style>

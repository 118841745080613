<template>
    <div id="app">
        <v-app id="inspire">
            <v-content>
                <v-container class="fill-height" fluid>
                    <v-row class="no-app__row" align="center" justify="center">
                        <div>
                            <p>
                                Unfortunately the lights can only be controlled by people who are actually at the house,
                                and the only way the house will know if you're there, is by accessing your location
                                <span v-if="oldDevice">, and it appears your device might not be able to provide location data</span>
                            </p>
                            <h1 class="headline">Steps to try and resolve:</h1>
                            <ol>
                                <li>Ensure the Location setting on your phone is enabled</li>
                                <li>Try downloading the <a href="https://www.google.com/chrome/" target="_blank" rel="noopener nofollow">Google Chrome web browser</a></li>
                                <li>Access this same website using Google Chrome</li>
                                <li>If problems still persist, I would love if you could let me know, so I can hopefully fix it before you come back next time!
                                    <a href="https://www.facebook.com/LukeLightsSA" target="_blank" rel="noopener nofollow">Send me a message on Facebook</a></li>
                            </ol>
                            <p v-if="oldDevice">After trying all of the above, if this page is still showing, unfortunately it seems your device won't be compatible with the lights</p>
                        </div>
                    </v-row>
                </v-container>
            </v-content>
        </v-app>
    </div>
</template>

<script>
  export default {
    name: "NoApp",
    props: [
      'oldDevice'
    ],
    created() {
      this.$vuetify.theme.dark = true
    }
  }
</script>

<style lang="scss">
    .no-app__row {
        display: block;
        margin: 0 auto;
    }
</style>

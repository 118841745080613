import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@store';
import Dashboard from "../views/Dashboard/Dashboard.vue";
import House from "../views/House/House.vue";
import { Control, Queue, TakeControl } from "@views";
import checkIsInternalNavigation from "./checkIsInternalNavigation";
import AnimationsControl from "@views/AnimationsControl/AnimationsControl.vue";
import ColourControl from "@views/ColourControl/ColourControl.vue";
import AdvancedAnimation from "@views/AdvancedAnimation/AdvancedAnimation.vue";
import SantaSleighControl from "@views/SantaSleighControl/SantaSleighControl.vue";
import AdvancedAnimationPlay from "@views/AdvancedAnimation/AdvancedAnimationPlay.vue";
import AdvancedAnimationCreate from "@views/AdvancedAnimation/AdvancedAnimationCreate.vue";
import GoCrazyControl from "@views/GoCrazyControl/GoCrazyControl.vue";
import ThemesControl from "@views/ThemesControl/ThemesControl.vue";
import Finished from "@views/Finished/Finished.vue";
import NearbyLights from "@views/NearbyLights/NearbyLights.vue";
import BYOThemeControl from "@views/BYOThemeControl/BYOThemeControl.vue";
import { controlNames } from '@constants';
import AnimationPlayer from "@views/AnimationPlayer/AnimationPlayer.vue";
import VoiceControl from "@views/VoiceControl/VoiceControl.vue";
import SimonSays from "@views/SimonSays/SimonSays.vue";

Vue.use(VueRouter);

function checkIsInControl(to, from, next) {
  if (!store.getters.isInControl) {
    next('/');
    return;
  }
  next();
}

const routes = [
  {
    path: '/animation-player',
    name: 'animation-player',
    component: AnimationPlayer
  },
  {
    path: '/house',
    name: 'house',
    component: House
  },
  {
    path: '/',
    component: Dashboard,
    beforeEnter: checkIsInternalNavigation, // This will run before each fo the children routes run, navigating a user away from the children *if* they've entered those URL's directly
    children: [
      {
        name: 'take-control',
        path: '',
        component: TakeControl,
        beforeEnter: (to, from, next) => {
          // Prevent a user from going back to the Dashboard if they're in control, because we don't want them to
          // attempt to re-click the take Control button
          if (store.getters.isInControl) {
            next('/control');
            return;
          }
          next();
        }
      },
      {
        name: 'control',
        path: 'control',
        component: Control,
        beforeEnter: checkIsInControl,
        children: [
          {
            name: controlNames.CONTROL__THEME,
            path: 'theme',
            component: ThemesControl,
            beforeEnter: checkIsInControl
          },
          {
            name: controlNames.CONTROL__ANIMATIONS,
            path: 'animations',
            component: AnimationsControl,
            beforeEnter: checkIsInControl
          },
          {
            name: controlNames.CONTROL__COLOURS,
            path: 'colours',
            component: ColourControl,
            beforeEnter: checkIsInControl
          },

          {
            name: controlNames.CONTROL__SANTA_SLEIGH,
            path: 'santa-sleigh',
            component: SantaSleighControl,
            beforeEnter: checkIsInControl
          },

          {
            name: controlNames.CONTROL_ADVANCED_ANIMATION,
            path: 'advanced',
            component: AdvancedAnimation,
            beforeEnter: checkIsInControl,
            children: [
              {
                name: controlNames.CONTROL_ADVANCED_ANIMATION_PLAY,
                path: 'play',
                component: AdvancedAnimationPlay
              },
              {
                name: controlNames.CONTROL_ADVANCED_ANIMATION_CREATE,
                path: 'create',
                component: AdvancedAnimationCreate
              }
            ]
          },
          // {
          //   name: controlNames.CONTROL__GO_CRAZY,
          //   path: 'go-crazy',
          //   component: GoCrazyControl,
          //   beforeEnter: checkIsInControl
          // },
          {
            name: controlNames.CONTROL__BYO_THEME,
            path: 'byo-theme',
            component: BYOThemeControl,
            beforeEnter: checkIsInControl
          },
          {
            name: controlNames.CONTROL__VOICE,
            path: 'voice',
            component: VoiceControl,
            beforeEnter: checkIsInControl
          },
          {
            name: controlNames.CONTROL__SIMON_SAYS,
            path: 'simon-says',
            component: SimonSays,
            beforeEnter: checkIsInControl
          },
          {
            name: 'control-yield',
            path: 'yield',
            beforeEnter: (to, from, next) => {
              this.$store.dispatch('YIELD_CONTROL');
              next('/');
            }
          }
        ]
      },
      {
        name: 'finished',
        path: 'finished',
        component: Finished
      },
      {
        name: 'nearby-lights',
        path: 'nearby',
        component: NearbyLights
      },
      {
        name: 'queue',
        path: 'queue',
        component: Queue
      }
    ]
  },
  {
    path: '/*', // This is a catch all, which will catch when someone tries to enter a custom route, e.g. lukelights.app/somethingthatdoesntexist
    name: 'fallback',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      // There isn't anything for people at random endpoints, so just redirect them back to the homepage
      next('/');
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (['take-control', 'control'].includes(to.name)) {
    checkIsInternalNavigation(to, from, next);
    return;
  }

  next();
});

export default router;

import annyang from 'annyang';
import { SoundwaveAnimation } from '@components';
import constants from "@constants";
import { getAnimationDisplayName } from '@utils';

export default {
  name: "VoiceControl",
  components: {
    SoundwaveAnimation
  },
  data() {
    return {
      command: '',
      comment: ''
    }
  },
  mounted() {
    const commands = {
      'Theme *theme': this.handleThemeChange,
      'Color *theme': this.handleThemeChange,
      'Colour *theme': this.handleThemeChange,
      'Animation *animation': this.handleAnimationChange,
      'Stop animation': () => { this.handleAnimationChange('off') },
      'Set :element to :theme': this.handleChangeElementTheme,
      'Lights off': this.handleLightsOff,
      'Lights on': this.handleLightsOn
    };

    annyang.addCommands(commands);

    annyang.start();
    // annyang.debug();

    annyang.addCallback('soundstart', () => {
      this.command = ``;
      this.comment = '';
    });

    annyang.addCallback('resultNoMatch', () => {
      this.command = `Sorry, I couldn't find a match for that command`;
      this.comment = '';
    });
  },
  /**
   * This will turn off the mic when the user goes to another control view
   */
  beforeDestroy() {
    annyang.removeCallback();
    annyang.abort();
  },
  methods: {
    handleThemeChange(spokenTheme) {
      // Check for an exact match of any of the theme names
      let theme = this.findMatchingTheme(spokenTheme);

      if (!theme) {
        this.command = `Sorry, I couldn't find a match for that command`;
        this.comment = '';
        return;
      }

      this.command = `Changing theme to ${theme.toUpperCase()}`;
      this.$store.dispatch('CHANGE_THEME', { theme });
    },

    findMatchingTheme(spokenTheme) {
      if (Object.values(constants.themes).find(constantTheme => constantTheme === spokenTheme)) {
        return spokenTheme;
      }

      let theme;
      switch (spokenTheme) {
        case 'chick':
        case 'sheek':
          theme = constants.themes.CHIC;
          break;

        default:
          theme = false;
      }

      return theme;
    },

    handleAnimationChange(spokenAnimation) {
      const animation = this.findMatchingAnimation(spokenAnimation);

      if (!animation) {
        this.command = `Sorry, I couldn't find a match for that command`;
        this.comment = '';
        return;
      }

      this.command = `Changing animation to ${getAnimationDisplayName(constants.animations[animation])}`;
      this.comment = '';

      this.$store.dispatch('CHANGE_ANIMATION', { animation })
    },

    findMatchingAnimation(spokenAnimation) {
      // Just returning the string ID for now as it's proving to be a little bit more difficult to get the actual ID from constants,
      // and I just want to get this deployed to test
      switch (spokenAnimation.toLowerCase()) {
        case 'off':
        case 'on':
        case 'steady':
          return 'steady_on';
        case 'alternate pulses':
          return 'alternate_pulses';
        case 'battle flash':
          return 'battle_flash';
        case 'chasing flash':
          return 'chasing_flash';
        case 'decay flash':
        case 'dk flash':
          return 'decay_flash';
        case 'heartbeat':
          return 'heartbeat';
        case 'in waves':
          return 'in_waves';
        case 'ping pong flash':
          return 'ping_pong_flash';
        case 'pulsing':
          return 'pulsing';
        case 'sequential':
          return 'sequential';
        case 'slow glow':
          return 'slo_glo';
        case 'slow fade':
          return 'slow_fade';
        case 'twinkle flash':
          return 'twinkle_flash';
      }
    },

    handleChangeElementTheme(element, theme) {
      this.command = 'Change element theme';
      this.comment = 'I love that you tried this! Unfortunately I need more code before it will work :(';
      // this.command = `${element}: ${theme}`;
      // this.comment = '';
    },

    handleLightsOff() {
      this.$store.dispatch('CHANGE_THEME', { theme: constants.themes.OFF });
      this.command = 'Turning lights off!';
      this.comment = 'Is it dark in here?';
    },

    handleLightsOn() {
      this.$store.dispatch('CHANGE_THEME', { theme: constants.themes.CLASSIC });
      this.command = 'Lights on';
      this.comment = 'Lets get crazy!';
    }
  }
}

"use strict";

const BaseAnimation = require('./BaseAnimation');

/**
 * Turn the element off
 *
 * CH1 |_|
 * CH2 |_|
 */
class Off extends BaseAnimation {
  constructor() {
    super();

    this.frameLength = 10; // Length in "ticks"
    this.totalFrames = 1;
    this.setMaxTicks();
    this.id = 'off';

    this.channels = {
      1: [],
      2: []
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Off';
  }
}

module.exports = Off;

"use strict";

const BattleFlash = require('../battle-flash');

/**
 * The first half of the Battle Flash animation
 *
 * This is the chasing-flash animation from the Myer Vue lights
 *
 * CH1 |X|X|_|_|_|_|_|_|X|X|_|_|_|_|_|_|X|X|_|_|_|_|_|X|_|X|_|X|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 */
class BattleFlashOne extends BattleFlash {
  constructor() {
    super();

    this.channels = {
      1: [
        0, 1, 8, 9, 16, 17, 23, 25, 27
      ],
      2: [
        0, 1, 8, 9, 16, 17, 23, 25, 27
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '1st';
  }
}

module.exports = BattleFlashOne;

/**
 * Application configuration object
 *
 * All static configuration values will come from this file.
 * Try and keep any calculation of new values outside, unless there is a very good reason, in which case document it well
 */

// Determine if the application is running in development mode
const isNode = typeof window === 'undefined';
const isWindows = process.platform.indexOf('win32');

let isDev = false; // This won't do anything for the database
if (isNode) {
  const yargs = require('yargs').argv;
  isDev = yargs.isDev === 'true';
} else {
  isDev = window.location.hostname === 'localhost';
}

// The port number to use for local DB instance when `firebase-db-server.js` is running
const localDBPort = 5010;
const seconds = 1000; // Store as variable to make setting the actual time limits below a bit easier
const tickLength = 10; // 1 tick per every 1000th second - Used for setInterval timer - 100 ticks per 1 second

module.exports = {
  isDev,

  // The data port of the controller
  port: isWindows >= 0 ? 'COM5' : '/dev/ttyUSB0',
  midiInput: isWindows >= 0 ? 'USB Midi         0' : 'USB Midi:USB Midi MIDI 1 20:0',
  midiOutput: isWindows >= 0 ? 'USB Midi         1' : '',

  // The time between 'ticks' in the animation. This is essentially the frame rate
  tickLength,
  ticksPerSecond: seconds / tickLength,

  // The amount of time after the last control action to revert back to regular looping
  controlTimeout: 30 * seconds, // 30 seconds

  // The amount of time that someone can keep controlling the lights once someone has joined the queue
  timeLimit: 60 * seconds, // 1 minute

  // The URL for cloud functions
  cloudFunctionsUrl: isDev
    ? 'http://localhost:5000/christmaslights-87eda/us-central1' // Is production
    : 'https://us-central1-christmaslights-87eda.cloudfunctions.net', // Is dev. Use `yarn serve:functions` to serve up the local Firebase functions
    // The PORT number used in this URL may need to be changed depending on whether serving only functions or not
    // If serving only functions, then will this PORT is fine, however if serving hosting too, then this port will probably need to be changed

  localDBPort,

  // Configuration for connecting to Firebase
  firebase: {
    "apiKey": "AIzaSyBJOHV-6Ll22QwHbE7d5pqEKH3wb9ge3Tk",
    "authDomain": "christmaslights-87eda.firebaseapp.com",

    ...(true || !isDev) && { // Is production - forcing to true as there appears to be a bug with using local database instance and `once`
      "databaseURL": "https://christmaslights-87eda.firebaseio.com/", // Production database URL
    } || { // Is dev
      "databaseURL": `ws://localhost:${localDBPort}`, // This needs to match localDBPort above
    },


    "projectId": "christmaslights-87eda"
  }
};

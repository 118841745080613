/**
 * Take a bright value (0-255) and convert it to a percentage decimal
 */
const maxBrightness = 250;

export default (brightness) => {
  if (brightness > maxBrightness) {
    return 1;
  }

  if (brightness < 0) {
    return 0;
  }

  return brightness / maxBrightness;
};

import Analytics from "@utils/Analytics";

export default {
  name: "SocialMediaLinks",
  methods: {
    trackNetworkUsed(network) {
      Analytics.FireFeatureUsed('social_link_clicked', network)
    }
  }
};

export default {
  name: "HousePartSelector",
  computed: {
    selectedHousePart: {
      get() {
        return this.$store.getters.selectedHousePart
      },
      set(housePart) {
        this.$store.dispatch('UPDATE_SELECTED_HOUSE_PART', { housePart });
      }
    }
  }
}

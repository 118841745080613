"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * Heartbeat animation
 * Make an Heartbeat too
 *
 * Both channels off
 * Fade both channels in to 100%
 * Fade both channels out to 50%
 * Fade both channel in to 100%
 * Fade both channels out to off
 * Repeat
 *
 * @returns {null}
 */
class Heartbeat extends FadeBaseAnimation {
  constructor() {
    super();

    this.id = 'heartbeat';
    this.frameLength = 5; // Length in "ticks"
    this.totalFrames = 100;
    this.setMaxTicks();
    this.fadeLength = 30; // Length in ticks. ~ 300ms
    this.fadeIncrement = 250 / this.fadeLength;

    const channelOneOn = [];
    const channelTwoOn = [];
    let fadeLevel = 0;

    // 0 - 100%
    for (let ch1FrameFadeUp = 0 ; ch1FrameFadeUp <= 29 ; ch1FrameFadeUp++) {
      channelOneOn.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(fadeLevel++ * this.fadeIncrement)
      });
    }
    // 100 - 50%
    for (let ch1FrameFadeHalfDown = 30 ; ch1FrameFadeHalfDown <= 49 ; ch1FrameFadeHalfDown++) {
      channelOneOn.push({
        frame: ch1FrameFadeHalfDown,
        brightness: Math.ceil(fadeLevel-- * this.fadeIncrement)
      });
    }

    // 50-100%
    for (let ch1FrameFadeBackUp = 50 ; ch1FrameFadeBackUp <= 69 ; ch1FrameFadeBackUp++) {
      channelOneOn.push({
        frame: ch1FrameFadeBackUp,
        brightness: Math.ceil(fadeLevel++ * this.fadeIncrement)
      });
    }

    // 100 - 0%
    for (let ch1FrameFadeDown = 70 ; ch1FrameFadeDown <= 99 ; ch1FrameFadeDown++) {
      channelOneOn.push({
        frame: ch1FrameFadeDown,
        brightness: Math.ceil(fadeLevel-- * this.fadeIncrement)
      });
    }

    this.channels = {
      1: channelOneOn,
      2: channelOneOn
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Heartbeat';
  }
}

module.exports = Heartbeat;

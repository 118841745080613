import firebase from "firebase/app";
import 'firebase/database';
import router from '@router';
import constants from "@constants";
import Analytics from "@utils/Analytics";
import AJAX from "../../utils/AJAX";

const state = {};
const mutations = {};
const actions = {
  CHANGE_ANIMATION({ getters, dispatch }, payload) {
    // User isn't in control, not allowed to change animation
    if (!getters.isInControl) {
      dispatch('alert', { message: `Unable to change animation, it appears you're not in control! Please press Take Control button again`, type: 'info' });
      router.push({ path: '/' });
      return;
    }

    if (getters.selectedElements.length === 0) {
      dispatch('alert', { message: 'Please select which part of the house you wish to animate', type: 'info' });
      return;
    }

    const { animation, elements = false, control = constants.controlNames.CONTROL__ANIMATIONS } = payload;

    Analytics.FireFeatureUsed('change_animation', {
      key: getters.clientKey,
      animation
    });


    firebase.database().ref('/').update({
      'action/animation': animation,
      'action/elements': elements === false ? getters.selectedElements.join(',') : elements,
      'action/control': control,
      updatingKey: getters.clientKey
    });
  }
};
const getters = {};

export default {
  state,
  mutations,
  actions,
  getters
};

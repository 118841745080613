import { CountdownTimer } from "@components";
import { controlNames } from '@constants';

export default {
  name: 'Title',
  components: {
    CountdownTimer
  },
  methods: {
    isBackButtonDisplayed() {
      return Object.values(controlNames).includes(this.$route.name)
    }
  }
}

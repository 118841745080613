'use strict';

const DMX = require('dmx');
const console = require('better-console');
const animations = require('../../src/animations');
const groupAnimations = require('../../src/group-animations');
const constants = require('../../src/constants');
const appConfig = require('../../application-configuration');
const firebase = require("firebase");
const findElementIndex = require('./utils/find-element-index');
const findElementIndexes = require('./utils/find-element-indexes');

const { byElement: elements, combinations } = constants.elementPorts;

/**
 * Animator class
 *
 * This is the main class that is used to control everything.
 * It will receive "ticks" from the main loop, and then update animation frames accordingly.
 * It will also change animations based upon instructions that is handed to it
 *
 */
class Animator {

  /**
   * @param {{
   *   isViewingOnly: Boolean
   * }} config
   */
  constructor(config = {}) {
    this.connection = null;

    const defaultConfig = {
      isViewingOnly: false
    };

    this.config = {
      ...defaultConfig,
      ...config
    };

    if (!this.config.isViewingOnly) {
      this.initDMX();
    }

    this.initAnimation(); // For regular looping
    this.initAnimations(); // For "Take Control"
    this.initIndexTracker();
    this.isSlave = false;
  }

  /**
   * Initialise the DMX plugin
   *
   * @returns {null}
   */
  initDMX() {
    this.dmx = new DMX();
    this.universe = this.dmx.addUniverse('demo', 'enttec-open-usb-dmx', appConfig.port);
  }

  /**
   * Initialise the starting animation
   *
   * @return {null}
   */
  initAnimation() {
    const secondsInTicks = 100; // 1 tick every 0.01 of a second, multiply by 100 is 1 second
    const lengthOfAnimationInSeconds = 30;
    this.animationTickCounter = 0; // Tracking ticks relative to needing a new animation
    this.currentAnimationId = Object.keys(constants.animationsLoop)[0]; // Set the starting animation to the first one
    this.setNewAnimation();
    this.animation = new animations[this.currentAnimation]();
    this.lengthOfAnimation = lengthOfAnimationInSeconds * secondsInTicks;
  }

  /**
   * Initialise the starting animations for each element
   * These animations are what will be changing when a user takes control of the system
   *
   * @returns {null}
   */
  initAnimations() {
    const { byElement } = constants.elementPorts;
    const elementNames = Object.keys(byElement);
    const offAnimation = this.getAnimationClass('off');

    /**
     * @type {{element: string, animation: BaseAnimation}[]}
     */
    this.elementAnimations = elementNames.map((id) => ({
      element: id,
      animation: new animations[offAnimation]()
    }));
  }

  /**
   * Initialise the index tracker
   * The index will keep track of which combination is currently active, and therefore which combination
   * will be next
   *
   * The index tracker is only used when the RPi is in master mode
   *
   * @returns {null}
   */
  initIndexTracker() {
    // Track the index of each combination that is currently active
    this.indexTracker = 0;
    // combinations.forEach((combination, index) => {
    //   this.indexTracker[index] = 0;
    // });
  }

  /**
   * Call the tick function for each active animation
   *
   * @returns {null}
   */
  tick() {
    if (this.getIsSlave()) {
      this.elementAnimations.forEach(({ animation }) => {
        animation.tick();
      });
    }

    if (this.getIsMaster()) {
      this.animation.tick();
    }
  }

  /**
   * Perform the next frame for each elements animation
   *
   * @returns {null}
   */
  performNextAction() {
    const nextValues = this.getNextValues();

    if (Object.keys(nextValues).length > 0) {
      this.sendData(nextValues);
    }

    if (this.getIsMaster()) {
      // Check if a new animation is required
      if (++this.animationTickCounter >= this.lengthOfAnimation) {
        this.changeAnimation();
        this.changeCombination();
        this.animationTickCounter = 0;
      }
    }

    // If there is a websocket connection, broadcast the next values so that they might be displayed in browser
    if (this.connection) {
      this.connection.sendUTF(JSON.stringify(nextValues));
    }
  }

  /**
   * Take an animation ID and get the class name
   *
   * @param {string} animationId ID of the animation to find the class name for
   *
   * @returns {string}
   */
  getAnimationClass(animationId) {
    return (Array.isArray(constants.animations[animationId])) ?
      Object.values(constants.animations[animationId][0]).pop() :
      constants.animations[animationId];
  }

  /**
   * Take a group animation ID and get the class name
   *
   * @param {string} animationId ID of the group animation to find the class name for
   *
   * @returns {string}
   */
  getGroupAnimationClass(animationId) {
    return Object.values(groupAnimations).find(animation => animation.id === animationId).name
  }

  /**
   * Update the currentAnimation global variable with the next animation
   * This function will modify a variable outside of its function body
   *
   * @returns {null}
   */
  setNewAnimation() {
    this.currentAnimation = this.getAnimationClass(this.currentAnimationId);

    // If the next animation is "Off", skip it, and select the next animation
    if (this.currentAnimation === 'Off') {
      this.changeAnimation();
      return;
    }

    console.info(`Playing animation: ${this.currentAnimation}`);
    firebase.database().ref().update({
      '/readonly/animation': this.currentAnimation,
      'updatingKey': 'secret-sauce'
    })
  }

  /**
   * Get a single dimension array of combinations
   *
   * @returns {*}
   */
  getCurrentCombination() {
    return combinations[this.indexTracker];
  }

  /**
   * Get the next data object that is to be sent to the controller for the current combination and animation
   *
   * @returns {Record<string, number>}
   */
  getNextValues() {
    if (this.getIsMaster()) {
      return this.getNextLoopValues();
    }

    if (this.getIsSlave()) {
      return this.getNextControlValues();
    }
  }

  /**
   * Get the next values for the regular loop
   * This will be called when the RPi is in master mode
   *
   * @returns {Record<string, number>}
   */
  getNextLoopValues() {
    const nextData = this.animation.getNext();
    const currentCombinations = this.getCurrentCombination();

    const nextValues = {};

    // Ensure elements that are no longer meant to be on, won't be on
    Object.values(elements).forEach((elementData) => {
      const channel1 = elementData[constants.channels.CONST__CH_1];
      if (Array.isArray(channel1)) {
        channel1.forEach(channel => {
          nextValues[channel] = 0;
        })
      } else if (Number.isInteger(channel1)) {
        nextValues[elementData[constants.channels.CONST__CH_1]] = 0;
      } else {
        // Do nothing, this element doesn't have anything plugged into this port
      }

      const channel2 = elementData[constants.channels.CONST__CH_2];
      if (Array.isArray(channel2)) {
        channel2.forEach(channel => {
          nextValues[channel] = 0;
        })
      } else if (Number.isInteger(channel2)) {
        nextValues[elementData[constants.channels.CONST__CH_2]] = 0;
      } else {
        // Do nothing, this element doesn't have anything plugged into this port
      }
    });

    currentCombinations.forEach((combinationElement) => {
      const channel1Number = elements[combinationElement][constants.channels.CONST__CH_1];
      const channel2Number = elements[combinationElement][constants.channels.CONST__CH_2];

      if (Array.isArray(channel1Number)) {
        channel1Number.forEach(channelNumber => {
          nextValues[channelNumber] = nextData[0];
        })
      } else if (Number.isInteger(channel1Number)) {
        nextValues[channel1Number] = nextData[0];
      } else {
        // Do nothing, this element doesn't have anything plugged into this port
      }

      if (Array.isArray(channel2Number)) {
        channel2Number.forEach(channelNumber => {
          nextValues[channelNumber] = nextData[1];
        })
      } else if (Number.isInteger(channel2Number)) {
        nextValues[channel2Number] = nextData[1];
      } else {
        // Do nothing, this element doesn't have anything plugged into this port
      }
    });

    return nextValues;
  }

  /**
   * Get the next values for each of the animations that are currently assigned to an element
   * This will be used when the RPi is in slave mode, and taking commands from a user
   *
   * @returns {Record<string, number>}
   */
  getNextControlValues() {
    const nextData = this.elementAnimations.map(({ element, animation }) => {
      const nextValue = animation.getNext();
      const nextChannelValues = {};

      const channel1 = elements[element][constants.channels.CONST__CH_1];
      if (Array.isArray(channel1)) {
        channel1.forEach(channel => {
          nextChannelValues[channel] = nextValue[0];
        })
      } else if (Number.isInteger(channel1)) {
        nextChannelValues[channel1] = nextValue[0];
      } else {
        // Do nothing, this element doesn't have anything plugged into this port
      }

      const channel2 = elements[element][constants.channels.CONST__CH_2];
      if (Array.isArray(channel2)) {
        channel2.forEach(channel => {
          nextChannelValues[channel] = nextValue[1];
        })
      } else if (Number.isInteger(channel2)) {
        nextChannelValues[channel2] = nextValue[1];
      } else {
        // Do nothing, this element doesn't have anything plugged into this port
      }

      return nextChannelValues;
    });

    // nextData is an array of objects. Using Object.assign and the spread operator, the data can be flattened into a single object
    return Object.assign({}, ...nextData);
  }

  /**
   * Send data to the controller board
   *
   * @param {Object} data
   */
  sendData(data) {
    // console.log(this.debug(data));
    if (!this.config.isViewingOnly) {
      this.universe.update(data);
    }
  }


  /**
   * Change the active animation
   *
   * @returns {null}
   */
  changeAnimation() {
    const animationKeys = Object.keys(constants.animationsLoop);
    const currentAnimationIndex = animationKeys.findIndex(animation => animation === this.currentAnimationId);
    // If the current index is the last one in the array, then the next animation needs to be the first one
    const nextAnimationIndex = currentAnimationIndex === (animationKeys.length - 1) ? 0 : currentAnimationIndex + 1;

    // Create instance of new animation and assign to class variable
    this.currentAnimationId = animationKeys[nextAnimationIndex];
    this.setNewAnimation();
    this.animation = new animations[this.currentAnimation]();
  }

  /**
   * Change the active combination
   *
   * @returns {null}
   */
  changeCombination() {
    ++this.indexTracker;
    if (this.indexTracker >= combinations.length) {
      this.indexTracker = 0;
    }
  }

  /**
   * Simple debug function that will return a string containing the name of the element/s, and their values
   *
   * @param {Object} data The "next data" object
   *
   * @returns {string}
   */
  debug(data) {
    // Create a string with the value for each of the elements that are currently on
    // The string will have each element doubled-up, but this is because each element has 2 channels that it outputs on
    return Object.keys(data).map((channel) => {
      // Find the element that is using `channel`
      const elementName = Object.keys(elements).find((elementKey) => [elements[elementKey][constants.channels.CONST__CH_1], elements[elementKey][constants.channels.CONST__CH_2]].includes(parseInt(channel, 10)));
      return `${elementName}: ${data[channel]}`;
    }).join(', ');
  }

  /**
   * Get the current mode the animator is running in
   *
   * @returns {string}
   */
  getCurrentMode() {
    return this.getIsSlave() ? 'slave' : 'master';
  }

  /**
   * Check if the RPi should be running as a slave
   *
   * @returns {boolean}
   */
  getIsSlave() {
    return this.isSlave;
  }

  /**
   * Check if the RPi should be running as a master
   * Effectively an alias for getIsSlave()
   *
   * @returns {boolean}
   */
  getIsMaster() {
    return !this.getIsSlave();
  }

  /**
   * Tell the RPi to be active as a slave or not
   *
   * @param {Boolean} isSlave Flag indicating if the RPi is in slave mode or not
   *
   * @returns {null}
   */
  setIsSlave(isSlave) {
    this.isSlave = isSlave;
  }

  /**
   * Tell the RPi to be active as a master or not
   * Effectively an alias for setIsSlave()
   *
   * @param {Boolean} isMaster Flag indicating if the RPi is in master mode or not
   *
   * @returns {null}
   */
  setIsMaster(isMaster) {
    this.setIsSlave(!isMaster);
  }

  /**
   * Function to change the animation for a specific element
   *
   * @param {string[]} elements     Array of element ID's to change the animation of
   * @param {string}   newAnimation The new animation to change to
   * @param {{}}       [args]       [Optional] Extra arguments to pass to the animation class instance
   *
   * @returns {void}
   */
  setAnimation(elements, newAnimation, args) {
    if (newAnimation === 'simonsays') {
      const animationClass = this.getGroupAnimationClass(newAnimation);
      const newAnimations = this.elementAnimations.map((elementAnimation) => {
        const { element } = elementAnimation;

        const elementIndexes = findElementIndexes(elements, element);
        if (elementIndexes.length === 0) {
          return elementAnimation;
        }

        const simonSaysAnimation = new groupAnimations[animationClass]({ sequencePositions: elementIndexes, totalElements: elements.length, ...args })

        return {
          ...elementAnimation,
          animation: simonSaysAnimation
        }
      })

      this.elementAnimations = newAnimations;
      return;
    }
    if (Object.keys(groupAnimations).map(key => key.toLowerCase()).includes(newAnimation.toLowerCase())) {
      const animationClass = this.getGroupAnimationClass(newAnimation);
      // This is the section where I should throw to a "Group Animation" handler, as I will want to add more than just sweep, like bounce, reverse sweep etc
      const newAnimations = this.elementAnimations.map((elementAnimation) => {
        const { element } = elementAnimation;

        const elementIndex = findElementIndex(elements, element);
        if (elementIndex < 0) {
          return elementAnimation;
        }

        return {
          ...elementAnimation,
          animation: new groupAnimations[animationClass]({ sequencePosition: elementIndex, totalElements: elements.length, ...args })
        }
      });

      this.elementAnimations = newAnimations;

      return;
    }

    const animationClass = this.getAnimationClass(newAnimation);

    const newAnimations = this.elementAnimations.map((animation) => {
      const { element } = animation;

      if (!elements.includes(element)) {
        return animation;
      }

      if (!animations[animationClass]) {
        // ! This is a bug because when I set santa wave or reindeer walk, I'm setting a value to animation in the DB that doesn't exist
        // ! When changing the theme, that will re-apply the animation that is in the database, so at that point it's looking
        // ! for the reindeer_walk or santa_wave animations, which obviously don't exist.
        // ! The following it just temporary as this is the only time that it's an issue, I believe..
        // ! Will have to test what happens when I set up elements with their own animation, then use santa wave, then change theme, does that break the existing animation
        // console.error(`"${newAnimation}" doesn't exist. Sticking with existing animation`);
        return {
          ...animation,
          animation: new animations['SteadyOn'](args)
        }
      }

      return {
        ...animation,
        animation: new animations[animationClass](args)
      }
    });

    this.elementAnimations = newAnimations;
  }

  /**
   * Get the currently set animations
   *
   * @returns {*}
   */
  getElementAnimations() {
    return this.elementAnimations;
  }

  /**
   * Get the current animation for a provided elementID
   *
   * @param {string} elementId The ID of the element to find the animation of
   *
   * @returns {string} The name of the animation that is currently playing
   */
  getElementAnimation(elementId) {
    return this.elementAnimations.find(elementAnimation => elementId === elementAnimation.element).animation;
  }
}

module.exports = Animator;

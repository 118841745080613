"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * Slow Fade animation
 *
 * Both channels fade in slowly
 * Both channels fade out slowly
 */
class SlowFade extends FadeBaseAnimation {
  constructor() {
    super();

    this.id = 'slow_fade';
    this.frameLength = 15; // Length in "ticks"
    this.totalFrames = 120;
    this.setMaxTicks();
    this.fadeLength = 60; // Length in ticks. ~ 300ms
    this.fadeIncrement = 250 / this.fadeLength; // +1 ensures brightness gets to 100%. This will happen for multiple frames, but that's alright

    const channelOneOn = [{
      frame: 0,
      brightness: 0
    }];

    for (
      let ch1FrameFadeUp = 1
      ; ch1FrameFadeUp < (this.totalFrames / 2)
      ; ch1FrameFadeUp++
    ) {
      channelOneOn.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeUp)
      });
    }

    for (
      let ch1FrameFadeDown = (this.totalFrames / 2), ch1FrameFadeDownMultiplier = (this.totalFrames / 2) - 1
      ; ch1FrameFadeDown < this.totalFrames
      ; ch1FrameFadeDown++, ch1FrameFadeDownMultiplier--
    ) {
      channelOneOn.push({
        frame: ch1FrameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeDownMultiplier)
      });
    }

    this.channels = {
      1: channelOneOn,
      2: channelOneOn
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Slow Fade';
  }
}


module.exports = SlowFade;

const themes = require('./themes');
const themeGroups = require('./theme-groups');

// @TODO This needs to be fixed up for 2020

module.exports = {
  FACADE: {
    id: 'facade',
    name: 'Facade',
    availableThemes: [
      ...Object.keys(themes)
    ]
  },


  FENCE: {
    id: 'fence',
    name: 'Fence',
    availableThemes: [
      ...Object.keys(themes)
    ]
  },

  GARDEN: {
    id: 'garden',
    name: 'Garden',
    availableThemes: [
      ...Object.keys(themes)
    ]
  },

  LETTERBOX_TREES: {
    id: 'letterbox_trees',
    name: 'Letterbox Trees',
    availableThemes: [
      ...Object.keys(themes)
    ]
  },

  REINDEER: {
    id: 'reindeer',
    name: 'Reindeer',
    availableThemes: [
      'on', themes.OFF
    ]
  },

  ROOF: {
    id: 'roof',
    name: 'Roof',
    availableThemes: [
      ...Object.keys(themes)
    ]
  },

  TREE: {
    id: 'tree',
    name: 'Christmas Tree',
    availableThemes: [
      ...Object.keys(themes)
    ]
  }
};

"use strict";

const BaseAnimation = require('./BaseAnimation');

/**
 * Chasing Flash animation
 *
 * Channel 1 flash on/off 3 times
 *
 * Channel 2 flash on/off 3 times
 *
 * Channel 1 on, channel 2 off
 * Channel 1 off, channel 2 on
 * repeat 4 more times
 *
 * CH1 |X|_|X|_|X|_|_|_|_|_|_|_|X|X|X|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|X|_|X|_|X|_|_|_|_|_|X|X|X|_|_|
 *
 */
class ChasingFlash extends BaseAnimation {
  constructor() {
    super();

    this.id = 'chasing_flash';
    this.frameLength = 15; // Length in "ticks"
    this.totalFrames = 21;
    this.setMaxTicks();

    this.channels = {
      1: [
        0, 2, 4, 12, 13, 14
      ],
      2: [
        6, 8, 10, 16, 17, 18
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Chasing Flash';
  }
}

module.exports = ChasingFlash;

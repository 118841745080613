import moment from 'moment-timezone';

export default class Storage {

  static get CONST__QUEUE_KEY() {
    return 'queueKey';
  }

  static get CONST__HIDE_SPLASH_FOREVER() {
    return 'hideSplashForever';
  }

  static get CONST__VISITED_YEARS() {
    return 'visitedYears';
  }

  static setItem(key, value) {
    localStorage.setItem(key, value);
  }

  static getItem(key) {
    return localStorage.getItem(key);
  }

  static removeItem(key) {
    localStorage.removeItem(key);
  }

  /**
   * Increase the count of visits for this user for this year
   *
   * @returns {null}
   */
  static saveVisit() {
    let visitedYears = Storage.getItem(Storage.CONST__VISITED_YEARS);
    const adelaideMoment = moment().tz('Australia/Adelaide');
    const year = adelaideMoment.year();
    const dateString = `${adelaideMoment.year()}-${adelaideMoment.month()}-${adelaideMoment.date()}`;

    if (!visitedYears) { // If there isn't any saved data for the user, create an empty object
      visitedYears = {};
    } else { // Otherwise parse the saved data
      visitedYears = JSON.parse(visitedYears);
    }

    if (!visitedYears[year]) { // If there isn't any saved data for this year, create the property and give it the value 1
      visitedYears[year] = 1;
    } else if(visitedYears.lastVisit !== dateString) { // Otherwise if the user hasn't already visited today, increase their visited count
      visitedYears[year] = visitedYears[year] + 1;
    }

    // Update the last visited time to be today
    visitedYears.lastVisit = dateString;

    Storage.setItem(Storage.CONST__VISITED_YEARS, JSON.stringify(visitedYears));
  }

  /**
   * Store the name of the BYO Theme that this user is the author of. This will allow them to overwrite the
   *
   * @param {string} name The formatted name of the BYO Theme (i.e. there are no spaces)
   *
   * @returns {null}
   */
  static SetIsAuthorOfBYOTheme(name) {
    const authoredThemes = Storage.GetAuthoredBYOThemes();
    const updatedAuthoredThemes = new Set([
      ...authoredThemes,
      name
    ]);
    // Calling JSON.stringify on a Set resolves to an empty object, so need to convert it to an array first
    localStorage.setItem('authored-themes', JSON.stringify(Array.from(updatedAuthoredThemes)));
  }

  /**
   * Save the elements that a user has added to their BYO Theme.
   * This will be used in case a user loses control whilst editing, so they can easily re-gain what they already had
   * If they don't refresh the page, then the theme will already be remembered in state, however if they refresh the page or
   * otherwise navigate, that would be lost
   *
   * @param {string} elements A string of element ID's that are part of this users (in progress) BYO Theme
   */
  static SaveBYOThemeInProgress(elements) {
    localStorage.setItem('byo-theme-in-progress', elements);
  }

  /**
   * Clear the BYO Theme in progress property
   * This is likely because the user has been able to save it to Firebase
   */
  static ClearBYOThemeInProgress() {
    localStorage.removeItem('byo-theme-in-progress');
  }

  /**
   * Get saved elements that a user had added to their BYO Theme before they lost control or otherwise had to stop editing it
   *
   * @returns {string}
   */
  static GetBYOThemeInProgress() {
    return localStorage.getItem('byo-theme-in-progress') || [];
  }

  /**
   * Get array of themes that this user has authored
   *
   * @returns {[String]}
   */
  static GetAuthoredBYOThemes() {
    const authoredThemes = localStorage.getItem('authored-themes');
    if (!authoredThemes) {
      return [];
    }

    return JSON.parse(authoredThemes);
  }
}

"use strict";

const GroupAnimation = require('./GroupAnimation');

/**
 * Bounce animation
 *
 * See docblock in sweep.js for explanation of how the group animations work
 *
 * Visualisation
 * Frames ->
 * E1 |X|_|_|_|_|_|_|_|_|_|_|_|X|
 * E2 |_|X|_|_|_|_|_|_|_|_|_|X|_|
 * E3 |_|_|X|_|_|_|_|_|_|_|X|_|_|
 * E4 |_|_|_|X|_|_|_|_|_|X|_|_|_|
 * E5 |_|_|_|_|X|_|_|_|X|_|_|_|_|
 * E6 |_|_|_|_|_|X|_|X|_|_|_|_|_|
 * E* |_|_|_|_|_|_|X|_|_|_|_|_|_|
 *
 * The X is when the element should start at full brightness then fade out
 * As we can see, the final element is only on for one of the frames
 */
class Bounce extends GroupAnimation {
  static id = 'bounce';
  constructor(...args) {
    super(...args);

    this.frameLength = 10;
    this.fadeLength = 10; // Number of frames
    this.totalFrames = ((this.frameLength * 2) -1) + this.totalElements;
    this.setMaxTicks();
    this.fadeDelta = 250 / this.fadeLength;
    // @TODO Because this animation uses this.totalElements in the frame calculations below, some refactoring is likely required to get it to work with this.startingFrame

    const channelOn = [{ frame: this.sequencePosition, brightness: 250 }];

    for (let frameFadeDown = (this.sequencePosition + 1), frameFadeDownMultiplier = this.fadeLength ; frameFadeDown < (this.sequencePosition + this.fadeLength) ; frameFadeDown++, frameFadeDownMultiplier--) {
      channelOn.push({
        frame: frameFadeDown,
        brightness: Math.ceil(this.fadeDelta * frameFadeDownMultiplier)
      });
    }

    // The last element in the series will be the "turning point", and we don't want it to light up twice in a row.
    if ((this.totalElements - 1) !== this.sequencePosition) {
      const secondPointStart = (this.sequencePosition + ((this.totalElements - this.sequencePosition) * 2) - 1);
      for (let frameFadeDown = secondPointStart, frameFadeDownMultiplier = this.fadeLength ; frameFadeDown < (secondPointStart + this.fadeLength) ; frameFadeDown++, frameFadeDownMultiplier--) {
        const index = channelOn.findIndex(channel => channel.frame === frameFadeDown)
        if (index !== -1) {
          channelOn[index] = {
            frame: frameFadeDown,
            brightness: Math.ceil(this.fadeDelta * frameFadeDownMultiplier)
          }
        } else {
          channelOn.push({
            frame: frameFadeDown,
            brightness: Math.ceil(this.fadeDelta * frameFadeDownMultiplier)
          });
        }
      }
    }

    this.channels = {
      1: channelOn,
      2: channelOn
    };

  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Bounce';
  }
}

module.exports = Bounce;

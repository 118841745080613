/**
 * Get the current location of the user
 *
 * @returns {Promise}
 */
export default async function getLocation() {
  return new Promise((res, rej) => {
    if (localStorage.getItem('isJudge')) {
      console.log(`User is a judge, skipping location`);
      res({
        latitude: -35.01539459774022,
        longitude: 138.6096507894968
      })
      return;
    }
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      res({
        latitude,
        longitude
      });
    }, (error) => {
      if (localStorage.getItem('isJudge')) {
        console.log(`User is a judge, skipping location`);
        res({
          latitude: -35.01539459774022,
          longitude: 138.6096507894968
        })
      }
      rej(new Error(`${error.code} ${error.message}`))
    }, { enableHighAccuracy: true });
  });
}

import firebase from "firebase/app";
import 'firebase/database';
import constants from '@constants'
import command from "@utils/command";

export default {
  name: "SimonSays",
  data() {
    return {
      selectedElements: [],
      status: 'init',
      elements: constants.elements, // This is obviously gross, but it's the easiest way to get the elements into the Vue component
      dbRef: null,
      isAboutToStart: false
    };
  },
  mounted() {
    command('simon-says', {
      action: 'init'
    });

    this.dbRef = firebase.database().ref('/command');
    this.dbRef.on('value', (snapshot) => {
      const command = JSON.parse(snapshot.val());
      this.handleRemoteCommand(command)
    })
  },
  beforeDestroy() {
    this.dbRef.off('value');
  },
  methods: {
    resetGame() {
      command('simon-says', {
        action: 'init'
      });
      this.selectedElements = [];
      this.status = 'init';
    },
    handleRemoteCommand(command) {
      if (!command.status) return;
      this.status = command.status;
    },
    toggleElement(element) {
      if (this.status !== 'init') {
        command('simon-says', {
          action: 'sequence-guess',
          element,
          timestamp: Date.now() // Required to make sure sending the same element a second time will be sent to the RPi
        });
        return;
      }

      if (this.selectedElements.includes(element)) {
        this.selectedElements = this.selectedElements.filter(el => el !== element);

        command('simon-says', {
          action: 'remove-element',
          element
        });
        return;
      }

      if (this.selectedElements.length === 5) {
        alert('Maximum number of elements reached! Please press start game or remove an element to add a different one');
        return;
      }

      this.selectedElements.push(element);
      command('simon-says', {
        action: 'add-element',
        element
      })
    },

    startGame() {
      command('simon-says', {
        action: 'start'
      });
      this.status = 'playing-sequence';
      this.isAboutToStart = false;
    }
  }
}

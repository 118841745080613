import { latLng, icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon, LControl } from "vue2-leaflet";
import nearbyLights from './light-locations.json';
import Analytics from "@utils/Analytics";

export default {
  name: "NearbyLights",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
    LControl
  },
  data() {
    return {
      isViewFullscreen: false,
      isTrackingUserPosition: false,
      zoom: 15,
      locations: nearbyLights.map((location) => ({
        ...location,
        latLng: latLng(location.lat, location.lng)
      })),
      viewMode: 'dark',
      lightMode: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      darkMode: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
      currentZoom: 15,
      mapOptions: {
        zoomSnap: 0.5,
        maxZoom: 19
      },
      lukeLightsLocation: latLng(-35.0155484, 138.6097224),
      userLocation: latLng(0, 0),
      center: latLng(0, 0),
      userIcon: icon({
        iconUrl: "/images/map-marker-user.png",
        iconSize: [23, 32],
        iconAnchor: [12, 32]
      }),
      lightIcon: icon({
        iconUrl: "/images/map-marker-light.png",
        iconSize: [23, 32],
        iconAnchor: [12, 32]
      }),
      lukeLightsIcon: icon({
        iconUrl: "/images/map-marker-luke-lights.png",
        iconSize: [38, 52],
        iconAnchor: [19, 52]
      })
    };
  },
  mounted() {
    Analytics.FireFeatureUsed('nearby_lights', 'visited');

    const handleError = (positionError) => {
      console.error(positionError);
    }
    const options = { enableHighAccuracy: true };

    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      this.userLocation = latLng(latitude, longitude);
      this.center = latLng(latitude, longitude);
    }, handleError, options);
    navigator.geolocation.watchPosition((position) => {
      const { latitude, longitude } = position.coords;
      this.userLocation = latLng(latitude, longitude);

      if (this.isTrackingUserPosition) {
        this.center = latLng(latitude, longitude);
      }

    }, handleError, options);

    this.$nextTick(() => { // Ensures we're only accessing the $refs when they're made available
      this.$refs.myMap.mapObject.on('dragstart', () => {
        if (this.isTrackingUserPosition) {
          this.isTrackingUserPosition = false;
        }
      });
      this.$refs.myMap.mapObject.on('zoomstart', () => {
        if (this.isTrackingUserPosition) {
          this.isTrackingUserPosition = false;
        }
      });
    });

  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    toggleFullScreen() {
      const nextIsViewFullscreen = !this.isViewFullscreen; // Just in case the update to this.isViewFullscreen isn't immediate - I want the next value in analytics
      this.isViewFullscreen = nextIsViewFullscreen;
      Analytics.FireFeatureUsed('nearby_lights_fullscreen', nextIsViewFullscreen)
    },
    toggleViewMode() {
      const nextViewMode = this.viewMode === 'dark' ? 'light' : 'dark';
      this.viewMode = nextViewMode;
      Analytics.FireFeatureUsed('nearby_lights_viewmode', nextViewMode)
    },
    enableTrackUserPosition() {
      this.isTrackingUserPosition = true;
      this.center = this.userLocation;
      this.zoom = 15;
    }
  }
};

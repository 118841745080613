"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * Ping Pong Flash animation
 *
 * Channel 1 flash on/off 3 times
 *
 * Channel 2 flash on/off 3 times
 *
 * Channel 1 on, channel 2 off
 * Channel 1 off, channel 2 on
 * repeat 4 more times
 *
 * CH1 |X|_|X|_|X|_|_|X|_|X|_|X|_|X|_|_|_|_|_|_|_|_|_|
 * CH2 |_|X|_|X|_|X|_|_|_|_|_|_|_|_|_|X|_|X|_|X|_|X|_|
 *
 */
class PingPongFlash extends FadeBaseAnimation {
  constructor() {
    super();

    this.id = 'ping_pong_flash';
    this.fadeLength = 6; // Length in ticks. ~ 300ms
    this.frameLength = 15; // Length in "ticks"
    this.fadeIncrement = 250 / this.fadeLength;

    // ? Channel 1 isn't actually longer than channel 2
    // ? Instead, the animation repeats immediately without a break, so it looks like channel 1 is on for longer at the end,
    // ? when in actual fact, it's just re-started the animation loop

    this.channels = {
      1: [
        0, 2, 4, 6, 8
      ],
      2: [
        1, 3, 5, 7, 9
      ]
    };

    this.frameHoldBuffer = 15; // Padding at the end where both channels will be on
    this.ch1FrameFadeStart = 9;
    this.ch1FrameHoldStart = this.ch1FrameFadeStart + this.fadeLength;
    this.ch2FrameFadeStart = 12;
    this.ch2FrameHoldStart = this.ch2FrameFadeStart + this.fadeLength;

    for (let ch1FrameFadeUp = this.ch1FrameFadeStart, ch1FadeUpMultiplier = 0 ; ch1FrameFadeUp < this.ch1FrameFadeStart + this.fadeLength ; ch1FrameFadeUp++, ch1FadeUpMultiplier++) {
      this.channels[1].push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FadeUpMultiplier)
      });
    }

    for (let ch1FrameFadeHold = this.ch1FrameHoldStart ; ch1FrameFadeHold < this.ch2FrameHoldStart + this.fadeLength + this.frameHoldBuffer ; ch1FrameFadeHold++) {
      this.channels[1].push({
        frame: ch1FrameFadeHold,
        brightness: 250
      });
    }

    for (let ch2FrameFadeUp = this.ch2FrameFadeStart, ch2FadeUpMultiplier = 0 ; ch2FrameFadeUp < this.ch2FrameFadeStart + this.fadeLength ; ch2FrameFadeUp++, ch2FadeUpMultiplier++) {
      this.channels[2].push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FadeUpMultiplier)
      });
    }

    for (let ch2FrameHold = this.ch2FrameFadeStart + this.fadeLength ; ch2FrameHold < this.ch2FrameHoldStart + this.fadeLength + this.frameHoldBuffer ; ch2FrameHold++) {
      this.channels[2].push({
        frame: ch2FrameHold,
        brightness: 250
      });
    }

    this.totalFrames = this.ch2FrameFadeStart + this.fadeLength;
    this.setMaxTicks();
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Ping Pong Flash';
  }
}

module.exports = PingPongFlash;

import { Title } from '@components';
import { listenBYOThemes, listenLightsStatus } from './store/FirebaseListeners';

export default {
  name: "App",
  components: {
    Title
  },
  created () {
    this.$vuetify.theme.dark = true;
    listenBYOThemes();
    listenLightsStatus();
  }
}

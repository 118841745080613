"use strict";

const SloGlo = require('../slo-glo');

/**
 * Second half of the Slo Glow animation
 *
 * CH1 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 *
 */
class SloGloTwo extends SloGlo {
  constructor() {
    super();

    const channelOneOn = [];
    const channelTwoOn = [];
    for (let ch2FrameFadeUp = 60, ch2FrameFadeUpMultiplier = 0 ; ch2FrameFadeUp <= 89 ; ch2FrameFadeUp++, ch2FrameFadeUpMultiplier++) {
      channelOneOn.push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FrameFadeUpMultiplier)
      });
      channelTwoOn.push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FrameFadeUpMultiplier)
      });
    }

    for (let ch2FrameFadeUp = 90, ch2FrameFadeDownMultiplier = 29 ; ch2FrameFadeUp <= 119 ; ch2FrameFadeUp++, ch2FrameFadeDownMultiplier--) {
      channelOneOn.push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FrameFadeDownMultiplier)
      });
      channelTwoOn.push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FrameFadeDownMultiplier)
      });
    }

    this.channels = {
      1: channelOneOn,
      2: channelTwoOn
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '2nd';
  }
}

module.exports = SloGloTwo;

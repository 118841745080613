"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * Pulse animation
 *
 * This animation is intended to be a one-and-done.
 * It was created to be used with the Simon Says game
 *
 * @returns {null}
 */
class Pulse extends FadeBaseAnimation {
  constructor() {
    super();

    const fullBrightness = 250;
    this.id = 'pulse';
    this.frameLength = 5; // Length in "ticks"
    this.totalFrames = 13;
    this.litLength = 8;
    this.setMaxTicks();
    this.fadeLength = 4 ; // Length in ticks
    this.fadeIncrement = fullBrightness / this.fadeLength; // +1 ensures brightness gets to 100%. This will happen for multiple frames, but that's alright

    const channelOn = [];
    let animationFrameCounter = 0;

    for (let i = 0 ; i < this.litLength ; i++) {
      channelOn.push({
        frame: animationFrameCounter,
        brightness: 250
      })
      animationFrameCounter++;
    }

    // j starts at 1 because we don't want to double up on 250
    // It will finish less than the fade length, because we don't want to end on 0, that would mean a double up of 0 at the end and round to the start
    for (let j = 0 ; j <= this.fadeLength ; j++) {
      channelOn.push({
        frame: animationFrameCounter,
        brightness: Math.ceil(fullBrightness - this.fadeIncrement * j)
      });
      animationFrameCounter++;
    }

    this.channels = {
      1: channelOn,
      2: channelOn
    };

  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Pulse';
  }
}

module.exports = Pulse;

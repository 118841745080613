import Vue from 'vue';
import LogRocket from 'logrocket';
import firebase from "firebase/app";
import App from './App.vue';
import NoApp from './NoApp.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import router from './router';
import getLocation from "./utils/get-location";
import appConfig from "../application-configuration";

Vue.config.productionTip = false;
import 'leaflet/dist/leaflet.css';

firebase.initializeApp(appConfig.firebase);

if (location.hostname !== 'localhost') {
  LogRocket.init('6cbvkc/luke-lights');
}
if (location.search.includes('special')) {
  localStorage.setItem('isJudge', true);
}
const isJudge = localStorage.getItem('isJudge');
const geolocationAvailable = 'geolocation' in navigator;
if (!geolocationAvailable && !isJudge) {
  // User doesn't have the ability to get geolocation from their device, so they can't access the control panel for the lights
  // Annoyingly I've had to double up this mounting code, even though the only difference is `NoApp` vs `App` because I have
  // to make an asynchronous call to request access to the users location
  new Vue({
    store,
    vuetify,
    router,
    render: h => h(NoApp),
    propsData: {
      oldDevice: true
    }
  }).$mount('#app');

} else {
  if (isJudge) {
    new Vue({
      store,
      vuetify,
      router,
      render: h => h(App)
    }).$mount('#app');
  } else {
    getLocation()
      .then(() => {
        new Vue({
          store,
          vuetify,
          router,
          render: h => h(App)
        }).$mount('#app');
      })
      .catch(() => {
        new Vue({
          store,
          vuetify,
          router,
          render: h => h(NoApp)
        }).$mount('#app');
      });

  }


}



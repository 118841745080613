"use strict";

const PingPongFlash = require('../ping-pong-flash');

/**
 * The first half of the Ping Pong flash animation
 *
 * CH1 |X|_|X|_|X|_|_|X|_|X|_|X|_|X|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 *
 */
class PingPongFlashOne extends PingPongFlash {
  constructor() {
    super();

    this.channels = {
      1: [
        0, 2, 4, 6, 8
      ],
      2: [
        0, 2, 4, 6, 8
      ]
    };

    for (let ch1FrameFadeUp = this.ch1FrameFadeStart, ch1FadeUpMultiplier = 0 ; ch1FrameFadeUp < this.ch1FrameFadeStart + this.fadeLength ; ch1FrameFadeUp++, ch1FadeUpMultiplier++) {
      this.channels[1].push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FadeUpMultiplier)
      });
      this.channels[2].push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FadeUpMultiplier)
      });
    }

    for (let ch1FrameFadeHold = this.ch1FrameHoldStart ; ch1FrameFadeHold < this.ch2FrameHoldStart + this.fadeLength + this.frameHoldBuffer ; ch1FrameFadeHold++) {
      this.channels[1].push({
        frame: ch1FrameFadeHold,
        brightness: 250
      });
      this.channels[2].push({
        frame: ch1FrameFadeHold,
        brightness: 250
      });
    }
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '1st';
  }
}

module.exports = PingPongFlashOne;

import Filter from 'bad-words';
import { BYOThemeElementSelector } from '@components';
import { getAvailableByoThemeElementThemes } from '@utils/get-available-element-themes';
import Storage from '@utils/Storage';
import { themes } from '@constants';

export default {
  name: 'BYOThemeControl',
  components: {
    BYOThemeElementSelector
  },
  data() {
    return {
      themeNameDialog: false,
      themeIsSaving: false,
      instructionsVisible: false
    };
  },
  computed: {
    themeName: {
      get() {
        return this.$store.getters.byoThemeName;
      },
      set(name) {
        this.$store.dispatch('SET_BYO_THEME_NAME', { name });
        // @TODO Send update to Firebase so that the user doesn't time out
        // @TODO If a user loses control, kill the update being sent to Firebase, and prepare the app to handle that situation gracefully
        // For example, the theme that they save won't be able to be automatically applied, they'll have to wait until next time
      }
    },
    authorName: {
      get() {
        return this.$store.getters.byoThemeAuthorName;
      },
      set(authorName) {
        this.$store.dispatch('SET_BYO_THEME_AUTHOR_NAME', { authorName });
      }
    },
    availableThemes() {
      return getAvailableByoThemeElementThemes(this.$store.getters.selectedByoThemeElement);
    }
  },
  created() {
    // If the user has already started creating their BYO theme, update the lights to display those elements again,
    // now that they've come back to the control
    if (this.$store.getters.isCreatingByoTheme) {
      this.$store.dispatch('PUSH_BYO_THEME_TO_FIREBASE');
    }
  },
  methods: {
    startBYOTheme() {
      this.$store.dispatch('UPDATE_SELECTED_HOUSE_PART', { housePart: '' });
      this.$store.dispatch('CLEAR_BYO_THEME_ELEMENTS');
      this.$store.dispatch('CHANGE_THEME', { theme: themes.OFF });
      this.$store.dispatch('SET_IS_CREATING_BYO_THEME', { isCreating: true });
    },
    saveTheme() {
      this.themeIsSaving = true;
      const filter = new Filter();
      if (filter.isProfane(this.themeName) || filter.isProfane(this.authorName)) {
        this.$store.dispatch('alert', { message: `Let's keep names family friendly`, type: 'info' });
        this.themeIsSaving = false;
        return;
      }

      this.$store.dispatch('SAVE_BYO_THEME', {
        themeName: this.themeName,
        authorName: this.authorName
      }).then((response) => {
        this.themeNameDialog = false;
        this.themeIsSaving = false;
        this.instructionsVisible = true;
        Storage.SetIsAuthorOfBYOTheme(response.data.themeId);
        this.$store.dispatch('SET_BYO_THEME', { theme: response.data.themeId });
      }).catch((response) => {
        // Secondary test in the cloud function, just in case someone gets around the client side validation
        if (response.data.isProfane) {
          this.$store.dispatch('alert', { message: `Let's keep names family friendly`, type: 'info' });
        } else {
          this.$store.dispatch('alert', { message: `Theme name already taken and it appears you're not the author, please try another`, type: 'info' });
        }
        this.themeIsSaving = false;
      });
    }
  }
}

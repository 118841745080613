import { ComingSoonTree } from "@components";

export default {
  name: "CountdownUntilLaunch",
  components: {
    ComingSoonTree
  },
  data() {
    return {

    }
  },
  methods: {
      /**
       * Our JavaScript function, which calculates the days, hours,
       * minutes and seconds left until Christmas day.
       *
       * @params {string} date The date to countdown to
       *
       * @returns {int} Number of day until the date
       */
      calculateChristmasCountdown(date) {
        const compareDate = new Date(date);
        const compareDay = ("0" + compareDate.getDate()).slice(-2);
        const compareMonth = compareDate.getMonth() + 1;

        //Get today's date.
        const now = new Date();

        //Get the current month. Add a +1 because
        //getMonth starts at 0 for January.
        const currentMonth = (now.getMonth() + 1);

        //Get the current day of the month.
        const currentDay = now.getDate();

        //Work out the year that the next Christmas
        //day will occur on.
        let nextChristmasYear = now.getFullYear();
        if(currentMonth === compareMonth && currentDay > compareDay){
          //This year's Christmas Day has already passed.
          nextChristmasYear = nextChristmasYear + 1;
        }

        const nextChristmasDate = `${nextChristmasYear}-${compareMonth}-${compareDay}T12:00:00.000Z`;
        const christmasDay = new Date(nextChristmasDate);

        //Get the difference in seconds between the two days.
        const diffSeconds = Math.floor((christmasDay.getTime() - now.getTime()) / 1000);

        let days = 0;

        //Don't calculate the time left if it is Christmas day.
        if(currentMonth !== compareMonth || (currentMonth === compareMonth && currentDay !== compareDay)){
          //Convert these seconds into days, hours, minutes, seconds.
          days = Math.floor(diffSeconds / (3600*24));
        }

        return days;
      }
  }
}

"use strict";

const ChasingFlash = require('../chasing-flash');

/**
 * The first half of the chasing flash animation
 *
 * CH1 |X|_|X|_|X|_|_|_|_|_|_|_|X|X|X|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 *
 */
class ChasingFlashOne extends ChasingFlash {
  constructor() {
    super();

    this.channels = {
      1: [
        0, 2, 4, 12, 13, 14
      ],
      2: [
        0, 2, 4, 12, 13, 14
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '1st';
  }
}

module.exports = ChasingFlashOne;

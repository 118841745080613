import animations from "@animations";

/**
 * Get the display name for a given animation ID
 *
 * @param {string} animationName The ID of the animation to get the name of
 *
 * @returns {string}
 */
export default function (animationName) {
  // The logic of determining whether there are "halved" animations has been moved here, rather than in the template
  let classInstance = animationName;
  if (Array.isArray(animationName)) {
    classInstance = Object.values(animationName[0])[0];
  }

  return animations[classInstance].getDisplayName();
}

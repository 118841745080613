<template>
  <String class="string--cool-white" :strLength="this.strLength">
    <White :brightness="channelOneBrightness"/>
    <White :brightness="channelTwoBrightness"/>
  </String>
</template>

<script>
  import String from './String';
  import White from '../Lights/White';

  export default {
    name: 'CoolWhiteString',
    components: {
      String,
      White
    },
    props: {
      channelOneBrightness: Number,
      channelTwoBrightness: Number,
      strLength: {
        type: Number,
        default: 20
      }
    }
  };
</script>

<style>

</style>

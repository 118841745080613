"use strict";
const EventEmitter = require('../../packages/server/utils/EventEmitter');
const eventEmitter = EventEmitter.getEventEmitter();

/**
 * Base animation class, that will handle timings
 */
class BaseAnimation {
  constructor() {
    this.currentTick = 0;
  }

  getId() {
    // @TODO Refactor all regular animations to have their ID set as a static property on the class
    return this.constructor.id || this.id;
  }

  /**
   * Relies on this.totalFrames and this.frameLength having been set already in the animations constructor
   */
  setMaxTicks() {
    this.maxTicks = this.totalFrames * this.frameLength;
  }

  /**
   * Update the currentTick that the animations will all be based upon
   *
   * @returns {null}
   */
  tick() {
    this.currentTick++;

    if (this.currentTick >= this.maxTicks) {
      this.currentTick = 0;
      eventEmitter.emit('animation-complete', this);
    }
  }

  /**
   * Get the current frame of the animation
   *
   * @returns {number}
   */
  getFrame() {
    return Math.floor(this.currentTick / this.frameLength);
  }

  /**
   * Check if the current tick lines up with the start of the provided frame number
   *
   * @param {int} number The frame number to check
   *
   * @returns {boolean}
   */
  isFrame(number) {
    return this.frameLength * number === this.currentTick;
  }

  /**
   * Get the next brightness levels for each channel
   *
   * @returns {array} First item for channel 1, second item for channel 2
   */
  getNext() {
    const channel1 = this.getChannel(1);
    const channel2 = this.getChannel(2);

    return [channel1, channel2];
  }

  getChannel(channel) {
    if (this.channels[channel].includes(this.getFrame())) {
      return 250;
    }

    return 0;
  }

  /**
   * Process a channel array to return a simple string that can be logged on the console
   * Logging the object is logging a bunch of Obervables and other random JS stuff, which makes finding the actual values a pain
   *
   * @param {[{ frame: Number, brightness: Number }]} channel
   *
   * @returns {string}
   */
  debug(channel) {
    return channel.map((frame) => {
      return `${frame.frame}, ${frame.brightness}`
    }).join('\n');
  }

  static getDisplayName() {
    return 'Need to add static getDisplayName function to class';
  }

}

module.exports = BaseAnimation;

/**
 * Initial state
 * @type {Object}
 */
const state = {
  timedAlerts: [], // Will automatically be removed
  fixedAlerts: [] // User has to remove manually
};

/**
 * Mutations for updating this piece of state
 * Must be synchronous
 *
 * @type {Object}
 */
const mutations = {

  /**
   *
   * @param state
   * @param {{ message: string, id: Number }} alert
   */
  addTimedAlert(state, alert) {
    state.timedAlerts = [
      ...state.timedAlerts,
      alert
    ];
  },
  removeTimedAlert(state, alertId) {
    state.timedAlerts = state.timedAlerts.filter(alert => alert.id !== alertId);
  }
};

/**
 * Actions for updating this piece of state
 * Can contain asynchronous actions
 *
 * @type {Object}
 */
const actions = {
  alert({ commit }, payload) {
    const id = Math.random().toString(36).substr(2, 5); // Generate some random ID. Only used to be able to remove an alert later
    const { message, type } = payload;

    if (type === 'info') {
      commit('addTimedAlert', { message, id });

      setTimeout(() => {
        commit('removeTimedAlert', id);
      }, 5000);
    }
  }
};

/**
 * Getter for this piece of state
 *
 * @type {Object}
 */
const getters = {
  alerts: state => state.timedAlerts
};

// Module exports
export default {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters
};

"use strict";

const TwinkleFlash = require('../twinkle-flash');

/**
 * First half of the Twinkle Flash animation
 *
 * CH1 |X|_|X|_|X|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|_|_|
 */
class TwinkleFlashOne extends TwinkleFlash {
  constructor() {
    super();

    this.channels = {
      1: [
        0, 2, 4
      ],
      2: [
        0, 2, 4
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '1st';
  }
}

module.exports = TwinkleFlashOne;

/**
 * This function will be called before each route navigation to ensure that the user is only moving around the site
 * using the internal navigation links, rather than attempting to jump to pages using the URL
 *
 * @param {Object}   to   Object representing the route that the user is moving to
 * @param {Object}   from Object representing the route that the user is coming from
 * @param {Function} next Function to control where the user moves to next
 *
 * @returns {null}
 */
export default (to, from, next) => {
  // If a user has typed in the URL they're going to (or refreshed the page), push them to the Dashboard
  if (!from.name && !['take-control', 'nearby-lights'].includes(to.name)) {
    next('/');
  } else {
    next();
  }
}

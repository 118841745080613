const houseParts = require('../house-parts');
const byoThemeElements = require('../byo-theme-elements');
const channels = require('../channels');
const types = require('../types');
const themeGroups = require('../theme-groups');
const themes = require('../themes');
const elementGroups = require('../element-groups');

module.exports = {
  TREE_MULTI: {
    id: "TREE_MULTI",
    name: 'Tree Party',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.TREE.id,
    byoThemeElementId: byoThemeElements.INSIDE_TREE.id,
    availableInThemes: [
      ...themeGroups.MULTI
    ],
    groups: [
      elementGroups.INSIDE,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  TREE_COOL_WHITE: {
    id: "TREE_COOL_WHITE",
    name: 'Tree Ice',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.TREE.id,
    byoThemeElementId: byoThemeElements.INSIDE_TREE.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    groups: [
      elementGroups.INSIDE,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  TREE_WARM_WHITE: {
    id: "TREE_WARM_WHITE",
    name: 'Tree Classic',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.TREE.id,
    byoThemeElementId: byoThemeElements.INSIDE_TREE.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.INSIDE,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },
}

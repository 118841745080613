/**
 * Given a number, determine the mid-point/s of all numbers leading up to that number
 *
 * Examples:
 * Given 7, the midpoint is 3, because we're accounting for an array in JS, which is 0-indexed
 * Therefore, given 6, the midpoint is 2 and 3
 *
 * @param {number} totalElements
 *
 * @return [number] || [number, number]
 */
module.exports = (totalElements) => {
  // If there are an even number of elements, then the mid point is the two that are closest to the middle
  // Obviously if there are an odd number of elements, then there is a single element that will be the mid point.
  const isEven = totalElements % 2 === 0;

  const firstMidpoint = Math.floor(totalElements / 2);

  if (!isEven) return [firstMidpoint];

  return [firstMidpoint - 1, firstMidpoint];
}
const elements = require('./elements')

const miniTreesArr = [
  elements.MINI_TREES_WW_1.id,
  elements.MINI_TREES_W_1.id,
  elements.MINI_TREES_WW_2.id,
  elements.MINI_TREES_W_2.id,
  elements.MINI_TREES_WW_3.id,
  elements.MINI_TREES_W_3.id,
  elements.MINI_TREES_WW_4.id
];

const miniTreesArrReverse = [...miniTreesArr].reverse()

const motifsArr = [
  elements.KOALA.id,
  elements.RIBBON_BELLS.id,
  elements.KANGAROO_A.id,
  elements.KANGAROO_B.id,
  elements.SNOWFLAKE_ICE_1.id,
  elements.SANTA_REINDEER.id,
  elements.HOLLY.id,
  elements.SNOWFLAKE_ICE_2.id,
  elements.TRAIN.id,
  elements.MERRY_CHRISTMAS_SIGN.id,
  elements.SANTA.id,
  elements.BLOSSOMS_A.id,
  elements.SNOWFLAKE_WARM.id,
  elements.BLOSSOMS_B.id
];

const motifsArrReverse = [...motifsArr].reverse()

module.exports = [
  {
    name: 'Bounce Mini Trees BWD',
    animationId:'bounce',
    elements: miniTreesArr.join(',')
  },
  {
    name: 'Bounce Mini Trees FWD',
    animationId:'bounce',
    elements: miniTreesArrReverse.join(',')
  },
  {
    name: 'Bounce Motifs Right',
    animationId:'bounce',
    elements: motifsArr.join(',')
  },
  {
    name: 'Bounce Motifs Left',
    animationId:'bounce',
    elements: motifsArrReverse.join(',')
  },
  {
    name: 'Double Sweep Mini Trees BWD',
    animationId:'sweepdouble',
    elements: miniTreesArr.join(',')
  },
  {
    name: 'Double Sweep Mini Trees FWD',
    animationId:'sweepdouble',
    elements: miniTreesArrReverse.join(',')
  },
  {
    name: 'Explode Mini Trees',
    animationId:'explode',
    elements: miniTreesArrReverse.join(',')
  },
  {
    name: 'Explode Motifs',
    animationId:'explode',
    elements: motifsArr.join(',')
  },
  {
    name: 'Fill Mini Trees',
    animationId:'fill',
    elements: miniTreesArr.join(',')
  },
  {
    name: 'Fill Motifs',
    animationId:'fill',
    elements: miniTreesArr.join(',')
  },
  {
    name: 'House Sweep Down',
    animationId:'sweep',
    elements: [
      elements.ROOF_OUTLINE_MULTI.id,
      elements.HOLLY.id,
      elements.ROOF_TREE_STAR_MULTI.id,
      elements.ROOF_TREE_MULTI.id,
      elements.GUTTER_LINE_MULTI.id,
      elements.SANTA.id,
      elements.FENCE_MULTI.id,
      elements.LETTERBOX_TREES_MULTI.id,
      elements.PEPPERMINT_TREES.id,

      elements.ROOF_OUTLINE_WARM.id,
      elements.HOLLY.id,
      elements.ROOF_TREE_STAR_MULTI.id,
      elements.ROOF_TREE_WARM_WHITE.id,
      elements.GUTTER_LINE_WARM.id,
      elements.SANTA.id,
      elements.FENCE_WARM_WHITE.id,
      elements.LETTERBOX_TREES_WARM_WHITE.id,
      elements.PEPPERMINT_TREES.id,

      elements.HOLLY.id,
      elements.ROOF_TREE_STAR_MULTI.id,
      elements.ROOF_TREE_WHITE.id,
      elements.GUTTER_LINE_COOL.id,
      elements.SANTA.id,
      elements.FENCE_COOL_WHITE.id,
      elements.LETTERBOX_TREES_COOL_WHITE.id,
      elements.PEPPERMINT_TREES.id
    ].join(',')
  },

  {
    name: 'House Sweep Up',
    animationId:'sweep',
    elements: [
      elements.PEPPERMINT_TREES.id,
      elements.LETTERBOX_TREES_MULTI.id,
      elements.FENCE_MULTI.id,
      elements.SANTA.id,
      elements.GUTTER_LINE_MULTI.id,
      elements.ROOF_TREE_MULTI.id,
      elements.ROOF_TREE_STAR_MULTI.id,
      elements.HOLLY.id,
      elements.ROOF_OUTLINE_MULTI.id,

      elements.PEPPERMINT_TREES.id,
      elements.LETTERBOX_TREES_WARM_WHITE.id,
      elements.FENCE_WARM_WHITE.id,
      elements.SANTA.id,
      elements.GUTTER_LINE_WARM.id,
      elements.ROOF_TREE_WARM_WHITE.id,
      elements.ROOF_TREE_STAR_MULTI.id,
      elements.HOLLY.id,
      elements.ROOF_OUTLINE_WARM.id,

      elements.PEPPERMINT_TREES.id,
      elements.LETTERBOX_TREES_COOL_WHITE.id,
      elements.FENCE_COOL_WHITE.id,
      elements.SANTA.id,
      elements.GUTTER_LINE_COOL.id,
      elements.ROOF_TREE_WHITE.id,
      elements.ROOF_TREE_STAR_MULTI.id,
      elements.HOLLY.id,
    ].join(',')
  },
  {
    name: 'Implode Mini Trees',
    animationId:'implode',
    elements: miniTreesArr.join(',')
  },
  {
    name: 'Implode Motifs',
    animationId:'implode',
    elements: motifsArr.join(',')
  },
  {
    name: 'Sweep Mini Trees BWD',
    animationId:'sweep',
    elements: miniTreesArr.join(',')
  },
  {
    name: 'Sweep Mini Trees FWD',
    animationId:'sweep',
    elements: miniTreesArrReverse.join(',')
  },
  {
    name: 'Sweep Motifs Right',
    animationId:'sweep',
    elements: motifsArr.join(',')
  },
  {
    name: 'Sweep Motifs Left',
    animationId:'sweep',
    elements: motifsArrReverse.join(',')
  },
]

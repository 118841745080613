const houseParts = require('../house-parts');
const byoThemeElements = require('../byo-theme-elements');
const themes = require('../themes');
const channels = require('../channels');
const types = require('../types');
const elementGroups = require('../element-groups');

module.exports = {
  PEPPERMINT_TREES: {
    id: "PEPPERMINT_TREES",
    name: 'Peppermint Trees',
    animation: 'OFF',
    type: types.RedWhite,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.PEPPERMINT_TREES.id,
    availableInThemes: [
      themes.ICE, themes.CHIC, themes.PARTY, themes.ODDBALL, themes.MODERN, themes.CRAZY, themes.OFF
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  }
};

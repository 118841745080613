import firebase from "firebase/app";
import 'firebase/database';
import constants from '@constants';
import router from '@router';
import Storage from "@utils/Storage";
import Analytics from "@utils/Analytics";
import { arrayUtils } from '../../utils';
import AJAX from "../../utils/AJAX";

/**
 * Initial state
 * @type {Object}
 */
const state = {
  theme: 'off', // Default theme is off, so this will ensure the selected theme in the UI is displayed to match
  isCreatingByoTheme: false,
  byoThemes: [],
  byoThemeElements: Storage.GetBYOThemeInProgress(),
  byoThemeName: '',
  byoThemeAuthorName: ''
};

/**
 * Mutations for updating this piece of state
 * Must be synchronous
 *
 * @type {Object}
 */
const mutations = {
  theme(state, payload) {
    state.theme = payload;
  },
  isCreatingByoTheme(state, isCreatingByoTheme) {
    state.isCreatingByoTheme = isCreatingByoTheme
  },
  byoThemes(state, byoThemes) {
    state.byoThemes = byoThemes;
  },
  byoThemeElements(state, elements) {
    state.byoThemeElements = elements;
  },
  byoThemeName(state, name) {
    state.byoThemeName = name;
  },
  byoThemeAuthorName(state, authorName) {
    state.byoThemeAuthorName = authorName;
  }
};

/**
 * Actions for updating this piece of state
 * Can contain asynchronous actions
 *
 * @type {Object}
 */
const actions = {
  CHANGE_THEME({ commit, getters, dispatch }, payload) {
    // User isn't in control, not allowed to change animation
    if (!getters.isInControl) {
      dispatch('alert', { message: `Unable to change theme, it appears you're not in control! Please press Take Control button again`, type: 'info' });
      router.push({ path: '/' });
      return;
    }

    const { theme } = payload;
    commit('theme', theme);

    Analytics.FireFeatureUsed('change-theme', {
      key: getters.clientKey,
      theme
    });


    firebase.database().ref('/').update({
      theme,
      updatingKey: getters.clientKey
    });
  },

  SET_IS_CREATING_BYO_THEME({ commit, getters, dispatch }, payload) {
    const { isCreating } = payload;
    commit('isCreatingByoTheme', isCreating);
    Analytics.FireFeatureUsed('byo-theme', {
      key: getters.clientKey,
      action: 'start'
    });
    dispatch('CHANGE_ANIMATION', { animation: "off", elements: 'ALL', key: getters.clientKey });
  },

  /**
   * This function was originally created so that it could prepend "byo-" to the theme name, when setting it as the active
   * theme. I have since moved this functionally to the SAVE_BYO_THEME action, also in this file, because it's being set
   * as the ID for the theme, so it needs to be stored in Firebase.
   * That means this action isn't really necessary anymore, but I'm going to keep it anyway, just because it does make
   * the code a little more verbose, and hopefully easy to understand..
   *
   * @param {Object} module
   * @param {{
   *  theme: {
   *    name: string,
   *    authorName: string
   *  }
   * }}              payload
   *
   * @returns {null}
   */
  SET_BYO_THEME({ dispatch }, payload) {
    const { theme } = payload;
    dispatch('CHANGE_THEME', { theme });
  },

  /**
   * Change the colour of part of the house
   *
   * E.g. The roof is a part, and there are 3 strings on the roof (multi, warm white and cool white). This means the
   * roof could have any of the themes applied to it, where only one (or a combination of) those strings are turned on
   *
   * @param {object} module
   * @param {{ theme: string }} payload Payload containing the theme that is selected
   * @constructor
   */
  CHANGE_HOUSE_PART_THEME({ getters, dispatch }, payload) {
    if (!getters.isInControl) {
      dispatch('alert', { message: `Unable to change house part, it appears you're not in control! Please press Take Control button again`, type: 'info' });
      router.push({ path: '/' });
      return;
    }

    const { theme } = payload;
    const selectedPart = getters.selectedHousePart;

    Analytics.FireFeatureUsed('change-house-part', {
      key: getters.clientKey,
      selectedPart
    });

    const elementsToTurnOn = [];
    const elementsToTurnOff = [];
    Object.values(constants.elements)
      .filter(el => el.housePart === selectedPart)
      .forEach((el) => {
        const themeName = theme.toLowerCase();
        if (themeName === 'off') {
          elementsToTurnOff.push(el.id)
        } else if (themeName === 'on') {
          elementsToTurnOn.push(el.id)
        } else {
          const arrayToUpdate = (el.availableInThemes.includes(themeName)) ? elementsToTurnOn : elementsToTurnOff;
          arrayToUpdate.push(el.id);
        }
      });

    AJAX.post('changeAnimation', {
      control: constants.controlNames.CONTROL__COLOURS,
      elements: elementsToTurnOff.join(','),
      animation: 'off',
      key: getters.clientKey
    });
    AJAX.post('changeAnimation', {
      control: constants.controlNames.CONTROL__COLOURS,
      elements: elementsToTurnOn.join(','),
      animation: 'steady_on',
      key: getters.clientKey
    }).catch(() => {
      this.$store.dispatch('alert', { message: `There was an issue sending the command, it appears you're no longer in control!`, type: 'info' });
      Analytics.FireFeatureUsed('exception-change-house-part', 'not-in-control');
      dispatch('REVOKE_CONTROL')
    });
  },

  /**
   * Add/remove element to array of elements that are enabled for this custom, BYO theme
   *
   * @param {Object} module
   * @param {Object} payload
   * @param {String} payload.theme The selected theme for a house part. Will be used to cross section elements to be added to byoThemeElements
   *
   * @returns {null}
   */
  TOGGLE_ELEMENT_IN_BYO_THEME({ commit, getters, dispatch }, payload) {
    const { theme } = payload;
    const selectByoThemeElement = getters.selectedByoThemeElement;

    const housePartElementsToAdd = [];
    const housePartElementsToRemove = [];
    Object.values(constants.elements)
      .filter(el => el.byoThemeElementId === selectByoThemeElement)
      .forEach((el) => {
        const themeName = theme.toLowerCase();
        if (themeName === 'off') {
          housePartElementsToRemove.push(el.id)
        } else if (themeName === 'on') {
          housePartElementsToAdd.push(el.id)
        } else {
          const arrayToUpdate = (el.availableInThemes.includes(themeName)) ? housePartElementsToAdd : housePartElementsToRemove;
          arrayToUpdate.push(el.id);
        }
      });

    // Prepare new byoThemeElements array by combining existing elements with the new elements to add
    const newByoElements = [
      // Remove elements that no longer match the selected theme (or because the house part has been turned off)
      ...state.byoThemeElements.filter(element => !housePartElementsToRemove.includes(element)),
      ...housePartElementsToAdd
    ];

    const prevByoThemeElements = [...state.byoThemeElements];

    let byoThemeElements = new Set();
    newByoElements.forEach(element => byoThemeElements.add(element)); // Make sure there is only 1 instance of each element in the array

    const nextByoThemeElements = Array.from(byoThemeElements);
    commit('byoThemeElements', nextByoThemeElements);
    Storage.SaveBYOThemeInProgress(nextByoThemeElements);
    // @TODO Save a copy of nextByoThemeElements to local storage. This will allow a user to lose control, refresh, but still pick up from where they left off

    // If the theme has changed, push an updated to firebase
    if (getters.isInControl && !arrayUtils.isEqual(prevByoThemeElements, nextByoThemeElements)) {
      dispatch('PUSH_BYO_THEME_TO_FIREBASE');
    }
  },

  PUSH_BYO_THEME_TO_FIREBASE({ getters, dispatch }) {
    const byoThemeElements = getters.byoThemeElements;
    AJAX.post('changeAnimation', {
      elements: byoThemeElements.join(','),
      animation: 'steady_on',
      key: getters.clientKey,
      control: constants.controlNames.CONTROL__BYO_THEME
    }).catch(() => {
      dispatch('alert', {
        message: `Unable to reflect your theme changes on the house as you're no longer in control! Please press Take Control again, and you can come right back to keep building your theme`,
        type: 'info'
      });
      dispatch('REVOKE_CONTROL');
    });
  },

  /**
   * Clear all elements from the BYO Theme array. This means the user has clicked on the "Start" button, and are creating
   * a new theme
   *
   * @param dispatch
   */
  CLEAR_BYO_THEME_ELEMENTS({ commit }) {
    commit('byoThemeElements', []);
  },

  /**
   * Handle saving the custom theme that the user has built using BYO Theme
   *
   * @param {Object} module
   * @param {{ themeName: string }} payload
   *
   * @returns {null}
   */
  SAVE_BYO_THEME({ getters }, payload) {
    const { themeName, authorName } = payload;
    const themeId = `byo-${themeName.replace(/ /g, '_')}`;
    // The following is for updating themes, not for saving new themes
    const authoredThemes = Storage.GetAuthoredBYOThemes();
    Storage.ClearBYOThemeInProgress();

    Analytics.FireFeatureUsed('byo-theme', {
      key: getters.clientKey,
      action: 'save'
    });
    return AJAX.post('saveBYOTheme', {
      id: themeId,
      name: themeName,
      authorName,
      elements: getters.byoThemeElements,
      isAuthor: authoredThemes.includes(themeId)
    });
    // .catch is handled in place where SAVE_BYO_THEME is triggered
  },

  POPULATE_BYO_THEMES({ commit }, payload) {
    const { themes } = payload;
    commit('byoThemes', themes);
  },

  SET_BYO_THEME_NAME({ commit }, payload) {
    const { name } = payload;
    commit('byoThemeName', name);
  },

  SET_BYO_THEME_AUTHOR_NAME({ commit }, payload) {
    const { authorName } = payload;
    commit('byoThemeAuthorName', authorName);
  }
};

/**
 * Getter for this piece of state
 *
 * @type {Object}
 */
const getters = {
  selectedTheme: state => state.theme,
  isCreatingByoTheme: state => state.isCreatingByoTheme,
  byoThemes: state => state.byoThemes,
  byoThemeElements: state => state.byoThemeElements,
  byoThemeName: state => state.byoThemeName,
  byoThemeAuthorName: state => state.byoThemeAuthorName
};

// Module exports
export default {
  state,
  mutations,
  actions,
  getters
};

"use strict";

const BaseAnimation = require('./BaseAnimation');

/**
 * Battle Flash animation
 * This is the chasing-flash animation from the Myer Vue lights
 *
 * Back and forth between channel 1 and 2 (bit longer than a flash)
 * repeat 3 times
 *
 * Channel 1 flash 3 times
 * Channel 2 flash 3 times
 *
 * CH1 |X|X|_|_|_|_|_|_|X|X|_|_|_|_|_|_|X|X|_|_|_|_|_|X|_|X|_|X|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|X|X|_|_|_|_|_|_|X|X|_|_|_|_|_|_|X|X|_|_|_|_|_|_|_|X|_|X|_|X|_|_|_|
 */
class BattleFlash extends BaseAnimation {
  constructor() {
    super();

    this.id = 'battle_flash';
    this.frameLength = 10; // Length in "ticks"
    this.totalFrames = 37;
    this.setMaxTicks();

    this.channels = {
      1: [
        0, 1, 8, 9, 16, 17, 23, 25, 27
      ],
      2: [
        4, 5, 12, 13, 20, 21, 29, 31, 33
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Battle Flash';
  }
}

module.exports = BattleFlash;

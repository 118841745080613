const { byElement: elementsByPort } = require('./element-ports');
const elements = require('./elements');
const {CONST__CH_1, CONST__CH_2} = require('./channels');

function elementPort(elementId, channel) {
  if (typeof elementsByPort[elementId] === 'undefined') {
    console.log({elementId});
    process.exit();
  }
  return elementsByPort[elementId][channel];
}

// ? The midi channels that are indented slightly are for the black keys
// ? These keys just get assigned the light that is associated with the white key directly next to it
// ? That's why some of the element are listed 4 times, and some only 3, because there might be only 1 black key either side of the elements' white keys

module.exports = [
  { midi: 36, port: elementPort(elements.GUTTER_LINE_COOL.id, CONST__CH_1), on: false },
    { midi: 37, port: elementPort(elements.GUTTER_LINE_COOL.id, CONST__CH_1), on: false },
  { midi: 38, port: elementPort(elements.GUTTER_LINE_WARM.id, CONST__CH_2), on: false },
    { midi: 39, port: elementPort(elements.KANGAROO_A.id, CONST__CH_2), on: false },
  { midi: 40, port: elementPort(elements.GUTTER_LINE_MULTI.id, CONST__CH_1), on: false },
  { midi: 41, port: elementPort(elements.GUTTER_LINE_MULTI.id, CONST__CH_2), on: false },
    { midi: 42, port: elementPort(elements.KANGAROO_A.id, CONST__CH_1), on: false },
  { midi: 43, port: elementPort(elements.TREE_COOL_WHITE.id, CONST__CH_1), on: false },
    { midi: 44, port: elementPort(elements.TREE_COOL_WHITE.id, CONST__CH_1), on: false },
  { midi: 45, port: elementPort(elements.TREE_COOL_WHITE.id, CONST__CH_2), on: false },
    { midi: 46, port: elementPort(elements.TREE_COOL_WHITE.id, CONST__CH_2), on: false },
  { midi: 47, port: elementPort(elements.TREE_MULTI.id, CONST__CH_1), on: false },
  { midi: 48, port: elementPort(elements.TREE_MULTI.id, CONST__CH_2), on: false },
    { midi: 49, port: elementPort(elements.TREE_MULTI.id, CONST__CH_1), on: false },
  { midi: 50, port: elementPort(elements.TREE_WARM_WHITE.id, CONST__CH_1), on: false },
    { midi: 51, port: elementPort(elements.TREE_WARM_WHITE.id, CONST__CH_1), on: false },
  { midi: 52, port: elementPort(elements.SANTA.id, CONST__CH_2), on: false },
  { midi: 53, port: elementPort(elements.SNOWFLAKE_ICE_1.id, CONST__CH_1), on: false },
    { midi: 54, port: elementPort(elements.SNOWFLAKE_ICE_2.id, CONST__CH_2), on: false },
  { midi: 55, port: elementPort(elements.MERRY_CHRISTMAS_SIGN.id, CONST__CH_2), on: false },
    { midi: 56, port: elementPort(elements.DOE_A.id, CONST__CH_1), on: false },
  { midi: 57, port: elementPort(elements.DOE_B.id, CONST__CH_2), on: false },
    { midi: 58, port: elementPort(elements.BUCK.id, CONST__CH_2), on: false },
  { midi: 59, port: elementPort(elements.HOLLY.id, CONST__CH_1), on: false },
  { midi: 60, port: elementPort(elements.HOLLY.id, CONST__CH_2), on: false },
    { midi: 61, port: elementPort(elements.HOLLY.id, CONST__CH_2), on: false },
  { midi: 62, port: elementPort(elements.BELLS.id, CONST__CH_1), on: false },
    { midi: 63, port: elementPort(elements.BELLS.id, CONST__CH_1), on: false },
  { midi: 64, port: elementPort(elements.BELLS.id, CONST__CH_2), on: false },
  { midi: 65, port: elementPort(elements.ROOF_OUTLINE_MULTI.id, CONST__CH_1), on: false },
    { midi: 66, port: elementPort(elements.ROOF_OUTLINE_MULTI.id, CONST__CH_1), on: false },
  { midi: 67, port: elementPort(elements.ROOF_OUTLINE_WARM.id, CONST__CH_2), on: false },
    { midi: 68, port: elementPort(elements.ROOF_OUTLINE_WARM.id, CONST__CH_2), on: false },
  { midi: 69, port: elementPort(elements.FENCE_MULTI.id, CONST__CH_1), on: false },
    { midi: 70, port: elementPort(elements.FENCE_MULTI.id, CONST__CH_1), on: false },
  { midi: 71, port: elementPort(elements.FENCE_MULTI.id, CONST__CH_2), on: false },
  { midi: 72, port: elementPort(elements.FENCE_COOL_WHITE.id, CONST__CH_1), on: false },
    { midi: 73, port: elementPort(elements.FENCE_COOL_WHITE.id, CONST__CH_1), on: false },
  { midi: 74, port: elementPort(elements.FENCE_COOL_WHITE.id, CONST__CH_2), on: false },
    { midi: 75, port: elementPort(elements.FENCE_COOL_WHITE.id, CONST__CH_2), on: false },
  { midi: 76, port: elementPort(elements.FENCE_WARM_WHITE.id, CONST__CH_1), on: false },
  { midi: 77, port: elementPort(elements.FENCE_WARM_WHITE.id, CONST__CH_2), on: false },
    { midi: 78, port: elementPort(elements.FENCE_WARM_WHITE.id, CONST__CH_2), on: false },
  { midi: 79, port: elementPort(elements.ROOF_TREE_WHITE.id, CONST__CH_1), on: false },
    { midi: 80, port: elementPort(elements.ROOF_TREE_WHITE.id, CONST__CH_2), on: false },
  { midi: 81, port: elementPort(elements.ROOF_TREE_WARM_WHITE.id, CONST__CH_2), on: false },
    { midi: 82, port: elementPort(elements.ROOF_TREE_WHITE.id, CONST__CH_2), on: false },
  { midi: 83, port: elementPort(elements.ROOF_TREE_MULTI.id, CONST__CH_1), on: false },
  { midi: 84, port: elementPort(elements.ROOF_TREE_MULTI.id, CONST__CH_2), on: false },
    { midi: 85, port: elementPort(elements.ROOF_TREE_WARM_WHITE.id, CONST__CH_2), on: false },
  { midi: 86, port: elementPort(elements.ROOF_TREE_WARM_WHITE.id, CONST__CH_2), on: false },
    { midi: 87, port: elementPort(elements.ROOF_TREE_MULTI.id, CONST__CH_1), on: false },
  { midi: 88, port: elementPort(elements.ROOF_TREE_MULTI.id, CONST__CH_1), on: false },
  { midi: 89, port: elementPort(elements.PEPPERMINT_TREES.id, CONST__CH_1), on: false },
    { midi: 960, port: elementPort(elements.PEPPERMINT_TREES.id, CONST__CH_1), on: false },
  { midi: 91, port: elementPort(elements.PEPPERMINT_TREES.id, CONST__CH_2), on: false },
    { midi: 92, port: elementPort(elements.PEPPERMINT_TREES.id, CONST__CH_2), on: false },
  { midi: 93, port: elementPort(elements.PEPPERMINT_TREES.id, CONST__CH_1), on: false },
    { midi: 94, port: elementPort(elements.PEPPERMINT_TREES.id, CONST__CH_1), on: false },
  { midi: 95, port: elementPort(elements.PEPPERMINT_TREES.id, CONST__CH_1), on: false },
  { midi: 96, port: elementPort(elements.TRAIN.id, CONST__CH_2), on: false }
];

<template>
  <div class="light"></div>
</template>

<script>
  export default {
    name: 'Light'
  };
</script>

<style lang="scss">
  :root {
    --light-size: 15px;
  }

  .light {
    width: var(--light-size);
    height: var(--light-size);
    border-radius: 50%;
    border: 1px solid transparent;
    transition: background-color 50ms linear;
    opacity: var(--denton);

    &:not(.light--on) {
      background-color: transparent;
      transition: background-color 50ms linear;
    }
  }
</style>

/* global gtag */

export default class Analytics {

    /**
     * Track specific features that are used within the app
     *
     * @param {string} feature The name of the feature that was used. Title case, with spaces
     * @param {string|Boolean} value   The value the feature is set to
     *
     * @returns {null}
     */
    static FireFeatureUsed(feature, value) {
        Analytics.FireEvent(value, {
            'event_category': 'features',
            'event_label': feature
        });
    }

    /**
     * Fire an event on Google Analytics
     *
     * @param {string|Boolean} eventAction  The 'action' of the event to creation
     * @param {Object} eventOptions Extra options to send with the event action
     *
     * @returns {null}
     */
    static FireEvent(eventAction, eventOptions) {
        if (window.location.hostname === 'localhost') {
            console.log(eventAction, eventOptions);
            return;
        }

        gtag('event', eventAction, eventOptions);
    }
}

import constants from '@constants'

export default {
  name: "ThemesControl",
  data() {
    return {
      tab: null,
      newThemes: []
    };
  },
  computed: {
    themes() {
      return constants.themes;
    }
  }
}

import firebase from "firebase/app";
import 'firebase/database';
import store from '@store';
import Storage from "../utils/Storage";
import { notifyControl } from '@utils';
import appConfig from '../../application-configuration';

function handleUserNotInControl() {
  Storage.removeItem(Storage.CONST__QUEUE_KEY);
  store.dispatch('NOT_IN_QUEUE');
  store.dispatch('REVOKE_CONTROL');
}

export const listenClientKey = (clientKey) => {
  const ref = firebase.database().ref(`/queue/entries/${clientKey}/position`);
  ref.on('value', (snapshot) => {
    const position = snapshot.val();

    // Likely the user has finished being in control, so they've now been removed from the queue, but localStorage hadn't been cleaned up
    // For some reason, this will come in at `null`, but if we reload the app with a bad client key in localStorage,
    // and try to use that to query the DB, then we'll end up in the error function below..
    if (position === null) {
      handleUserNotInControl();
      return;
    }

    // User is in control
    if (position === 0) {
      store.dispatch('GRANT_CONTROL');
      notifyControl();
      return;
    }

    store.dispatch('IS_IN_QUEUE', { position });
  }, () => {
    // This is an error function, but I'm just going to ignore the error and assume it's because the user's key isn't in the queue
    handleUserNotInControl();
    ref.off('value');
  });
};

export const listenBYOThemes = () => {
  firebase.database().ref('/byoThemes/2023').on('value', (snapshot) => {
    const themesVal = snapshot.val();
    if (!themesVal) {
      // I don't expect this to ever actually be a thing, as I haven't yet built a way for people to remove their themes..
      store.dispatch('POPULATE_BYO_THEMES', { themes: [] });
      return;
    }

    const themesArray = Object.values(themesVal);

    if (themesArray) {
      const themes = themesArray
        .map((theme) => {
          const { id, name, authorName } = theme;
          return {
            id,
            name,
            authorName
          }
        })
        .sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
      store.dispatch('POPULATE_BYO_THEMES', { themes });
    }
  })
};

export const listenTimer = () => {
  firebase.database().ref('/timer').on('value', (snapshot) => {
    if (!store.getters.isInControl) {
      // If the current user is only in the queue, then there is no point in trying to display a countdown timer for them
      return;
    }

    const { tracking, remaining } = snapshot.val();

    if (!tracking) {
      // This will be called when tracking stops. This whole callback won't be called again until tracking is turned back
      // on, so there is no point in checking that the application hasn't already be set to false
      store.dispatch('SET_IS_TRACKING_TIMER', { tracking });
      // Not going to bother resetting the remaining time to anything, we can just wait until time is being tracked again
      return;
    }

    const isAppTracking = store.getters.isTrackingTimer;
    if (tracking && !isAppTracking) {
      // This will ensure we only dispatch SET_IS_TRACKING_TIMER once while the countdown is happening
      store.dispatch('SET_IS_TRACKING_TIMER', { tracking });
    }

    store.dispatch('SET_REMAINING_TIME', { remaining });
    if (remaining === 0) {
      store.dispatch('REVOKE_CONTROL');
    }
  });
}

/**
 * Read the status value to see if the lights are on
 * The lights will be marked on when the runner.js CLI script is run on the RPi. When the script is stopped, the lights
 * are assumed to be off
 */
export const listenLightsStatus = () => {
  firebase.database().ref('/configuration/status').on('value', (snapshot) => {
    const status = snapshot.val();

    store.dispatch('SET_LIGHTS_STATUS', { status })
  })
}

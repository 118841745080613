"use strict";
const EventEmitter = require('events');
const eventEmitter = new EventEmitter ();

const GroupAnimation = require('./GroupAnimation');

/**
 * Empty animation
 *
 * This animation will start with the element on, then it will fade out to 0%, then it will remain at 0%
 *
 * ? Is this just the same animation as unfill?
 *
 * Visualisation
 * The F below indicates the Fade cycle of the element
 * The X indicates that the light is just on at 100%
 * The _ indicates that the light is at 0%
 * Frames ->
 * E1 |X|F|_|_|_|_|_|_|_
 * E2 |X|X|F|_|_|_|_|_|_
 * E3 |X|X|X|F|_|_|_|_|_
 * E4 |X|X|X|X|F|_|_|_|_
 * E5 |X|X|X|X|X|F|_|_|_
 * E6 |X|X|X|X|X|X|F|_|_
 * E* |X|X|X|X|X|X|X|F|_
 *
 */
class Empty extends GroupAnimation {
  static id = 'empty';

  constructor(...args) {
    super(...args);

    this.frameLength = 5;
    this.fadeLength = 4; // Number of frames
    const fadeOutLength = this.fadeLength / 2;
    // How many frames it will take for all elements to fade out one-by-one and all be at 0%
    const framesTilAll0 = this.totalElements + this.fadeLength; // Total elements plus how long it will take for the final element to fade out
    this.totalFrames = framesTilAll0 + fadeOutLength;
    this.setMaxTicks();
    this.fadeDelta = 250 / this.fadeLength;
    this.fadeOutDelta = 250 / fadeOutLength; // Fade out is going to be four times as fast as fade in

    const channelOn = [];
    let animationFrameCounter = 0;
    this.startingFrame = 0;

    for ( ; animationFrameCounter < this.sequencePosition ; animationFrameCounter++) {
      channelOn.push({
        frame: animationFrameCounter,
        brightness: 250
      });
    }

    for (let frameFadeOut = 0 ; animationFrameCounter <= this.sequencePosition + this.fadeLength ; frameFadeOut++) {
      channelOn.push({
        frame: animationFrameCounter,
        brightness: 250 - ( frameFadeOut * this.fadeDelta )
      });
      animationFrameCounter++;
    }

    for ( ; animationFrameCounter < framesTilAll0 ; animationFrameCounter++) {
      channelOn.push({
        frame: animationFrameCounter,
        brightness: 0
      });
    }

    this.channels = {
      1: channelOn,
      2: channelOn
    };

  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Empty';
  }
}

module.exports = Empty;

"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * Alternate pulses animation
 *
 * Pulse one channel
 * Pulse the other channel
 * Repeat
 */
class AlternatePulsing extends FadeBaseAnimation {

  constructor() {
    super();

    this.id = 'alternate_pulses';

    this.frameLength = 3; // Length in "ticks"
    const fadeLength = 30; // Length in ticks. ~ 300ms
    this.fadeIncrement = 250 / fadeLength; // +1 ensures brightness gets to 100%. This will happen for multiple frames, but that's alright

    const ch1FadeUpStart = 0;
    const ch1FadeDownStart = ch1FadeUpStart + fadeLength;

    const ch2FadeUpStart = 60;
    const ch2FadeDownStart = ch2FadeUpStart + fadeLength;

    this.totalFrames = ch2FadeDownStart + fadeLength;
    this.setMaxTicks();

    const channelOneOn = [];
    for (let ch1FrameFadeUp = ch1FadeUpStart ; ch1FrameFadeUp < ch1FadeUpStart + fadeLength ; ch1FrameFadeUp++) {
      channelOneOn.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeUp)
      });
    }

    for (let ch1FrameFadeDown = ch1FadeDownStart, ch1FrameFadeDownMultiplier = 29 ; ch1FrameFadeDown < ch1FadeDownStart + fadeLength ; ch1FrameFadeDown++, ch1FrameFadeDownMultiplier--) {
      channelOneOn.push({
        frame: ch1FrameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeDownMultiplier)
      });
    }

    const channelTwoOn = [];
    for (let ch2FrameFadeUp = ch2FadeUpStart, ch2FadeUpMultiplier = 0 ; ch2FrameFadeUp < ch2FadeUpStart + fadeLength ; ch2FrameFadeUp++, ch2FadeUpMultiplier++) {
      channelTwoOn.push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FadeUpMultiplier)
      });
    }

    for (let ch2FrameFadeDown = ch2FadeDownStart, ch2FrameFadeDownMultiplier = 29 ; ch2FrameFadeDown < ch2FadeDownStart + fadeLength ; ch2FrameFadeDown++, ch2FrameFadeDownMultiplier--) {
      channelTwoOn.push({
        frame: ch2FrameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * ch2FrameFadeDownMultiplier)
      });
    }

    this.channels = {
      1: channelOneOn,
      2: channelTwoOn
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Alternate Pulses';
  }
}

module.exports = AlternatePulsing;

const determineMidpoint = require('./determine-midpoint');

module.exports = (totalElements, sequencePosition) => {
  const midPoints = determineMidpoint(totalElements);

  // If this instance is the midpoint, then there is no offset
  if (midPoints.includes(sequencePosition)) return 0;

  if (midPoints.length === 1 || midPoints[0] > sequencePosition) return Math.abs(sequencePosition - midPoints[0]);

  // Otherwise this sequence position is after the midpoints
  return Math.abs(sequencePosition - midPoints[1]);
}
import * as Strings from '../../components/Strings';
import { elements, elementPorts, advancedAnimations } from '@constants';
import Animator from '../../../packages/server/animator';
import appConfig from '../../../application-configuration';

/**
 * A component that is intended to act like the Raspberry Pi, except that it will only listen to Firebase for updates,
 * and won't implement the timeout feature.
 *
 * This means the component can be used to "watch" what is happening on the house, without having to be watching the house
 * The RPi still controls the timeout, and users still control the animations that will get played.
 */
export default {
  name: "House",
  components: {
    ...Strings
  },
  data: function () {
    return {
      intervalId: null,
      dmxData: [],
      mode: ''
    };
  },
  mounted() {
    document.title = 'House - Luke Lights'
    this.createSocketConnection();
    this.animator = new Animator({ isViewingOnly: true });
  },
  computed: {

    /**
     * @typedef {Object} LightElementObject
     * @property {string} animation The animation that is playing on the element
     * @property {BrightnessObject} brightness
     * @property {string} id   The ID of the element. E.g. "MULTI"
     * @property {string} name The name of the element. E.g. "Roof Multi String"
     * @property {string} type The type of element. E.g. "Multi". These correspond to String components in `src/components/Strings`
     */

    /**
     * @typedef {Object} BrightnessObject
     * @property {number} 1 - The brightness value for the first channel
     * @property {number} 2 - The brightness value for the second channel
     */

    /**
     * Using the elements provided in `../../constants/elements` and the `dmxData` that is built up as a result of calling
     * `this.animator.getNextValues`, build an object that contains all elements, and the appropriate brightness levels
     * for their 2 respective channels
     *
     * @returns {LightElementObject[]}
     */
    lightElements() {
      const els = {};
      Object.values(elements).forEach((element) => {
        const elementsDMXData = this.dmxData.filter(data => data.elementId === element.id);

        // if (elementsDMXData.length > 2) {
          // I am removing this check because there are elements that will appear to have more than 2 channels, because
          // they're spread out across controllers, so there could be 4 or potentially 6 channels that need to be set
          // console.error(`Something has gone wrong, found more than 2 channels for ${element.id}`);
        // }

        if (!els[element.id]) {
          els[element.id] = {
            ...element
          };
        }

        if (elementsDMXData[0]) {
          els[element.id].brightness[elementsDMXData[0].channel] = elementsDMXData[0].brightness;
        }
        if (elementsDMXData[1]) {
          els[element.id].brightness[elementsDMXData[1].channel] = elementsDMXData[1].brightness;
        }
      });
      // els has been an object up until this point to make updating the values easier
      return Object.values(els);
    }
  },

  methods: {

    /**
     * Alert the server that the UI is available to display the animation values
     */
    createSocketConnection() {
      const ws = new WebSocket('ws://localhost:9898/');

      ws.onerror = function() {
        console.warn('Websocket server not available, connection isn\'t made');
      }

      ws.onopen = function() {
        ws.send('Client Connected');
      };

      ws.onmessage = (e) => {
        const nextValues = JSON.parse(e.data);
        this.updateDMXData(nextValues);
      };

      ws.onclose = () => {
        this.connection = null;
      }
    },

    startAnimation() {
      this.animator.setIsSlave(true);
      // The following is temporary until I build a UI that will allow me to change the animation on the fly,
      // without having to go through Firebase
      // To change the advanced animation that is playing, change the name that is used in the find function below
      this.animator.setAnimation(Object.keys(elements), 'off');
      const advancedAnimation = advancedAnimations.find(animation => animation.name === 'Bounce Mini Trees FWD');
      this.animator.setAnimation(advancedAnimation.elements.split(','), advancedAnimation.animationId);

      this.intervalId = setInterval(() => {
        this.animator.tick();
        const nextValues = this.animator.getNextValues();
        this.updateDMXData(nextValues);
      }, appConfig.tickLength);
    },

    stopAnimation() {
      clearInterval(this.intervalId);
    },

    /**
     * Convert the port values that would otherwise be sent to the DMX controller, back to a format that will allow the brightness
     * to be mapped to element ID's.
     *
     * @param {{ channelId: brightness }} portValues Object where the key is a Port ID and value is the brightness for that output port
     *        This object will have a varying number of keys, depending on how many output ports have been configured
     *
     * @returns {null}
     */
    updateDMXData: function (portValues) {
      // this.dmxData is then used to create the computed lightElements property above
      this.dmxData = Object.entries(portValues).map(([port, brightness]) => {
        return {
          ...elementPorts.byPort[port],
          brightness
        }
      });
    },

    /**
     * Update the mode set in the Animator, but also the mode that will be displayed on the House dashboard
     *
     * @param {Boolean} isSlave Flag indicating if the system is in slave mode
     *
     * @returns {null}
     */
    updateMode(isSlave) {
      this.animator.setIsSlave(isSlave);
      this.mode = this.animator.getCurrentMode();
    }
  }
}

"use strict";

const Sequential = require('../sequential');

/**
 * First half of the Sequential animation
 *
 * CH1 |X|X|X|X|X|X|X|X|X|X|_|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 */
class SequentialOne extends Sequential {
  constructor() {
    super();

    this.channels = {
      1: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9
      ],
      2: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '1st';
  }
}

module.exports = SequentialOne;

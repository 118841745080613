"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * Alternate Decay Flash animation
 *
 * Decay flash one channel
 * Decay flash the other channel
 * Repeat
 */
class AlternateDecayFlash extends FadeBaseAnimation {
  constructor(channel = false) {
    super();

    this.id = 'alternate_decay_flash';
    this.frameLength = 10; // Length in "ticks"
    this.totalFrames = 50;
    this.setMaxTicks();
    this.fadeLength = this.totalFrames / 2;
    this.fadeIncrement = 250 / this.fadeLength;

    const channelOneFadeStart = 0;
    const channelTwoFadeStart = this.totalFrames / 2;
    const fadeDownMultiplier = (this.fadeLength - 1);

    const channelOneOn = [{ frame: channelOneFadeStart, brightness: 250 }];
    const channelTwoOn = [{ frame: channelTwoFadeStart, brightness: 250 }];

    for (
      let frameFadeDown = (channelOneFadeStart + 1), frameFadeDownMultiplier = fadeDownMultiplier
      ; frameFadeDown <= channelTwoFadeStart
      ; frameFadeDown++, frameFadeDownMultiplier--
    ) {
      channelOneOn.push({
        frame: frameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * frameFadeDownMultiplier)
      });
    }

    for (
      let frameFadeDown = (channelTwoFadeStart + 1), frameFadeDownMultiplier = fadeDownMultiplier
      ; frameFadeDown <= channelTwoFadeStart + this.fadeLength
      ; frameFadeDown++, frameFadeDownMultiplier--
    ) {
      channelTwoOn.push({
        frame: frameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * frameFadeDownMultiplier)
      });
    }

    this.channels = {
      1: channelOneOn,
      2: channelTwoOn
    };

  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Alternate Decay Flash';
  }
}

module.exports = AlternateDecayFlash;

"use strict";

const FadeBaseAnimation = require('../animations/FadeBaseAnimation');

/**
 * GroupAnimation
 *
 * Animations that extend this are still applied to a single element, however with the added contextual information or
 * sequencePosition and totalElements, the group animations will be able to determine when each element will start
 * to light up relative to the other elements
 */
class GroupAnimation extends FadeBaseAnimation {

  /**
   * @param args
   * @param args.sequencePosition
   * @param args.totalElements
   */
  constructor({ sequencePosition = 0, totalElements = 1 } = {}) {
    super();

    this.sequencePosition = sequencePosition;
    this.totalElements = totalElements;
    this.animationDelay = 1; // The amount of delay between elements' animation's starting. The lower the number, the faster the animation appears
    this.startingFrame = this.sequencePosition * this.animationDelay;

  }
}

module.exports = GroupAnimation;

import firebase from "firebase/app";
import 'firebase/database';
import store from '@store';

export default function command(name, payload) {
  firebase.database().ref('/').update({
    command: JSON.stringify({ name, payload }),
    updatingKey: store.getters.clientKey
  });
}

const Bounce = require('./bounce');
const Empty = require('./empty');
const Explode = require('./explode');
const Fill = require('./fill');
const Implode = require('./implode');
const SimonSays = require('./simon-says');
const SweepDouble = require('./sweep-double');
const Sweep = require('./sweep');

module.exports = {
  Bounce,
  Empty,
  Explode,
  Fill,
  Implode,
  SimonSays,
  SweepDouble,
  Sweep
}

"use strict";

const GroupAnimation = require('./GroupAnimation');

/**
 * SweepDouble animation
 *
 * See docblock in sweep.js for explanation of how the group animations work
 */
class SweepDouble extends GroupAnimation {
  static id = 'sweepdouble';
  constructor(...args) {
    super(...args);

    this.frameLength = 10;
    this.fadeLength = 10; // Number of frames
    this.totalFrames = (this.frameLength * 2) + this.totalElements;
    this.setMaxTicks();
    this.fadeDelta = 250 / this.fadeLength;
    // @TODO Because this animation uses this.totalElements in the frame calculations below, some refactoring is likely required to get it to work with this.startingFrame

    const channelOn = [{ frame: this.sequencePosition, brightness: 250 }];

    for (let frameFadeDown = (this.sequencePosition + 1), frameFadeDownMultiplier = this.fadeLength ; frameFadeDown < (this.sequencePosition + this.fadeLength) ; frameFadeDown++, frameFadeDownMultiplier--) {
      channelOn.push({
        frame: frameFadeDown,
        brightness: Math.ceil(this.fadeDelta * frameFadeDownMultiplier)
      });
    }

    for (let frameFadeDown = (this.sequencePosition + this.totalElements + 1), frameFadeDownMultiplier = this.fadeLength ; frameFadeDown < (this.sequencePosition + this.totalElements + this.fadeLength) ; frameFadeDown++, frameFadeDownMultiplier--) {
      channelOn.push({
        frame: frameFadeDown,
        brightness: Math.ceil(this.fadeDelta * frameFadeDownMultiplier)
      });
    }

    this.channels = {
      1: channelOn,
      2: channelOn
    };

  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Sweep Double';
  }
}

module.exports = SweepDouble;

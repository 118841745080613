const houseParts = require('../house-parts');
const channels = require('../channels');
const types = require('../types');
const themeGroups = require('../theme-groups');
const themes = require('../themes')
const elementGroups = require('../element-groups');
const byoThemeElements = require('../byo-theme-elements');

// const icicles = require('./icicles');
const fence = require('./fence');
const letterboxTrees = require('./letterbox-trees');
const peppermintTree = require('./peppermint-tree');
const miniTrees = require('./mini-trees');
const motifs = require('./motifs');
const roof = require('./roof')
const ropes = require('./rope-lights')
const tree = require('./tree');

module.exports = {
  // ...icicles,
  ...fence,
  ...letterboxTrees,
  ...peppermintTree,
  ...miniTrees,
  ...motifs,
  ...roof,
  ...ropes,
  ...tree,

  BLOSSOMS_A: {
    id: "BLOSSOMS_A",
    name: 'Blossom Trees A',
    animation: 'OFF',
    type: types.BlossomTrees,
    housePart: houseParts.FENCE.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE,
      themes.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  BLOSSOMS_B: {
    id: "BLOSSOMS_B",
    name: 'Blossom Trees B',
    animation: 'OFF',
    type: types.BlossomTrees,
    housePart: houseParts.FENCE.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE,
      themes.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  DOE_A: {
    id: "DOE_A",
    name: 'Reindeer - Doe 1',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.REINDEER.id,
    byoThemeElementId: byoThemeElements.REINDEER.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.GARDEN
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  DOE_B: {
    id: "DOE_B",
    name: 'Reindeer - Doe 2',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.REINDEER.id,
    byoThemeElementId: byoThemeElements.REINDEER.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.GARDEN
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  BUCK: {
    id: "BUCK",
    name: 'Reindeer - Buck',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.REINDEER.id,
    byoThemeElementId: byoThemeElements.REINDEER.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.GARDEN
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ELF_TREE_1: {
    id: 'ELF_TREE_1',
    name: 'Elf Tree 1',
    animation: 'off',
    type: types.RedGreen,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.ELF_TREE.id,
    availableInThemes: [
      ...themeGroups.MULTI
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ELF_TREE_2: {
    id: 'ELF_TREE_2',
    name: 'Elf Tree 2',
    animation: 'off',
    type: types.RedGreen,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.ELF_TREE.id,
    availableInThemes: [
      ...themeGroups.MULTI
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  LETTERBOX: {
    id: 'LETTERBOX',
    name: 'Letterbox',
    animation: 'off',
    type: types.Multi,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.LETTERBOX.id,
    availableInThemes: [
      ...themeGroups.MULTI
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  }
}

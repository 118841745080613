export default {
  name: "BYOThemeElementSelector",
  computed: {
    selectedByoThemeElement: {
      get() {
        return this.$store.getters.selectedByoThemeElement
      },
      set(element) {
        this.$store.dispatch('UPDATE_SELECTED_BYO_THEME_ELEMENTS', { element });
      }
    }
  }
}

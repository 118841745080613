const houseParts = require('../house-parts');
const byoThemeElements = require('../byo-theme-elements');
const channels = require('../channels');
const types = require('../types');
const themeGroups = require('../theme-groups');
const themes = require('../themes');
const elementGroups = require('../element-groups');

module.exports = {
  FENCE_MULTI: {
    id: 'FENCE_MULTI',
    name: 'Fence Party',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.FENCE.id,
    byoThemeElementId: byoThemeElements.FENCE.id,
    availableInThemes: [
      ...themeGroups.MULTI
    ],
    groups: [
      elementGroups.GARDEN
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  FENCE_COOL_WHITE: {
    id: 'FENCE_COOL_WHITE',
    name: 'Fence Ice',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.FENCE.id,
    byoThemeElementId: byoThemeElements.FENCE.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    groups: [
      elementGroups.GARDEN
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  FENCE_WARM_WHITE: {
    id: 'FENCE_WARM_WHITE',
    name: 'Fence Classic',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.FENCE.id,
    byoThemeElementId: byoThemeElements.FENCE.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.GARDEN
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  }

};

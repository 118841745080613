"use strict";

const BaseAnimation = require('./BaseAnimation');

/**
 * Twinkle Flash animation
 *
 * Channel 1 flash 3 times
 * Channel 2 flash 3 times
 * repeat
 *
 * CH1 |X|_|X|_|X|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|X|_|X|_|X|_|
 */
class TwinkleFlash extends BaseAnimation {
  constructor() {
    super();

    this.frameLength = 10; // Length in "ticks"
    this.totalFrames = 11;
    this.setMaxTicks();
    this.id = 'twinkle_flash';

    this.channels = {
      1: [
        0, 2, 4
      ],
      2: [
        6, 8, 10
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Twinkle Flash';
  }
}

module.exports = TwinkleFlash;

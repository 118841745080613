export default {
  name: "ComingSoonTree",
  data() {
    return {
      greenOn: true,
      purpleOn: true,
      redOn: true,
      yellowOn: true,
      blueOn: true,
      pinkOn: true,
      animations: [
        'fade',
        'flash',
        'slowFlash',
        'waves',
        'chase'

      ],
      currentAnimation: 0
    }
  },
  methods: {
    toggleString(lightString) {
      this[lightString] = !this[lightString];
    },
    changeAnimation() {
      let nextAnimation = this.currentAnimation + 1;
      if (nextAnimation >= this.animations.length) {
        nextAnimation = 0;
      }

      this.currentAnimation = nextAnimation;
    }
  }
}

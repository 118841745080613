"use strict";

const BaseAnimation = require('./BaseAnimation');
const normalizeBrightness = require('../utils/normalize-brightness');

class FadeBaseAnimation extends BaseAnimation {
  /**
   * Overwriting this function so that it can handle the object that is passed in from the implementing classes
   *
   * @param {int} channel Number of the channel that is being output to
   *
   * @returns {*}
   */
  getChannel(channel) {
    const currentFrame = this.getFrame();

    // If there is a value in the array that matches the current frame, then that means the light should just
    // be on, with no fading, so return 250
    if (this.channels[channel].includes(currentFrame)) {
      return 250;
    }

    // If none of the frames in the channel definition matches the current frame, return off
    if (this.channels[channel].every(frameInfo => frameInfo.frame !== currentFrame)) {
      return 0;
    }

    // Otherwise, read the brightness value of the object that correspondes to the current frame
    return normalizeBrightness(this.channels[channel].find(frameInfo => frameInfo.frame === currentFrame).brightness);

  }
}



module.exports = FadeBaseAnimation;

const elements = require('./elements');
const themes = require('./themes');
const channels = require('./channels');

/**
 * Generate the different combinations for each set of elements
 *
 * @param {string} theme
 *
 * @returns {array}
 */
const createCombinations = (theme) => {
  return Object.entries(elements)
    .filter(([, elementData]) => {
      return elementData.availableInThemes.includes(theme);
    })
    .map(([elementId]) => {
      return elementId;
    });
};

// @TODO This really could be cleaned up, and all the port numbers moved to the elements config files.. I don't think theres
// any need to have them defined here like this, except to maybe create the combinations down below. The following could
// still be dynamically generated, except rather then defining a port number in here, we simply read it from element config
// Might make it tricky to add new elements thought..
const portsByElement = {
  [elements.BLOSSOMS_A.id]: { [channels.CONST__CH_1]: 25, [channels.CONST__CH_2]: 26 },
  [elements.BLOSSOMS_B.id]: { [channels.CONST__CH_1]: 27, [channels.CONST__CH_2]: 28 },

  [elements.MERRY_CHRISTMAS_SIGN.id]: { [channels.CONST__CH_1]: 113, [channels.CONST__CH_2]: 114 },
  [elements.SANTA_REINDEER.id]: { [channels.CONST__CH_1]: 134, [channels.CONST__CH_2]: [133, 137] },
  [elements.SANTA_SLEIGH.id]: { [channels.CONST__CH_1]: 134, [channels.CONST__CH_2]: null },
  [elements.SANTA_HAND.id]: { [channels.CONST__CH_1]: 135, [channels.CONST__CH_2]: 136 },
  [elements.REINDEER_SLEIGH.id]: { [channels.CONST__CH_1]: 133, [channels.CONST__CH_2]: null },
  [elements.REINDEER_LEGS.id]: { [channels.CONST__CH_1]: 137, [channels.CONST__CH_2]: 138 },
  [elements.BELLS.id]: { [channels.CONST__CH_1]: 129, [channels.CONST__CH_2]: 130 },
  [elements.HOLLY.id]: { [channels.CONST__CH_1]: 141, [channels.CONST__CH_2]: 142 },
  [elements.SNOWFLAKE_ICE_1.id]: { [channels.CONST__CH_1]: 131, [channels.CONST__CH_2]: 132 },
  [elements.SNOWFLAKE_ICE_2.id]: { [channels.CONST__CH_1]: 143, [channels.CONST__CH_2]: 144 },
  [elements.SNOWFLAKE_WARM.id]: { [channels.CONST__CH_1]: 115, [channels.CONST__CH_2]: 116 },
  [elements.KANGAROO_A.id]: { [channels.CONST__CH_1]: 77, [channels.CONST__CH_2]: 78 },
  [elements.KANGAROO_B.id]: { [channels.CONST__CH_1]: 79, [channels.CONST__CH_2]: 80 },
  [elements.KOALA.id]: { [channels.CONST__CH_1]: 73, [channels.CONST__CH_2]: 74 },
  [elements.ROPELIGHT_W.id]: { [channels.CONST__CH_1]: 75, [channels.CONST__CH_2]: 76 },

  [elements.BUCK.id]: { [channels.CONST__CH_1]: 145, [channels.CONST__CH_2]: 146 },
  [elements.DOE_B.id]: { [channels.CONST__CH_1]: 147, [channels.CONST__CH_2]: 148 },
  [elements.DOE_A.id]: { [channels.CONST__CH_1]: 149, [channels.CONST__CH_2]: 150 },

  [elements.FENCE_WARM_WHITE.id]: { [channels.CONST__CH_1]: [31, 93], [channels.CONST__CH_2]: [31, 94] },
  [elements.FENCE_COOL_WHITE.id]: { [channels.CONST__CH_1]: [33, 91], [channels.CONST__CH_2]: [34, 92] },
  [elements.FENCE_MULTI.id]: { [channels.CONST__CH_1]: [35, 95], [channels.CONST__CH_2]: [36, 96] },

  [elements.PEPPERMINT_TREES.id]: { [channels.CONST__CH_1]: 99, [channels.CONST__CH_2]: 100 },
  [elements.ELF_TREE_1.id]: { [channels.CONST__CH_1]: 29, [channels.CONST__CH_2]: 30 },
  [elements.ELF_TREE_2.id]: { [channels.CONST__CH_1]: 97, [channels.CONST__CH_2]: 98 },
  [elements.LETTERBOX.id]: { [channels.CONST__CH_1]: 101, [channels.CONST__CH_2]: 102 },

  [elements.LETTERBOX_TREES_COOL_WHITE.id]: { [channels.CONST__CH_1]: 107, [channels.CONST__CH_2]: 108 },
  [elements.LETTERBOX_TREES_WARM_WHITE.id]: { [channels.CONST__CH_1]: 103, [channels.CONST__CH_2]: 104 },
  [elements.LETTERBOX_TREES_MULTI.id]: { [channels.CONST__CH_1]: 105, [channels.CONST__CH_2]: 106 },

  [elements.TREE_WARM_WHITE.id]: { [channels.CONST__CH_1]: 1, [channels.CONST__CH_2]: 2 },
  [elements.TREE_MULTI.id]: { [channels.CONST__CH_1]: 3, [channels.CONST__CH_2]: 4 },
  [elements.TREE_COOL_WHITE.id]: { [channels.CONST__CH_1]: 5, [channels.CONST__CH_2]: 6 },

  [elements.ROOF_TREE_WARM_WHITE.id]: { [channels.CONST__CH_1]: 17, [channels.CONST__CH_2]: 18 },
  [elements.ROOF_TREE_WHITE.id]: { [channels.CONST__CH_1]: 15, [channels.CONST__CH_2]: 16 },
  [elements.ROOF_TREE_MULTI.id]: { [channels.CONST__CH_1]: 13, [channels.CONST__CH_2]: 14 },

  [elements.ROOF_TREE_STAR_MULTI.id]: { [channels.CONST__CH_1]: 67, [channels.CONST__CH_2]: 68 },
  [elements.ROOF_TREE_STAR_WARM.id]: { [channels.CONST__CH_1]: 69, [channels.CONST__CH_2]: 70 },
  [elements.ROOF_TREE_STAR_COOL.id]: { [channels.CONST__CH_1]: 71, [channels.CONST__CH_2]: 72 },

  [elements.ROOF_MOUNTAIN_WARM_1.id]: { [channels.CONST__CH_1]: [175, 176, 177], [channels.CONST__CH_2]: null },
  [elements.ROOF_MOUNTAIN_COOL.id]: { [channels.CONST__CH_1]: null, [channels.CONST__CH_2]: [178, 179, 180] },
  [elements.ROOF_MOUNTAIN_WARM_2.id]: { [channels.CONST__CH_1]: [172, 173, 174], [channels.CONST__CH_2]: null },

  [elements.GUTTER_LINE_WARM.id]: { [channels.CONST__CH_1]: 7, [channels.CONST__CH_2]: 8 },
  [elements.GUTTER_LINE_COOL.id]: { [channels.CONST__CH_1]: 9, [channels.CONST__CH_2]: 10 },
  [elements.GUTTER_LINE_MULTI.id]: { [channels.CONST__CH_1]: 11, [channels.CONST__CH_2]: 12 },

  [elements.SANTA.id]: { [channels.CONST__CH_1]: 109, [channels.CONST__CH_2]: 110 },
  [elements.TRAIN.id]: { [channels.CONST__CH_1]: 111, [channels.CONST__CH_2]: 112 },
  [elements.RIBBON_BELLS.id]: { [channels.CONST__CH_1]: 127, [channels.CONST__CH_2]: 128 },

  [elements.ROOF_OUTLINE_WARM.id]: { [channels.CONST__CH_1]: [55, 63], [channels.CONST__CH_2]: [56, 64] },
  [elements.ROOF_OUTLINE_COOL.id]: { [channels.CONST__CH_1]: [57, 65], [channels.CONST__CH_2]: [58, 66] },
  [elements.ROOF_OUTLINE_MULTI.id]: { [channels.CONST__CH_1]: [59, 61], [channels.CONST__CH_2]: [60, 62] },

  [elements.MINI_TREES_WW_1.id]: { [channels.CONST__CH_1]: 41, [channels.CONST__CH_2]: 42 },
  [elements.MINI_TREES_W_1.id]: { [channels.CONST__CH_1]: 43, [channels.CONST__CH_2]: 44 },
  [elements.MINI_TREES_WW_2.id]: { [channels.CONST__CH_1]: 45, [channels.CONST__CH_2]: 46 },
  [elements.MINI_TREES_W_2.id]: { [channels.CONST__CH_1]: 47, [channels.CONST__CH_2]: 48 },
  [elements.MINI_TREES_WW_3.id]: { [channels.CONST__CH_1]: 49, [channels.CONST__CH_2]: 50 },
  [elements.MINI_TREES_W_3.id]: { [channels.CONST__CH_1]: 51, [channels.CONST__CH_2]: 52 },
  [elements.MINI_TREES_WW_4.id]: { [channels.CONST__CH_1]: 53, [channels.CONST__CH_2]: 54 },
};

// Using the hardcoded channel and element values above, swap the keys/values, such that the channel ID is the key, and
// the element ID is the value
/**
 * @var {{
 *    portId: {
 *      elementId,
 *      channel
 *    }
 *  }} elementsByPort
 * `portId` is the output port number for the controller
 * `elementId` is the string identifier for the element
 * `channel` is the first or second channel for the above element
 */
const elementsByPort = {};
const portsSeen = [];
Object.entries(portsByElement).forEach(([ elementId, channels ]) => {
  Object.entries(channels).forEach(([channel, port]) => {
    if (portsSeen.includes(port)) {
      // @TODO Come up with a better way to handle where there are ports re-used, and they're expected to be re-used
      console.warn(`Port ${port} used more than once`);
      // throw new Error(`Ports must only be referenced once. Port ${port} used more than once`);
    }
    if (port === 0) {
      throw new Error(`Port 0 needs to be replaced with the actual port number for ${elementId}`);
    }
    if (port !== null) {
      portsSeen.push(port);
    }
    // For elements that are spread out across the yard, they need to be connected to multiple ports, which means
    // in the data above, that element will have an array for the port numbers that is associated.
    // This will mainly in the House component for testing the lights.
    const portNumber = Array.isArray(port) ? port[0] : port;
    elementsByPort[portNumber] = {
      elementId,
      channel: parseInt(channel, 10)
    };

    // If there are two ports, then make sure the other one gets set too.
    // I'm not sure if I'm actually using this anywhere but in the House component at the moment, and this likely
    // means that there will be 2 elements displayed in the House component, but I think that's an issue I should "fix"
    // in the House component itself, rather than messing with the data..
    if (Array.isArray(port)) {
      elementsByPort[port[1]] = {
        elementId,
        channel: parseInt(channel, 10)
      };
    }

  });
});

module.exports = {
  byElement: portsByElement,
  byPort: elementsByPort,

  combinations: [
    // createCombinations(themes.ICE),
    // createCombinations(themes.CLASSIC),
    // createCombinations(themes.PARTY),
    createCombinations(themes.MODERN),
    createCombinations(themes.CHIC),
    createCombinations(themes.ODDBALL),
    createCombinations(themes.CRAZY)
  ]
};

const advancedAnimations = require('./advanced-animations');
const animations = require('./animations');
const animationsLoop = require('./animations-loop');
const byoThemeElements = require('./byo-theme-elements');
const channels = require('./channels');
const controlNames = require('./control-names');
const elements = require('./elements');
const elementGroups = require('./element-groups');
const elementPorts = require('./element-ports');
const houseParts = require('./house-parts');
const midiChannels = require('./midi-channels');
const themes = require('./themes');

module.exports = {
  advancedAnimations,
  animations,
  animationsLoop,
  byoThemeElements,
  channels,
  controlNames,
  elements,
  elementGroups,
  elementPorts,
  houseParts,
  midiChannels,
  themes
};

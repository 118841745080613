import Vue from 'vue'
import Vuex from 'vuex'
import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';
import AJAX from "../utils/AJAX";
import getLocation from "../utils/get-location";
import appConfig from '../../application-configuration';
import Storage from "../utils/Storage";
import modules from './modules';
import router from '@router';
import { listenClientKey } from "@store/FirebaseListeners";
import Analytics from "@utils/Analytics";

const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

const queueKey = Storage.getItem(Storage.CONST__QUEUE_KEY);

export default new Vuex.Store({
  modules,
  state: {
    lightsStatus: 'checking',
    isInQueue: false,
    isRequestingControl: !!queueKey || false, // Handles issues when user refreshes page while in queue or in control. Displays loading animation until Firebase tells device where it is
    isInControl: false,
    queuePosition: null,
    key: queueKey || null
  },
  mutations: {
    setProperty(state, { property, value }) {
      state[property] = value;
    }
  },
  actions: {

    async requestControl({ getters, commit, dispatch }) {
      if (getters.isRequestingControl) {
        // If the user is already requesting control, no point letting them do it again.
        // This will actually prevent the user from tapping the Take Control button if they accidentally refresh the page
        return;
      }
      // console.log(`requestControl`);
      commit('setProperty', { property: 'isRequestingControl', value: true });
      const currentPosition = await getLocation();
      // Send request to Firebase to request control - send users position information
      const response = await AJAX.post('requestTakeControl', { currentPosition });
      const { body: { key = null, control = false, queued = false, tooFar = false, position = null, lightsOff = false } } = response;

      commit('setProperty', { property: 'isRequestingControl', value: false });

      if (lightsOff) {
        dispatch('alert', { message: `Unable to take control, the lights are off!`, type: 'info' });
        return;
      }

      if (tooFar) {
        dispatch('alert', { message: `Unable to take control, too far from house.`, type: 'info' });
        return;
      }

      commit('setProperty', { property: 'isInControl', value: control });
      Storage.setItem(Storage.CONST__QUEUE_KEY, key);
      commit('setProperty', { property: 'key', value: key });
      LogRocket.identify(key);
      listenClientKey(key);

      if (queued) {
        router.push({ path: '/queue' });
      }

      if (control) {
        router.push({ path: '/control' });
      }
    },

    SET_LIGHTS_STATUS({ commit }, payload) {
      const { status } = payload;
      commit('setProperty', { property: 'lightsStatus', value: status })
    },

    ABANDON_QUEUE({ commit, getters }) {
      Analytics.FireFeatureUsed('abandon_queue', getters.clientKey)
      // console.log(`ABANDON_QUEUE`);
      commit('setProperty', { property: 'isInQueue', value: false });
      commit('setProperty', { property: 'isInControl', value: false });
      router.push({ path: '/' });

      // console.log(getters.clientKey);
      Storage.removeItem(Storage.CONST__QUEUE_KEY);
      AJAX.post('abandonQueue', {
        key: getters.clientKey
      });
    },

    SET_CLIENT_KEY({ commit }, payload) {
      // console.log(`SET_CLIENT_KEY`, payload);
      const { key } = payload;
      commit('setProperty', { property: 'key', value: key });
    },

    GRANT_CONTROL({ commit, getters }) {
      Analytics.FireFeatureUsed('abandon_queue', getters.clientKey);
      // console.log(`GRANT_CONTROL`);
      commit('setProperty', { property: 'isInControl', value: true });
      commit('setProperty', { property: 'isInQueue', value: false });
      if (router.history.current.name !== 'control') {
        router.push({ path: '/control' });
      }
    },

    REVOKE_CONTROL({ commit, dispatch, getters }) {
      Analytics.FireFeatureUsed('revoke_control', getters.clientKey);
      // console.log(`REVOKE_CONTROL`);
      commit('setProperty', { property: 'isInControl', value: false });
      commit('setProperty', { property: 'key', value: '' });
      dispatch('SET_IS_TRACKING_TIMER', { tracking: false });
      Storage.removeItem(Storage.CONST__QUEUE_KEY);
      commit('setProperty', { property: 'isRequestingControl', value: false });

      if (router.history.current.name !== 'take-control') {
        router.push({ path: '/finished?queue-advanced' });
      }
    },

    YIELD_CONTROL({ commit, getters }) {
      Analytics.FireFeatureUsed('yield_control', getters.clientKey);

      Storage.removeItem(Storage.CONST__QUEUE_KEY);
      if (!getters.isInControl) {
        router.push({ path: '/finished' });
        return;
      }
      // console.log(`YIELD_CONTROL`);

      AJAX.post('yieldControl', { key: getters.clientKey });
      if (router.history.current.name !== 'take-control') {
        commit('setProperty', { property: 'isRequestingControl', value: false });
        commit('setProperty', { property: 'isInControl', value: false });
        router.push({ path: '/finished' });
      }
    },

    IS_IN_QUEUE({ commit }, payload) {
      // console.log(`IS_IN_QUEUE`, payload);
      const { position } = payload;
      commit('setProperty', { property: 'isInControl', value: false });
      commit('setProperty', { property: 'isInQueue', value: true });
      commit('setProperty', { property: 'queuePosition', value: position });
      if (router.history.current.name !== 'queue') {
        router.push({ path: '/queue' });
      }
    },

    /**
     * Likely called after a user has refreshed their device, whilst either being in control, or being in the queue.
     * The UI is updated to look like it's loading for those users, to prevent them from clicking the Take Control button whilst
     * Firebase is getting back to them to let them know they're in control, or which position of the queue they're in
     */
    NOT_IN_QUEUE({ commit }) {
      commit('setProperty', { property: 'isInQueue', value: false }); // Shouldn't be needed, but just in case
      commit('setProperty', { property: 'isRequestingControl', value: false }); // Firebase has finished checking if this person is in the queue
    },

    PURGE_QUEUE() {
      AJAX.post('purgeQueue');
    }
  },
  getters: {
    lightsStatus: state => state.lightsStatus,
    isInControl: state => state.isInControl,
    isRequestingControl: state => state.isRequestingControl,
    isInQueue: state => state.isInQueue,
    clientKey: state => state.key,
    queuePosition: state => state.queuePosition
  },
  plugins: [logrocketPlugin]
})

"use strict";

const Sequential = require('../sequential');

/**
 * Second half of the Sequential animation
 *
 * Channel 1 on, Channel 2 off
 * Channel 1 off, Channel 2 on
 * Repeat
 *
 * CH1 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|X|X|X|X|X|X|X|X|X|X|
 */
class SequentialTwo extends Sequential {
  constructor() {
    super();

    this.channels = {
      1: [
        10, 11, 12, 13, 14, 15, 16, 17, 18, 19
      ],
      2: [
        10, 11, 12, 13, 14, 15, 16, 17, 18, 19
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '2nd';
  }
}

module.exports = SequentialTwo;

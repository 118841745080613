const { houseParts } = require('../constants');

/**
 * Find the house part object for a given house part ID
 *
 * @param {string} selectedHousePart The ID of the house part to find the data for
 *
 * @returns {{
    id: string,
    name: string,
    availableThemes: Array
  }}
 */
module.exports = (selectedHousePart) => {
    return Object.values(houseParts).find(housePart => housePart.id === selectedHousePart);
}

/**
 * Take a brightness value and ensure it doesn't exceed the maximum brightness, and isn't any less than the minimum
 * brightness
 * The max brightness is 250
 * The min brightness is 0
 *
 * @param {int} brightness The brightness number to normalize
 *
 * @returns {int}
 */
const normalizeBrightness = (brightness) => {
  if (brightness > 250) {
    return 250;
  }

  if (brightness < 0) {
    return 0;
  }

  return brightness;
};

module.exports = normalizeBrightness;

"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * Decay Flash animation
 *
 * This is to be used when sending a pulse from the "Go Crazy" control feature
 * Elements will turn on immediately, then fade out slowly
 *
 * Channel X turn on, then fade out
 * - Animation has been set up such that it can send the animation to only 1 channel
 *   This is for when being used with the "Go Crazy" control feature
 *   When being used normally, the channel argument won't be used
 *
 */
class DecayFlash extends FadeBaseAnimation {
    constructor(channel = false) {
        super();

        this.id = 'decay_flash';
        this.frameLength = 10; // Length in "ticks"
        this.totalFrames = 25;
        this.setMaxTicks();
        this.fadeLength = this.totalFrames; // Length in ticks. ~ 300ms
        this.fadeIncrement = 250 / this.fadeLength; // +1 ensures brightness gets to 100%. This will happen for multiple frames, but that's alright

        const channelOn = [{ frame: 1, brightness: 250 }];

        for (let frameFadeDown = 2, frameFadeDownMultiplier = this.fadeLength ; frameFadeDown < this.totalFrames ; frameFadeDown++, frameFadeDownMultiplier--) {
            channelOn.push({
                frame: frameFadeDown,
                brightness: Math.ceil(this.fadeIncrement * frameFadeDownMultiplier)
            });
        }

        if (channel !== false) {
            this.channels = {
                [channel]: channelOn
            };
        } else {
            this.channels = {
                1: channelOn,
                2: channelOn
            };
        }

    }

    /**
     * Get the display name for this animation
     * Used on buttons and reporting
     *
     * @returns {string}
     */
    static getDisplayName() {
        return 'Decay Flash';
    }
}

module.exports = DecayFlash;

<template>
  <String class="string--cool-white" :strLength="1">
    <White :brightness="channelOneBrightness"/>
    <White :brightness="channelOneBrightness"/>
    <White :brightness="channelTwoBrightness"/>
    <White :brightness="channelTwoBrightness"/>
  </String>
</template>

<script>
  import String from './String';
  import White from '../Lights/White';

  export default {
    name: 'BlossomTrees',
    components: {
      String,
      White
    },
    props: [
      'channelOneBrightness',
      'channelTwoBrightness'
    ]
  };
</script>

<style>

</style>

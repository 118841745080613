"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * In Waves animation
 *
 * Channel 1 fade in, channel 2 off
 * Channel 1 fade out, channel 2 off
 * Channel 1 off, channel 2 fade in
 * Channel 1 off, channel 2 fade out
 *
 * Fade takes around 300ms
 *
 * CH1 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 */
class InWaves extends FadeBaseAnimation {
  constructor() {
    super();

    this.id = 'in_waves';
    this.frameLength = 3; // Length in "ticks"
    this.fadeLength = 30; // Length in ticks. ~ 300ms
    this.fadeIncrement = 250 / this.fadeLength;

    this.ch1FrameFadeUpStart = 0;
    this.ch1FrameFadeHoldStart = this.ch1FrameFadeUpStart + this.fadeLength;
    this.ch1FrameFadeDownStart = this.ch1FrameFadeHoldStart + this.fadeLength;

    this.ch2FrameFadeUpStart = 15;
    this.ch2FrameFadeHoldStart = this.ch2FrameFadeUpStart + this.fadeLength;
    this.ch2FrameFadeDownStart = this.ch2FrameFadeHoldStart + this.fadeLength;

    this.totalFrames = this.ch2FrameFadeDownStart + this.fadeLength;
    this.setMaxTicks();

    const channelOneOn = [];
    for (let ch1FrameFadeUp = this.ch1FrameFadeUpStart ; ch1FrameFadeUp < this.ch1FrameFadeUpStart + this.fadeLength ; ch1FrameFadeUp++) {
      channelOneOn.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeUp)
      });
    }

    for (let ch1FrameFadeHold = this.ch1FrameFadeHoldStart ; ch1FrameFadeHold < this.ch1FrameFadeHoldStart + this.fadeLength ; ch1FrameFadeHold++) {
      channelOneOn.push({
        frame: ch1FrameFadeHold,
        brightness: 250
      });
    }

    for (let ch1FrameFadeDown = this.ch1FrameFadeDownStart, ch1FrameFadeDownMultiplier = 29 ; ch1FrameFadeDown < this.ch1FrameFadeDownStart + this.fadeLength ; ch1FrameFadeDown++, ch1FrameFadeDownMultiplier--) {
      channelOneOn.push({
        frame: ch1FrameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeDownMultiplier)
      });
    }

    const channelTwoOn = [];
    for (let ch2FrameFadeUp = this.ch2FrameFadeUpStart, ch2FrameFadeUpMultiplier = 0 ; ch2FrameFadeUp < this.ch2FrameFadeUpStart + this.fadeLength ; ch2FrameFadeUp++, ch2FrameFadeUpMultiplier++) {
      channelTwoOn.push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FrameFadeUpMultiplier)
      });
    }

    for (let ch2FrameFadeHold = this.ch2FrameFadeHoldStart ; ch2FrameFadeHold < this.ch2FrameFadeHoldStart + this.fadeLength ; ch2FrameFadeHold++) {
      channelTwoOn.push({
        frame: ch2FrameFadeHold,
        brightness: 250
      });
    }

    for (let ch2FrameFadeUp = this.ch2FrameFadeDownStart, ch2FrameFadeDownMultiplier = 29 ; ch2FrameFadeUp < this.ch2FrameFadeDownStart + this.fadeLength ; ch2FrameFadeUp++, ch2FrameFadeDownMultiplier--) {
      channelTwoOn.push({
        frame: ch2FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch2FrameFadeDownMultiplier)
      });
    }

    this.channels = {
      1: channelOneOn,
      2: channelTwoOn
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'In Waves';
  }
}

module.exports = InWaves;

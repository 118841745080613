const EventEmitter = require('events');

class MyEmitter extends EventEmitter {}

class SingletonEmitter {
  constructor() {
    if (!SingletonEmitter.instance) {
      this._eventEmitter = new MyEmitter();
      SingletonEmitter.instance = this;
    }

    return SingletonEmitter.instance;
  }

  getEventEmitter() {
    return this._eventEmitter;
  }
}

const singletonEmitter = new SingletonEmitter();

module.exports = singletonEmitter;

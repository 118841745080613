const AlternateDecayFlash = require('./alternate-decay-flash');
const AlternatePulses = require('./alternate-pulses');
const AlternatePulsesOne = require('./halves/alternate-pulses-one');
const AlternatePulsesTwo = require('./halves/alternate-pulses-two');
const BattleFlash = require('./battle-flash');
const BattleFlashOne = require('./halves/battle-flash-one');
const BattleFlashTwo = require('./halves/battle-flash-two');
const ChasingFlash = require('./chasing-flash');
const ChasingFlashOne = require('./halves/chasing-flash-one');
const ChasingFlashTwo = require('./halves/chasing-flash-two');
const DecayFlash = require('./decay-flash');
const DoublePulse = require('./double-pulse');
const FadePop = require('./fade-pop');
const Heartbeat = require('./heartbeat');
const InWaves = require('./in-waves');
const InWavesOne = require('./halves/in-waves-one');
const InWavesTwo = require('./halves/in-waves-two');
const JingleBells = require('./jingle-bells');
const Off = require('./off');
const PingPongFlash = require('./ping-pong-flash');
const PingPongFlashOne = require('./halves/ping-pong-flash-one');
const PingPongFlashTwo = require('./halves/ping-pong-flash-two');
const Pulse = require('./pulse');
const Pulsing = require('./pulsing');
const SequentialControlled = require('./sequential-controlled');
const Sequential = require('./sequential');
const SequentialOne = require('./halves/sequential-one');
const SequentialTwo = require('./halves/sequential-two');
const SloGlo = require('./slo-glo');
const SloGloOne = require('./halves/slo-glo-one');
const SloGloTwo = require('./halves/slo-glo-two');
const SlowFade = require('./slow-fade');
const SteadyOn = require('./steady-on');
const TwinkleFlash = require('./twinkle-flash');
const TwinkleFlashOne = require('./halves/twinkle-flash-one');
const TwinkleFlashTwo = require('./halves/twinkle-flash-two');
const TwinklePulse = require('./twinkle-pulse');

module.exports = {
  AlternateDecayFlash,
  AlternatePulses,
  AlternatePulsesOne,
  AlternatePulsesTwo,
  BattleFlash,
  BattleFlashOne,
  BattleFlashTwo,
  ChasingFlash,
  ChasingFlashOne,
  ChasingFlashTwo,
  DecayFlash,
  DoublePulse,
  FadePop,
  Heartbeat,
  InWaves,
  InWavesOne,
  InWavesTwo,
  JingleBells,
  Off,
  PingPongFlash,
  PingPongFlashOne,
  PingPongFlashTwo,
  Pulse,
  Pulsing,
  SequentialControlled,
  Sequential,
  SequentialOne,
  SequentialTwo,
  SloGlo,
  SloGloOne,
  SloGloTwo,
  SlowFade,
  SteadyOn,
  TwinkleFlash,
  TwinkleFlashOne,
  TwinkleFlashTwo,
  TwinklePulse
};

import { SocialMediaLinks } from "@components";

export default {
  name: "TakeControl",
  components: {
    SocialMediaLinks
  },
  data() {
    return {
      instructionsVisible: false
    }
  }
}

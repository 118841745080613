import alerts from './alerts';
import animations from './animations';
import elements from './elements';
import houseParts from './house-parts';
import themes from './themes';
import timer from './timer';

export default {
  alerts,
  animations,
  elements,
  houseParts,
  themes,
  timer
};

// import firebase from "firebase";
// import 'firebase/database';
import { controlNames, animations } from "@constants";
// import store from '@store';

export default {
  name: "SantaSleighControl",
  data: function() {
    return {
      runSpeed: 50,
      ticksLabels: [
        'Walk',
        'Trot',
        'Gallop'
      ]
    };
  },
  methods: {
    onSantaWave() {
      this.$store.dispatch('CHANGE_ANIMATION', { animation: 'santa_wave', control: controlNames.CONTROL__SANTA_SLEIGH })
      // firebase.database().ref('/').update({
      //   'command': JSON.stringify({
      //     name: 'santa_wave',
      //     payload: {}
      //   }),
      //   updatingKey: store.getters.clientKey
      // });
    },

    stopSantaWave() {
      this.$store.dispatch('CHANGE_ANIMATION', { animation: 'santa_wave_stop', control: controlNames.CONTROL__SANTA_SLEIGH })
      // firebase.database().ref('/').update({
      //   'command': JSON.stringify({
      //     name: 'santa_wave_stop',
      //     payload: {}
      //   }),
      //   updatingKey: store.getters.clientKey
      // });
    },

    onReindeerRun() {
      this.$store.dispatch('CHANGE_ANIMATION', { animation: 'reindeer_trot', control: controlNames.CONTROL__SANTA_SLEIGH })
      // firebase.database().ref('/').update({
      //   'command': JSON.stringify({
      //     name: 'reindeer_run',
      //     payload: {
      //       speed: this.runSpeed
      //     }
      //   }),
      //   updatingKey: store.getters.clientKey
      // });
    },

    onSpeedChange() {
      // @TODO Potentially make this sliding scale the literal control of how fast the legs are. Passing the selected value over the API call, rather than word that is interpreted on the BE
      let animationName;
      switch (this.runSpeed) {
        case 0:
          animationName = 'reindeer_walk';
          break;
        case 50:
          animationName = 'reindeer_trot';
          break;
        case 100:
          animationName = 'reindeer_gallop';
          break;
      }

      // firebase.database().ref('/').update({
      //   'command': JSON.stringify({
      //     name: 'reindeer_run',
      //     payload: {
      //       speed: this.runSpeed
      //     }
      //   }),
      //   updatingKey: store.getters.clientKey
      // });
      this.$store.dispatch('CHANGE_ANIMATION', { animation: animationName, control: controlNames.CONTROL__SANTA_SLEIGH })
    }
  }
}

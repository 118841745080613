<template>
  <Light class="light--green" :style="{ 'background-color': backgroundColor }"/>
</template>

<script>
  import Light from './Light';
  import {convertBrightness} from '../../utils';

  export default {
    name: 'GreenLight',
    components: {
      Light
    },
    props: [
      'brightness'
    ],
    computed: {
      backgroundColor() {
        return `rgba(0, 255, 0, ${convertBrightness(this.brightness)})`;
      }
    }
  };
</script>

<style>
  .light--green {
    border-color: rgb(0, 255, 0);
  }
</style>

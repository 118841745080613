const houseParts = require('../house-parts');
const byoThemeElements = require('../byo-theme-elements');
const channels = require('../channels');
const types = require('../types');
const themeGroups = require('../theme-groups');
const themes = require("../themes");
const elementGroups = require('../element-groups');

module.exports = {
  ROOF_TREE_MULTI: {
    id: "ROOF_TREE_MULTI",
    name: 'Roof Tree Party',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_TREE.id,
    availableInThemes: [
      ...themeGroups.MULTI
    ],
    groups: [
      elementGroups.ROOF,
      elementGroups.ROOF_TREE
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ROOF_TREE_WHITE: {
    id: "ROOF_TREE_WHITE",
    name: 'Roof Tree Ice',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_TREE.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    groups: [
      elementGroups.ROOF,
      elementGroups.ROOF_TREE
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ROOF_TREE_WARM_WHITE: {
    id: "ROOF_TREE_WARM_WHITE",
    name: 'Roof Tree Classic',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_TREE.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.ROOF,
      elementGroups.ROOF_TREE
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ROOF_TREE_STAR_MULTI: {
    id: "ROOF_TREE_STAR_MULTI",
    name: 'Roof Tree Multi Star',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_TREE_STAR.id,
    availableInThemes: [
      ...themeGroups.MULTI
    ],
    groups: [
      elementGroups.ROOF,
      elementGroups.ROOF_TREE
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ROOF_TREE_STAR_COOL: {
    id: "ROOF_TREE_STAR_COOL",
    name: 'Roof Tree White Star',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_TREE_STAR.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    groups: [
      elementGroups.ROOF,
      elementGroups.ROOF_TREE
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ROOF_TREE_STAR_WARM: {
    id: "ROOF_TREE_STAR_WARM",
    name: 'Roof Tree Warm White Star',
    animation: 'OFF',
    type: types.Blue,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_TREE_STAR.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.ROOF,
      elementGroups.ROOF_TREE
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ROOF_OUTLINE_WARM: {
    id: "ROOF_OUTLINE_WARM",
    name: 'Roof Outline Classic',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_PEAK.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.ROOF
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ROOF_OUTLINE_MULTI: {
    id: "ROOF_OUTLINE_MULTI",
    name: 'Roof Outline Party',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_PEAK.id,
    availableInThemes: [
      ...themeGroups.MULTI
    ],
    groups: [
      elementGroups.ROOF
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ROOF_OUTLINE_COOL: {
    id: "ROOF_OUTLINE_COOL",
    name: 'Roof Outline Ice',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_PEAK.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    groups: [
      elementGroups.ROOF
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  GUTTER_LINE_MULTI: {
    id: "GUTTER_LINE_MULTI",
    name: 'Gutter Line Party',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.GUTTER_LINE.id,
    availableInThemes: [
      ...themeGroups.MULTI
    ],
    groups: [
      elementGroups.ROOF
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  GUTTER_LINE_WARM: {
    id: "GUTTER_LINE_WARM",
    name: 'Gutter Line Warm',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.GUTTER_LINE.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.ROOF
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  GUTTER_LINE_COOL: {
    id: "GUTTER_LINE_COOL",
    name: 'Gutter Line Cool',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.GUTTER_LINE.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    groups: [
      elementGroups.ROOF
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ROOF_MOUNTAIN_COOL: {
    id: "ROOF_MOUNTAIN_COOL",
    name: 'Roof Mountain Ice',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_MOUNTAINS.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    groups: [
      elementGroups.ROOF
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ROOF_MOUNTAIN_WARM_1: {
    id: "ROOF_MOUNTAIN_WARM_1",
    name: 'Roof Mountain Warm (Main)',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_MOUNTAINS.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.ROOF
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  ROOF_MOUNTAIN_WARM_2: {
    id: "ROOF_MOUNTAIN_WARM_2",
    name: 'Roof Mountain Warm (Secondary)',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.ROOF_MOUNTAINS.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.ROOF
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },
}

const determineMidpoint = require('./determine-midpoint');

module.exports = (totalElements, sequencePosition) => {
  const midPoints = determineMidpoint(totalElements);

  // First and last elements light up immediately
  if (sequencePosition === 0 || sequencePosition === (totalElements - 1)) {
    return 0;
  }

  if (midPoints.includes(sequencePosition)) {
    if (midPoints.length > 1) {
      return Math.floor(totalElements / 2) - 1;
    }

    return Math.floor(totalElements / 2)
  }

  if (sequencePosition < midPoints[0]) {
    return sequencePosition
  }

  // Here, we're dealing with a sequence position that is above the midpoint
  return Math.abs(totalElements - sequencePosition) - 1;
}
<template>
  <Light class="light--warm-white" :style="{ 'background-color': backgroundColor }"/>
</template>

<script>
  import Light from './Light';
  import {convertBrightness} from '../../utils';

  export default {
    name: 'WarmWhiteLight',
    components: {
      Light
    },
    props: [
      'brightness'
    ],
    computed: {
      backgroundColor() {
        return `rgba(245, 191, 66, ${convertBrightness(this.brightness)})`;
      }
    }
  };
</script>

<style>
  .light--warm-white {
    border-color: rgb(245, 191, 66);
  }
</style>

/**
 * This file contains all of the available themes
 *
 * The themes listed here will be displayed in the UI of the application, so only add ones that should be visible
 */

const ICE = 'ice';
const CLASSIC = 'classic';
const PARTY = 'party';
const MODERN = 'modern';
const CHIC = 'chic';
const ODDBALL = 'oddball';
const CRAZY = 'crazy';
const FIRE = 'fire';
const LAGOON = 'lagoon';
const MOTIFS = 'motifs';
const OFF = 'off';

module.exports = {
  ICE,
  CLASSIC,
  PARTY,
  MODERN,
  CHIC,
  ODDBALL,
  CRAZY,
  MOTIFS,
  // FIRE,
  // LAGOON,
  OFF
};

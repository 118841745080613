const types = require("../types");
const houseParts = require("../house-parts");
const byoThemeElements = require('../byo-theme-elements');
const themeGroups = require("../theme-groups");
const themes = require("../themes");
const channels = require("../channels");
const elementGroups = require('../element-groups');

module.exports = {
  BELLS: {
    id: 'BELLS',
    name: 'Bells',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.FACADE.id,
    byoThemeElementId: byoThemeElements.BELLS.id,
    availableInThemes: [
      ...themeGroups.MULTI,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.FACADE,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  SNOWFLAKE_WARM: {
    id: 'SNOWFLAKE_WARM',
    name: 'Warm Snowflakes',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.SNOWFLAKES.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE,
      ...themeGroups.WARM_WHITE,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.ROOF,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  SNOWFLAKE_ICE_1: {
    id: 'SNOWFLAKE_ICE_1',
    name: 'Ice Snowflake 1',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.SNOWFLAKES.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.ROOF,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  SNOWFLAKE_ICE_2: {
    id: 'SNOWFLAKE_ICE_2',
    name: 'Ice Snowflake 2',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.SNOWFLAKES.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.ROOF,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  HOLLY: {
    id: 'HOLLY',
    name: 'Holly',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.HOLLY.id,
    availableInThemes: [
      ...themeGroups.MULTI,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.ROOF,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  MERRY_CHRISTMAS_SIGN: {
    id: 'MERRY_CHRISTMAS_SIGN',
    name: 'Merry Christmas Sign',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.FACADE.id,
    byoThemeElementId: byoThemeElements.MERRY_CHRISTMAS.id,
    availableInThemes: [
      ...themeGroups.MULTI,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.FACADE,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  SANTA_REINDEER: {
    id: "SANTA_REINDEER",
    name: 'Santa & Reindeer',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.ROOF.id,
    byoThemeElementId: byoThemeElements.SANTA_REINDEER.id,
    availableInThemes: [
      ...themeGroups.MULTI,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.ROOF,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  SANTA_SLEIGH: {
    id: "SANTA_SLEIGH",
    name: 'Santa in Sleigh',
    animation: 'OFF',
    type: types.Multi,
    housePart: null,
    byoThemeElementId: byoThemeElements.SANTA_REINDEER.id,
    availableInThemes: [],
    groups: [
      elementGroups.ROOF,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  REINDEER_SLEIGH: {
    id: "REINDEER_SLEIGH",
    name: 'Reindeer with Sleigh',
    animation: 'OFF',
    type: types.Multi,
    housePart: null,
    byoThemeElementId: byoThemeElements.SANTA_REINDEER.id,
    availableInThemes: [],
    groups: [
      elementGroups.ROOF,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  SANTA_HAND: {
    id: "SANTA_HAND",
    name: "Santa's Hand",
    animation: 'OFF',
    type: types.Multi,
    housePart: null, // This is used to omit the elements from element selectors
    byoThemeElementId: byoThemeElements.SANTA_REINDEER.id,
    availableInThemes: [],
    groups: [
      elementGroups.ROOF,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  REINDEER_LEGS: {
    id: "REINDEER_LEGS",
    name: "Reindeer's Legs",
    animation: 'OFF',
    type: types.Multi,
    housePart: null,
    byoThemeElementId: byoThemeElements.SANTA_REINDEER.id,
    availableInThemes: [],
    groups: [
      elementGroups.ROOF,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  KANGAROO_A: {
    id: "KANGAROO_A",
    name: 'Kangaroo A',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.FENCE.id,
    byoThemeElementId: byoThemeElements.KANGAROOS.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  KANGAROO_B: {
    id: "KANGAROO_B",
    name: 'Kangaroo B',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.FENCE.id,
    byoThemeElementId: byoThemeElements.KANGAROOS.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  RIBBON_BELLS: {
    id: "RIBBON_BELLS",
    name: 'Ribbon Bells',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.FACADE.id,
    byoThemeElementId: byoThemeElements.RIBBON_BELLS.id,
    availableInThemes: [
      ...themeGroups.MULTI,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.FACADE,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  TRAIN: {
    id: "TRAIN",
    name: 'Train',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.FACADE.id,
    byoThemeElementId: byoThemeElements.TRAIN.id,
    availableInThemes: [
      ...themeGroups.MULTI,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.FACADE,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  SANTA: {
    id: "SANTA",
    name: 'Santa',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.FACADE.id,
    byoThemeElementId: byoThemeElements.SANTA.id,
    availableInThemes: [
      ...themeGroups.MULTI,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.FACADE,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },

  KOALA: {
    id: "KOALA",
    name: 'Koala',
    animation: 'OFF',
    type: types.Multi,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.KOALA.id,
    availableInThemes: [
      ...themeGroups.MULTI,
      themes.MOTIFS
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.MOTIFS
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  }
}

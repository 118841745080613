import constants from '@constants';
import animations from '@animations';
import { ElementSelector } from '@components';

export default {
  name: 'AnimationsControl',
  components: {
    ElementSelector
  },
  data() {
    return {
      newAnimations: [
        'fade_pop'
      ]
    }
  },
  computed: {
    animations() {
      const anims = constants.animations;
      delete anims.off; // Not intended for the app
      delete anims.double_pulse; // Not intended for the app
      delete anims.pulse; // Not intended for the app
      delete anims.sequential_controlled; // Not ready for individual control yet
      return anims;
    }
  },
  methods: {
    /**
     * Get the display name for a given animation ID
     * ! This needs to be removed, but have put it back just for now as there was an issue using the function that has been moved to @utils
     *
     * @param {string} animationName The ID of the animation to get the name of
     *
     * @returns {string}
     */
    getAnimationDisplayName(animationName) {
      // The logic of determining whether there are "halved" animations has been moved here, rather than in the template
      let classInstance = animationName;
      if (Array.isArray(animationName)) {
        classInstance = Object.values(animationName[0])[0];
      }

      return animations[classInstance].getDisplayName();
    },
  }
}

"use strict";

const BaseAnimation = require('./BaseAnimation');

/**
 * Steady On animation
 *
 * Both channels on indefinitely
 *
 * CH1 |X|
 * CH2 |X|
 */
class SteadyOn extends BaseAnimation {
  /**
   *
   * @param opts
   * @param opts.offset      [Optional] The number of frames to wait before this element will come on. This will be used for sweeps
   * @param opts.totalFrames [Optional] The overall length of the animation. Used for sweeps and will change depending on how many elements in sweep
   */
  constructor({ offset = 0, totalFrames = 1 } = {}) {
    super();

    this.frameLength = 10; // Length in "ticks"
    this.totalFrames = totalFrames;
    this.setMaxTicks();
    this.id = 'steady_on';

    this.channels = {
      1: [
        offset
      ],
      2: [
        offset
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'No Animation';
  }
}

module.exports = SteadyOn;

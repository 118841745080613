"use strict";

const FadeBaseAnimation = require('./FadeBaseAnimation');

/**
 * Pulsing animation
 *
 * @returns {null}
 */
class Pulsing extends FadeBaseAnimation {
  constructor() {
    super();

    this.id = 'pulsing';
    this.frameLength = 3; // Length in "ticks"
    this.totalFrames = 60;
    this.setMaxTicks();
    this.fadeLength = 30; // Length in ticks. ~ 300ms
    this.fadeIncrement = 250 / this.fadeLength; // +1 ensures brightness gets to 100%. This will happen for multiple frames, but that's alright

    const channelOneOn = [];
    const channelTwoOn = [];
    for (let ch1FrameFadeUp = 0 ; ch1FrameFadeUp <= 29 ; ch1FrameFadeUp++) {
      channelOneOn.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeUp)
      });
      channelTwoOn.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeUp)
      });
    }

    for (let ch1FrameFadeDown = 30, ch1FrameFadeDownMultiplier = 29 ; ch1FrameFadeDown <= 59 ; ch1FrameFadeDown++, ch1FrameFadeDownMultiplier--) {
      channelOneOn.push({
        frame: ch1FrameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeDownMultiplier)
      });
      channelTwoOn.push({
        frame: ch1FrameFadeDown,
        brightness: Math.ceil(this.fadeIncrement * ch1FrameFadeDownMultiplier)
      });
    }

    this.channels = {
      1: channelOneOn,
      2: channelTwoOn
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Pulsing';
  }
}

module.exports = Pulsing;

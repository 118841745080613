const animations = require('./animations');

const loopAnimations = [
  'alternate_decay_flash',
  'alternate_pulses',
  'decay_flash',
  'fade_pop',
  'heartbeat',
  'in_waves',
  'pulsing',
  'sequential',
  'slo_glo',
  'slow_fade',
  'steady_on',
  'twinkle_pulse',
  'off'
];

const exportsObject = {};

Object.entries(animations).forEach(([animationName, animationValue]) => {
  if (loopAnimations.includes(animationName)) {
    exportsObject[animationName] = animationValue;
  }
})

module.exports = exportsObject;

const { elements, byoThemeElements } = require('../constants');

export const getAvailableHousePartThemes = (housePart) => {
  const elementsInHousePart = Object.values(elements).filter((element) => element.housePart === housePart)
  const themes = elementsInHousePart.map(element => element.availableInThemes);
  return [...new Set(themes.flat().filter(Boolean))];
}

export const getAvailableElementGroupThemes = (group) => {
  const elementsInGroup = Object.values(elements).filter(element => element.byoThemeElement === group)
  return  [...new Set(elementsInGroup.map(element => element.availableInThemes).flat().filter(Boolean))];
}

export const getAvailableByoThemeElementThemes = (byoThemeElement) => {
  if (!byoThemeElement) return [];
  const element = Object.values(byoThemeElements).find(element => element.id === byoThemeElement);
  if (element.availableThemes.length === 1 && element.availableThemes.includes('off')) {
    return [
      'On', 'Off'
    ]
  }

  return [...new Set(element.availableThemes.filter(Boolean))].sort((a, b) => a === 'off' ? 1 : -1);
}

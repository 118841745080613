"use strict";

const BaseAnimation = require('./BaseAnimation');

/**
 * Sequential animation
 *
 * Channel 1 on, Channel 2 off
 * Channel 1 off, Channel 2 on
 * Repeat
 *
 * CH1 |X|X|X|X|X|X|X|X|X|X|_|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|_|_|_|_|_|_|X|X|X|X|X|X|X|X|X|X|
 */
class Sequential extends BaseAnimation {
  constructor() {
    super();

    this.frameLength = 10; // Length in "ticks"
    this.totalFrames = 20;
    this.setMaxTicks();
    this.id = 'sequential';

    this.channels = {
      1: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9
      ],
      2: [
        10, 11, 12, 13, 14, 15, 16, 17, 18, 19
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Sequential';
  }
}

module.exports = Sequential;

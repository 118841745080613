"use strict";

const GroupAnimation = require('./GroupAnimation');
const determineExplodeOffset = require('../utils/determine-explode-offset');

/**
 * Bounce animation
 *
 * See docblock in sweep.js for explanation of how the group animations work
 *
 * Visualisation
 * Frames ->
 * E1 |_|_|_|X|
 * E2 |_|_|X|_|
 * E3 |_|X|_|_|
 * E4 |X|_|_|_|
 * E5 |_|X|_|_|
 * E6 |_|_|X|_|
 * E* |_|_|_|X|
 *
 * The X is when the element should start at full brightness then fade out
 */
class Explode extends GroupAnimation {
  static id = 'explode';
  constructor(...args) {
    super(...args);

    this.frameLength = 10;
    this.fadeLength = 10; // Number of frames
    this.totalFrames = ((this.frameLength * 2) -1) + this.totalElements;
    this.setMaxTicks();
    this.fadeDelta = 250 / this.fadeLength;
    const offset = determineExplodeOffset(this.totalElements, this.sequencePosition);
    // @TODO Some refactoring is required to get it to work with this.startingFrame

    // Then determine where this elements specific position falls in relation

    const channelOn = [{ frame: offset, brightness: 250 }];

    for (let frameFadeDown = (offset + 1), frameFadeDownMultiplier = this.fadeLength ; frameFadeDown < (this.sequencePosition + this.fadeLength) ; frameFadeDown++, frameFadeDownMultiplier--) {
      channelOn.push({
        frame: frameFadeDown,
        brightness: Math.ceil(this.fadeDelta * frameFadeDownMultiplier)
      });
    }

    this.channels = {
      1: channelOn,
      2: channelOn
    };

  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Explode';
  }
}

module.exports = Explode;

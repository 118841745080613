"use strict";

const GroupAnimation = require('./GroupAnimation');

/**
 * Sweep animation
 *
 * This animation uses Decay Flash as the basis, but alters some of the timing, hence why I'm not just using the Decay
 * Flash animation
 *
 * The idea is that this animation will still only be applied to a single element, but the contextual information that
 * the animation is provided will allow each elements' instance of the animation to work such that it appears that they're
 * all working together.
 * That means, the offset and totalFrames contextual information
 */
class Sweep extends GroupAnimation {
  static id = 'sweep';

  constructor(...args) {
    super(...args);

    this.frameLength = 10;
    this.fadeLength = 10; // Number of frames
    // Each animation is 10 frames long. Each element starts their animation 1 frame after the previous element.
    // Therefore, the overall length needs to be 10 frames after the last one starts
    this.totalFrames = this.frameLength + (this.totalElements * this.animationDelay);
    this.setMaxTicks();
    this.fadeDelta = 250 / this.fadeLength;

    const channelOn = [{ frame: this.startingFrame, brightness: 250 }];

    for (let frameFadeDown = (this.startingFrame + 1), frameFadeDownMultiplier = this.fadeLength ; frameFadeDown < (this.startingFrame + this.fadeLength) ; frameFadeDown++, frameFadeDownMultiplier--) {
      channelOn.push({
        frame: frameFadeDown,
        brightness: Math.max(Math.ceil(this.fadeDelta * frameFadeDownMultiplier), 0)
      });
    }

    this.channels = {
      1: channelOn,
      2: channelOn
    };

  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Sweep';
  }
}

module.exports = Sweep;

import animations from "../../animations";
import * as Strings from '@components/Strings';

export default {
  name: "AnimationPlayer",
  components: {
    ...Strings
  },
  data: function () {
    return {
      channelOneBrightness: 0,
      channelTwoBrightness: 0,
      animation: 'Off',
      animations,
      intervalId: null
    };
  },
  mounted() {
    const Animation = this.animations[this.animation];
    this.newAnimation(Animation);
  },
  methods: {
    newAnimation: function(Animation) {
      this.animation = new Animation();
      this.intervalId = setInterval(this.startAnimation, 10); // 1 tick per every 1000th second
    },

    startAnimation: function () {
      this.animation.tick();
      const nextValue = this.animation.getNext();
      if (nextValue === false) {
        return
      }

      this.channelOneBrightness = nextValue[0];
      this.channelTwoBrightness = nextValue[1];
    },

    stopAnimation: function() {
      clearInterval(this.intervalId);
    },

    changeAnimation: function (animation) {
      this.stopAnimation();
      this.newAnimation(this.animations[animation]);
      this.startAnimation();
    }
  }
}

"use strict";

const GroupAnimation = require('./GroupAnimation');

/**
 * Fill animation
 *
 * This animation will start with the element off, then it will fade in to 100%, then it will remain at
 * 100% for the duration of the overall group animation. That means, while the other delayed elements are fading in
 * and up to 100%, if this elements' instance has already faded up to 100%, it will remain at 100% until all other
 * elements are at 100%, then they'll all fade out at the same rate.
 *
 * Visualisation
 * The F below indicates the Fade cycle of the element
 * The X indicates that the light is just on at 100%
 * The _ indicates that the light is at 0%
 * Frames ->
 * E1 |F|X|X|X|X|X|X|X|_
 * E2 |_|F|X|X|X|X|X|X|_
 * E3 |_|_|F|X|X|X|X|X|_
 * E4 |_|_|_|F|X|X|X|X|_
 * E5 |_|_|_|_|F|X|X|X|_
 * E6 |_|_|_|_|_|F|X|X|_
 * E* |_|_|_|_|_|_|F|X|_
 */
class Fill extends GroupAnimation {
  static id = 'fill';

  constructor(...args) {
    super(...args);

    this.frameLength = 10;
    this.fadeLength = 10; // Number of frames
    const fadeOutLength = this.fadeLength / 2;
    // How many frames it will take for all elements to fade in one-by-one and all be at 100%
    const framesTilAll100 = this.frameLength + (this.totalElements * this.animationDelay);
    this.totalFrames = framesTilAll100 + fadeOutLength;
    this.setMaxTicks();
    this.fadeDelta = 250 / this.fadeLength;
    this.fadeOutDelta = 250 / fadeOutLength; // Fade out is going to be four times as fast as fade in

    const channelOn = [];
    let animationFrameCounter = 0

    for (let frameFadeUp = this.startingFrame ; frameFadeUp <= this.startingFrame + this.fadeLength ; frameFadeUp++) {
      channelOn.push({
        frame: frameFadeUp,
        brightness: Math.ceil(this.fadeDelta * animationFrameCounter)
      });
      animationFrameCounter++;
    }

    // Don't have to add 1 to the animationFrameCounter variable because the last step in the loop above is to increment
    // This means that the last time the loop goes through, animationFrameCounter would be incremented but no value
    // for that frame yet assigned
    for (let remainingFrame = animationFrameCounter ; remainingFrame < framesTilAll100 ; remainingFrame++) {
      channelOn.push({
        frame: this.startingFrame + remainingFrame,
        brightness: 250
      });
    }

    // All of the elements are going to fade out together at the exact same time, after all of the elements have faded in
    // one by one
    for (let fadeOutFrame = framesTilAll100, animationFadeMultiplier = fadeOutLength ; fadeOutFrame < this.totalFrames ; fadeOutFrame++, animationFadeMultiplier--) {
      channelOn.push({
        frame: fadeOutFrame,
        brightness: Math.ceil(this.fadeOutDelta * animationFadeMultiplier)
      });
    }

    this.channels = {
      1: channelOn,
      2: channelOn
    };

  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Fill';
  }
}

module.exports = Fill;

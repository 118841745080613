"use strict";

const BaseAnimation = require('./BaseAnimation');

/**
 * Sequential Controller animation
 *
 * Channel 1 on, Channel 2 off
 * Channel 1 off, Channel 2 on
 * Repeat
 *
 * CH1 |X|X|X|_|_|_|
 * CH2 |_|_|_|X|X|X|
 */
class SequentialControlled extends BaseAnimation {
  /**
   *
   * @param [args]
   * @param {number} args.[frameLength] Speed at which the channels will swap. Lower the number, faster the animation
   */
  constructor(args) {
    super();

    this.frameLength = 7; // Length in "ticks"
    if (typeof args !== 'undefined') {
      this.frameLength = args.frameLength || 7;
    }
    this.totalFrames = 6;
    this.setMaxTicks();
    this.id = 'sequential_controlled';

    this.channels = {
      1: [
        0, 1, 2
      ],
      2: [
        3, 4, 5
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'Sequential Controlled';
  }
}

module.exports = SequentialControlled;

"use strict";
const EventEmitter = require('events');
const eventEmitter = new EventEmitter ();

const GroupAnimation = require('./GroupAnimation');

/**
 * Simon Says animation
 *
 * This animation is effectively just displaying elements on/off in a given sequence
 *
 * The sequence is going to be determined by the SimonSays game instance
 */
class SimonSays extends GroupAnimation {
  static id = 'simonsays';

  constructor(args) {
    super(args);

    this.sequencePositions = args.sequencePositions;
    this.frameLength = 5;
    this.fadeLength = 4; // Number of frames
    this.litLength = 8;
    this.pauseLength = 8;
    const fadeOutLength = this.fadeLength / 2;
    // How many frames it will take for all elements to fade out one-by-one and all be at 0%
    const animationLength = this.litLength + this.fadeLength + this.pauseLength;
    const framesTilAll0 = this.totalElements * animationLength; // Total elements plus how long it will take for the final element to fade out plus the "delay" between each sequence -
    this.totalFrames = framesTilAll0 + fadeOutLength;
    this.setMaxTicks();
    this.fadeDelta = 250 / this.fadeLength;
    this.fadeOutDelta = 250 / fadeOutLength; // Fade out is going to be four times as fast as fade in

    const channelOn = [];
    let animationFrameCounter = 0;

    this.sequencePositions.forEach((sequencePosition) => {
      const sequenceStartingFrame = sequencePosition * animationLength;
      animationFrameCounter = sequenceStartingFrame

      // From the starting frame to the lit length, add full brightness
      for (let frames = 0 ; frames < this.litLength ; frames++) {
        channelOn.push({
          frame: animationFrameCounter,
          brightness: 250
        });
        animationFrameCounter++;
      }

      // From the starting frame plus lit length, add fade
      for ( let frameFadeOut = 0 ; animationFrameCounter < sequenceStartingFrame + this.litLength + this.fadeLength ; frameFadeOut++) {
        channelOn.push({
          frame: animationFrameCounter,
          // Subtracting fadeDelta from 250 means that the first frame of the fade is actually a fade, not just a 100% brightness frame again
          brightness: (250 - this.fadeDelta) - ( frameFadeOut * this.fadeDelta )
        });
        animationFrameCounter++;
      }

      for (let frames = 0 ; frames < this.pauseLength ; frames++) {
        channelOn.push({
          frame: animationFrameCounter,
          brightness: 0
        });
        animationFrameCounter++;
      }
    })

    for (; animationFrameCounter < framesTilAll0 ; animationFrameCounter++) {
      channelOn.push({
        frame: animationFrameCounter,
        brightness: 0
      });

    }

    this.channels = {
      1: channelOn,
      2: channelOn
    };

  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return 'SimonSays';
  }
}

module.exports = SimonSays;

"use strict";

const FadeBaseAnimation = require("./FadeBaseAnimation");

/**
 * Fade Pop animation
 *
 * Both channels will fade in to 60% brightness, then POP to 100%
 */
class FadePop extends FadeBaseAnimation {
  constructor(channel = false) {
    super();

    this.id = "fade_pop";
    this.frameLength = 10; // Length in "ticks"
    this.totalFrames = 30;
    this.setMaxTicks();
    this.fadeLength = this.totalFrames * .6; // Fade in to 60% of full brightness
    this.fadeIncrement = 250 / this.totalFrames;
    let fadeLevel = 0;

    const channelOn = [];

    // 0 - 60%
    for (let ch1FrameFadeUp = 0; ch1FrameFadeUp <= this.fadeLength; ch1FrameFadeUp++) {
      channelOn.push({
        frame: ch1FrameFadeUp,
        brightness: Math.ceil(fadeLevel++ * this.fadeIncrement)
      });
    }

    for (let i = (this.fadeLength + 1) ; i <= this.totalFrames ; i++) {
      channelOn.push({
        frame: i,
        brightness: 250
      })
    }

    this.channels = {
      1: channelOn,
      2: channelOn
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return "Fade Pop";
  }
}

module.exports = FadePop;

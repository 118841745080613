"use strict";

const BattleFlash = require('../battle-flash');

/**
 * The second half of the battle flash animation
 *
 * This is the chasing-flash animation from the Myer Vue lights
 *
 * CH1 |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|
 * CH2 |_|_|_|_|X|X|_|_|_|_|_|_|X|X|_|_|_|_|_|_|X|X|_|_|_|_|_|_|_|X|_|X|_|X|_|_|_|
 */
class BattleFlashTwo extends BattleFlash {
  constructor() {
    super();

    this.channels = {
      1: [
        4, 5, 12, 13, 20, 21, 29, 31, 33
      ],
      2: [
        4, 5, 12, 13, 20, 21, 29, 31, 33
      ]
    };
  }

  /**
   * Get the display name for this animation
   * Used on buttons and reporting
   *
   * @returns {string}
   */
  static getDisplayName() {
    return '2nd';
  }
}

module.exports = BattleFlashTwo;

// Handle elements that are in an array, which means they're meant to be lit up at the same time
/**
 * Find the index of an element in an array.
 * The provided array could be all strings, or have array items that are all strings
 *
 * @param {string[]|string[][]} elements
 * @param {string} element
 *
 * @returns {number}
 */
const findElementIndex = (elements, element) => {
  return elements.findIndex((el) => {
    if (Array.isArray(el)) {
      return el.includes(element)
    }
    return el === element
  })
}

module.exports = findElementIndex;

/**
 * This file contains all of the available animations
 * It does contain somewhat doubled-up information as is in src/animations/runner.js, however the structure
 * of the data is different, such that the halved animations can easily be ignored, in the case of the base runner.js script
 */

module.exports = {
  'alternate_decay_flash': 'AlternateDecayFlash',
  'alternate_pulses': [
    { 'alternate_pulses': 'AlternatePulses' },
    { 'alternate_pulses_one': 'AlternatePulsesOne' },
    { 'alternate_pulses_two': 'AlternatePulsesTwo' }
  ],
  'battle_flash': [
    { 'battle_flash': 'BattleFlash' },
    { 'battle_flash_one': 'BattleFlashOne' },
    { 'battle_flash_two': 'BattleFlashTwo' }
  ],
  'chasing_flash': [
    {'chasing_flash': 'ChasingFlash'},
    {'chasing_flash_one': 'ChasingFlashOne'},
    {'chasing_flash_two': 'ChasingFlashTwo'}
  ],
  'decay_flash': 'DecayFlash',
  'double_pulse': 'DoublePulse',
  'fade_pop': 'FadePop',
  'heartbeat': 'Heartbeat',
  'in_waves': [
    {'in_waves': 'InWaves'},
    {'in_waves_one': 'InWavesOne'},
    {'in_waves_two': 'InWavesTwo'}
  ],
  'jingle_bells': 'JingleBells',
  'ping_pong_flash': [
    {'ping_pong_flash': 'PingPongFlash'},
    {'ping_pong_flash_one': 'PingPongFlashOne'},
    {'ping_pong_flash_two': 'PingPongFlashTwo'}
  ],
  'pulse': 'Pulse',
  'pulsing': 'Pulsing',
  'sequential_controlled': 'SequentialControlled',
  'sequential': [
    {'sequential': 'Sequential'},
    {'sequential_one': 'SequentialOne'},
    {'sequential_two': 'SequentialTwo'}
  ],
  'slo_glo': [
    {'slo_glo': 'SloGlo'},
    {'slo_glo_one': 'SloGloOne'},
    {'slo_glo_two': 'SloGloTwo'}
  ],
  'slow_fade': 'SlowFade',
  'twinkle_flash': [
    {'twinkle_flash': 'TwinkleFlash'},
    {'twinkle_flash_one': 'TwinkleFlashOne'},
    {'twinkle_flash_two': 'TwinkleFlashTwo'}
  ],
  'twinkle_pulse': 'TwinklePulse',
  'steady_on': 'SteadyOn',
  'off':'Off' // Not going to be visible to the client
};

<template>
  <div class="string">
    <div class="string--group" v-for="index in this.strLength" :key="index">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'String',
    props: {
      strLength: {
        type: Number,
        default: 20
      }
    }
  };
</script>

<style>
  .string {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

  }

  .string--group {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-around;
  }
</style>

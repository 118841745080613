import { SocialMediaLinks } from "@components";
import AJAX from '@utils/AJAX';

export default {
  name: "Finished",
  components: {
    SocialMediaLinks
  },
  data() {
    return {
      firstName: '',
      ratingFormDisplayed: true,
      ratingFormSubmitting: false,
    }
  },
  computed: {
    wasRevoked() {
      const params = new URLSearchParams(location.search)
      return params.has('queue-advanced');
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.ratingFormSubmitting = true;
      AJAX.post('rate5Stars', { name: this.firstName }).then(() => {
        this.$store.dispatch('alert', { message: 'Thank you! Rated 5 stars 🎅', type: 'info' });
        this.ratingFormDisplayed = false;
        this.ratingFormSubmitting = false;
      });
    }
  }
}

const houseParts = require('../house-parts');
const byoThemeElements = require('../byo-theme-elements');
const channels = require('../channels');
const types = require('../types');
const themeGroups = require('../theme-groups');
const themes = require('../themes');
const elementGroups = require('../element-groups');

module.exports = {
  MINI_TREES_WW_1: {
    id: "MINI_TREES_WW_1",
    name: 'Mini Trees Warm White 1',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.MINI_TREES.id,
    byoThemeOptions: [themes.CLASSIC],
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },
  MINI_TREES_W_1: {
    id: "MINI_TREES_W_1",
    name: 'Mini Trees White 1',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.MINI_TREES.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },
  MINI_TREES_WW_2: {
    id: "MINI_TREES_WW_2",
    name: 'Mini Trees Warm White 2',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.MINI_TREES.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },
  MINI_TREES_W_2: {
    id: "MINI_TREES_W_2",
    name: 'Mini Trees White 2',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.MINI_TREES.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },
  MINI_TREES_WW_3: {
    id: "MINI_TREES_WW_3",
    name: 'Mini Trees Warm White 3',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.MINI_TREES.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },
  MINI_TREES_W_3: {
    id: "MINI_TREES_W_3",
    name: 'Mini Trees White 3',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.MINI_TREES.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },
  MINI_TREES_WW_4: {
    id: "MINI_TREES_WW_4",
    name: 'Mini Trees Warm White 4',
    animation: 'OFF',
    type: types.WarmWhite,
    housePart: houseParts.GARDEN.id,
    byoThemeElementId: byoThemeElements.MINI_TREES.id,
    availableInThemes: [
      ...themeGroups.WARM_WHITE
    ],
    groups: [
      elementGroups.GARDEN,
      elementGroups.TREES
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },
  // MINI_TREES_WW_5: {
  //   id: "MINI_TREES_WW_5",
  //   name: 'Mini Trees Warm White 5',
  //   animation: 'OFF',
  //   type: types.WarmWhite,
  //   housePart: houseParts.GARDEN.id,
  //   byoThemeElementId: byoThemeElements.MINI_TREES.id,
  //   availableInThemes: [
  //     ...themeGroups.WARM_WHITE
  //   ],
  //   groups: [
  //     elementGroups.GARDEN,
  //     elementGroups.TREES
  //   ],
  //   brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  // }
}

import constants from "@constants";

const state = {
  elements: [
    { id: 'ALL', display: 'Whole House' },
    ...Object.values(constants.elements).filter(element => element.housePart !== null).map(element => ({ id: element.id, display: element.name }) )
  ],
  selectedElements: ['ALL'],
  selectedByoThemeElement: ''
};
const mutations = {
  updateSelectedElements(state, elements) {
    state.selectedElements = elements;
  },
  updateSelectedByoThemeElements(state, elements) {
    state.selectedByoThemeElement = elements;
  }
};
const actions = {
  UPDATE_SELECTED_ELEMENTS({ commit }, payload) {
    const { elements } = payload;
    commit('updateSelectedElements', elements);
  },
  UPDATE_SELECTED_BYO_THEME_ELEMENTS({ commit }, payload) {
    const { element } = payload;
    commit('updateSelectedByoThemeElements', element);
  }
};
const getters = {
  selectedElements: state => state.selectedElements,
  selectedByoThemeElement: state => state.selectedByoThemeElement,
  elements: state => state.elements,
  byoThemeElementOptions: () => Object.values(constants.byoThemeElements)
};

export default {
  state,
  mutations,
  actions,
  getters
};

const houseParts = require('../house-parts');
const channels = require('../channels');
const types = require('../types');
const themeGroups = require('../theme-groups');

module.exports = {
  ROPELIGHT_W: {
    id: 'ROPELIGHT_W',
    name: 'Ice Rope Light',
    animation: 'OFF',
    type: types.CoolWhite,
    housePart: houseParts.GARDEN.id,
    availableInThemes: [
      ...themeGroups.COOL_WHITE
    ],
    brightness: { [channels.CONST__CH_1]: 0, [channels.CONST__CH_2]: 0 }
  },
}
